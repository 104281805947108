import PrismicDOM from 'prismic-dom';
import linkResolver from '@/plugins/prismic/link-resolver';
import htmlSerializer from '@/plugins/prismic/html-serializer';

const isValidRichTextField = (input) => {
  // Field does not exist
  if (typeof input === 'undefined') {
    process.env.NODE_ENV !== 'production' &&
      // eslint-disable-next-line no-console
      console.warn(
        "Missing field. Please check you didn't mispell a field name"
      );
    return false;
  }

  // String is empty in Prismic
  const isEmptyString = !input.length || !input[0]?.text?.length;
  return !isEmptyString;
};

export const richText = (input) => {
  if (!isValidRichTextField(input)) {
    return undefined;
  }
  return PrismicDOM.RichText.asHtml(input, linkResolver, htmlSerializer);
};

export const stripRichTextLineBreak = (input) => input.replace('<br />', ' ');

export const replaceTag = (input, replacement = 'h2', needle = 'p') => {
  return input
    .replace(`<${needle}`, `<${replacement}`)
    .replace(`</${needle}`, `</${replacement}`);
};

export const replaceRichTextTag = (input, replacement = 'h2', needle = 'p') => {
  if (!isValidRichTextField(input)) {
    return undefined;
  }
  return PrismicDOM.RichText.asHtml(input, linkResolver, htmlSerializer)
    .replace(`<${needle}`, `<${replacement}`)
    .replace(`</${needle}`, `</${replacement}`);
};

export const stripRichTextWrapperTag = (input) => {
  if (typeof input === 'string') {
    return input;
  }
  if (!isValidRichTextField(input)) {
    return undefined;
  }
  return PrismicDOM.RichText.asHtml(
    input,
    linkResolver,
    htmlSerializer
  ).replace(/^<p[^>]*>|<\/p>$/g, '');
};

export const asText = (text) => {
  if (typeof text === 'string') {
    return text;
  }
  return PrismicDOM.RichText.asText(text);
};

export const stripHTMLTags = (input) => {
  return input.replace(/<[^>]*>?/gm, '');
};
