import { stripRichTextWrapperTag } from '@/utils/html';
import { pushLisaModule } from '@/plugins/gtm';
import lisaModuleAdapter, {
  getDefaultLisaLabels,
  queryProduct,
  LISA_TYPE,
  PRODUCT_QUERY_ERROR,
  LISA_CTA,
} from '~/slices/LisaContent/adapter.new';
import translations from '@/mixins/componentTranslations.js';
import { getFiltersArrays } from '~/utils/helpers/get-filters';

export default {
  mixins: [translations],
  data() {
    return {
      lang: null,
      lisaModule: null,
      sliceData: null,
      lisaData: null,
      type: '',
      uid: null,
      useLisaTitle: false,
      resetButton: false,
      selectedCoverType: null,
      selectedDestination: null,
      selectedLanguage: null,
      selectedLocation: null,
      pageContext: null,
    };
  },
  created() {
    this.lang = this.$i18n.localeProperties.iso;

    const temporaryTitle = [
      {
        type: 'paragraph',
        text: 'Find the right insurance for you:',
        spans: [
          {
            type: 'label',
            data: {
              label: 'black-color',
            },
          },
        ],
      },
    ];

    this.lisaModule = getDefaultLisaLabels(this.$i18n);
    this.title = stripRichTextWrapperTag(temporaryTitle);
  },
  methods: {
    processTracking(result) {
      const resultType = result ? 'product page' : 'except to form';
      pushLisaModule(
        { store: this.$store, $gtm: this.$gtm },
        {
          lisaModule: this.lisaModule,
          destination: this.selectedDestination,
          coverType: this.selectedCoverType,
          language: this.selectedLanguage,
          location: this.selectedLocation,
          resultType,
        }
      );
    },
    redirect(path) {
      const isPathExternal = path.includes('http');

      if (isPathExternal) {
        window.location.href = path;
      } else {
        this.$router.push({ path });
      }
    },
    updateButtonsState(status, isTrue, isQuote = false) {
      const isLisaPanelOrContent = [
        LISA_TYPE.panel,
        LISA_TYPE.content,
      ].includes(this.type);

      const hasSubmitCta = isLisaPanelOrContent && this.lisaModule?.submitCta;
      const hasQuoteCta = isLisaPanelOrContent && this.lisaModule?.cta;
      const hasButton = this.lisaModule?.buttons.length > 0;

      if (status === 'loading') {
        if (hasQuoteCta && isQuote) {
          this.lisaModule.cta.loading = isTrue;
          return;
        }
        if (hasButton) {
          this.lisaModule.buttons[0].cta.loading = isTrue;
        }
        if (hasSubmitCta) {
          this.lisaModule.submitCta.loading = isTrue;
        }
        return;
      }
      if (status === 'enable') {
        if (hasSubmitCta) this.lisaModule.submitCta.disabled = !isTrue;
        if (hasQuoteCta) this.lisaModule.cta.disabled = !isTrue;
        if (hasButton) {
          this.lisaModule.buttons[0].cta.disabled = !isTrue;
        }
      }
    },
    saveLastDestination(destination) {
      localStorage.lastResearchOption = JSON.stringify(destination);
    },
    async onLisaModuleSubmit(event, isQuote) {
      this.updateButtonsState('loading', true, isQuote);

      this.saveLastDestination(this.selectedDestination);

      const productFound = await queryProduct({
        selectedCoverType: this.selectedCoverType,
        selectedDestination: this.selectedDestination,
        selectedLanguage: this.selectedLanguage,
        selectedLocation: this.selectedLocation,
        lang: this.lang,
        isPro: this.pageContext?.isPro,
        prismic: this.$prismic,
      });
      const locale = this.$i18n.localeProperties.code;

      const productHasError = Object.values(PRODUCT_QUERY_ERROR).includes(
        productFound?.error
      );
      if (productHasError) {
        const errorPageUID = this.lisaModule.errorPage?.uid ?? '404';
        const errorPageURL = `${locale}/landing/${errorPageUID}`;
        this.$router.push({ path: `/${errorPageURL}` });
        return;
      }

      this.processTracking(productFound);

      if (!productFound) {
        const uid = this.lisaModule.noResultPage?.uid;
        const noResultPage = uid ? `landing/${uid}` : '404';
        const landingPage = `${locale}/${noResultPage}`;
        this.$router.push({ path: `/${landingPage}` });
        this.resetLisa();
        return;
      }

      if (isQuote) {
        const link = this.$enhancedLinkSerializer(
          productFound.data.card[0].cta_primary_link
        );
        this.redirect(link.href);
      } else {
        const offer = productFound.data.offer.uid;
        const product = productFound.uid;
        this.$router.push({ path: `/${locale}/${offer}/${product}` });
      }
      this.resetLisa();
    },
    onLisaModuleInput(event) {
      this.selectedCoverType = event.selectedCoverType;
      this.selectedDestination = event.selectedDestination;
      this.selectedLanguage = event.selectedLanguage;
      this.selectedLocation = this.lisaModule.location;

      const shouldEnableSubmit =
        this.selectedCoverType &&
        this.selectedDestination &&
        this.selectedLanguage;

      this.updateButtonsState('enable', shouldEnableSubmit);
    },
    onLisaModuleQuote(event) {
      const isQuote = true;
      this.onLisaModuleSubmit(event, isQuote);
    },
    async resetLisa() {
      const isLisaPanelOrContent = [
        LISA_TYPE.panel,
        LISA_TYPE.content,
      ].includes(this.type);

      if (isLisaPanelOrContent) {
        this.updateButtonsState('loading', false);
        this.updateButtonsState('enable', false);
      }
      this.lisaModule.coverType.value = {};
      this.lisaModule.language.value = {};
      this.lisaModule.destination.value = '';

      await this.initLisaModule({
        useLisaTitle: this.useLisaTitle,
        resetButton: this.resetButton,
        type: this.type,
        uid: this.uid,
        context: this.pageContext,
        slice: this.slice,
      });
    },
    setLisaData() {
      this.lisaData = getFiltersArrays({ i18n: this.$i18n });
    },
    autoFill(lisa, defaultValues = {}, withContext = true, type) {
      const contextDefaultValues = {
        // TODO: Prismic is currently using 3 letters country code, this is a temporary
        // hack which will work for some codes until the data is changed in the CMS
        destination:
          this.pageContext?.country_code?.length >= 2
            ? this.pageContext.country_code.substring(0, 2)
            : null,
        coverType: this.pageContext?.cover_type?.id,
        language: this.$i18n.localeProperties.code,
      };

      return this.autoFillLisaOptions(
        lisa,
        withContext ? contextDefaultValues : defaultValues,
        !withContext,
        type
      );
    },
    autoFillLisaOptions(lisa, fields = {}, reset = false, type) {
      const { location, coverType, destination, language } = fields;
      if (!lisa) {
        return;
      }
      const {
        location: lisaLocation,
        coverType: lisaCoverType,
        destination: lisaDestination,
        language: lisaLanguage,
      } = lisa;

      // deep copy original object to avoid mutating original object
      const lisaClone = structuredClone(lisa);

      if (reset) {
        if (type === 'products') {
          lisaClone.location.defaultValue = null;
          lisaClone.location.value = null;
        }
        lisaClone.destination.defaultValue = null;
        lisaClone.destination.value = null;
        lisaClone.coverType.value = '';
        lisaClone.language.value = null;
      }

      if (location) {
        const foundLocation = lisaLocation.options.find((opt) =>
          opt.value.startsWith(location.toUpperCase())
        );
        lisaClone.location = {
          ...lisaClone.location,
          defaultValue: foundLocation,
          value: foundLocation.value,
        };
      }

      if (destination) {
        const foundDestination = lisaDestination.options.find((opt) =>
          opt.value.startsWith(destination.toUpperCase())
        );
        lisaClone.destination = {
          ...lisaClone.destination,
          defaultValue: foundDestination,
          value: foundDestination,
        };
      }

      if (coverType) {
        const foundCoverType = lisaCoverType.options.find(
          (opt) => opt.value.toUpperCase() === coverType.toUpperCase()
        );
        lisaClone.coverType.value = foundCoverType;
      }

      if (language) {
        const foundLanguage = lisaLanguage.options.find(
          (opt) => opt.code.toUpperCase() === language.toUpperCase()
        );
        lisaClone.language.value = foundLanguage;
      }

      return lisaClone;
    },
    async initLisaModule(options) {
      const {
        useLisaTitle = false,
        resetButton = false,
        type = LISA_TYPE.hero,
        uid = null,
        context = null,
        slice = null,
        cta = LISA_CTA.PLAN,
        defaultValues = {},
        autoFillWithContext = true,
      } = options;

      this.uid = uid;
      this.type = type;
      this.useLisaTitle = useLisaTitle;
      this.resetButton = resetButton;
      this.pageContext = context;
      this.slice = slice;
      this.setLisaData();

      const lisa = await lisaModuleAdapter(slice, {
        uid,
        type,
        prismic: this.$prismic,
        i18n: this.$i18n,
        lisaData: this.lisaData,
        useLisaTitle,
        enableResetButton: resetButton,
        cta,
      });

      this.lisaModule = this.autoFill(
        lisa,
        defaultValues,
        autoFillWithContext ?? true,
        type
      );
    },
  },
};
