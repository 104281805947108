import { EKOMI_SET_REVIEWS, EKOMI_SET_ANSWERS } from '~/store/mutation-types';

export const defaultAccount = 'international-care-fr';
export const getRating = (rating, i18n) => {
  if (!i18n) return;
  const ratingAsFloat = Number(rating).toFixed(1);
  return new Intl.NumberFormat(i18n.locale).format(ratingAsFloat);
};

const accounts = [
  {
    id: 'asp',
    name: 'APRIL Santé Prévoyance',
    ekomiId: '110335',
    interfaceId: '87627',
    interfacePassword: '46ef79acc84ace17717d35aef',
    formId: '8168',
    marketTag: null,
  },
  {
    id: 'international-care-fr',
    name: 'APRIL International Care - France',
    ekomiId: '71888',
    interfaceId: '61303',
    interfacePassword: '761ba2f3a5cfff270dd798b60',
    formId: '4874',
    marketTag: 'international',
  },
  {
    id: 'international-care-en',
    name: 'APRIL International Care - English',
    ekomiId: '71878',
    interfaceId: '61293',
    interfacePassword: '2c829111bc643c40f8055338d',
    formId: '24708',
    marketTag: 'international',
  },
  {
    id: 'moto',
    name: 'APRIL Moto',
    ekomiId: '76397',
    interfaceId: '65792',
    interfacePassword: '9c9772b90bc6cbc4c4aab91dc',
    formId: '36787',
    marketTag: 'moto',
  },
  {
    id: 'marine',
    name: 'APRIL Marine',
    ekomiId: '76398',
    interfaceId: '65793',
    interfacePassword: 'fb1ae50a80603a2415ab1dfce',
    formId: '36790',
    marketTag: 'marine',
  },
];

const getEkomiParams = (type, market) => {
  let params = {
    form_id: market.formId,
  };

  const answersParams = {
    published: 1,
    widthdrawn: 0,
    record_per_page: 3,
    show_moderated_reviews: 1,
    filter: 'positive',
  };

  if (type === 'answers') {
    params = {
      ...params,
      ...answersParams,
    };
  }

  return params;
};

const getEkomiUrl = (type, params, serverSide, endpoint) => {
  if (type === 'reviews') {
    return serverSide
      ? `${endpoint}/v2/reviews/summary?${params.toString()}`
      : `/api/ekomi/reviews?${params.toString()}`;
  } else if (type === 'answers') {
    return serverSide
      ? `${endpoint}/v3/answers?${params.toString()}`
      : `/api/ekomi/answers?${params.toString()}`;
  }
};

export const getEkomiData = async (id, type, context) => {
  const store = context.$store || context.store;
  const serverSide = typeof window === 'undefined';
  const data = store.state.ekomi[type][id || defaultAccount];
  const config = context.$config;

  if (data) {
    return data;
  }

  const market = accounts.find(
    (market) => market.id === (id || defaultAccount)
  );
  const params = new URLSearchParams(getEkomiParams(type, market));
  const url = getEkomiUrl(type, params, serverSide, config.ekomiEndpointUrl);

  try {
    const response = await fetch(url, {
      headers: {
        'x-gravitee-api-key': config.ekomiApiKey,
        'interface-id': market.interfaceId,
        'interface-password': market.interfacePassword,
      },
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const json = await response.json();

    if (json.error) {
      return null;
    }

    if (type === 'reviews') {
      await store.commit(`ekomi/${EKOMI_SET_REVIEWS}`, {
        market: market.id,
        reviews: json.data,
      });
      return json.data;
    } else if (type === 'answers') {
      await store.commit(`ekomi/${EKOMI_SET_ANSWERS}`, {
        market: market.id,
        answers: json.data.result,
      });
      return json.data.result;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};
