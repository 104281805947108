import filterNullOrUndefined from './filterNullOrUndefined';
import { LAYOUT_SET_HEAD } from '@/store/mutation-types';

export default (data, store) => {
  const hero = data.slices?.find((slice) => slice.slice_type === 'hero');
  const heroImage = hero?.primary?.hero_push_desktop_image?.url;

  const headObject = {
    title: data?.page_title,
    description: data?.page_description,
    image: data?.page_image?.url || heroImage || null,
    nositemap: data?.seo_sitemap,
    nofollow: data?.seo_nofollow,
    noindex: data?.seo_noindex,
    canonicalUrl: data?.canonical_url,
  };

  store.commit(`layout/${LAYOUT_SET_HEAD}`, filterNullOrUndefined(headObject));
};
