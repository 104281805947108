import prismicDOM from 'prismic-dom';
import linkResolver from './link-resolver';

const Elements = prismicDOM.RichText.Elements;

const getLabel = (elementLabel, children, content) => {
  if (elementLabel === 'infobulle') {
    return `<button data-tooltip="${children.join('')}">i</button>`;
  }
  if (elementLabel === 'exposant') {
    return `<sup class="${elementLabel}">${content}</sup>`;
  }

  const labelClass = elementLabel ? ` class="${elementLabel}"` : '';
  return `<span ${labelClass}>${children.join('')}</span>`;
};

const getHyperlink = (element, content) => {
  let result = '';
  const url = prismicDOM.Link.url(element.data, linkResolver);

  if (element.data.link_type === 'Document') {
    result = `<a href="${url}" data-nuxt-link>${content}</a>`;
  } else {
    const target = element.data.target
      ? `target="'${element.data.target}'" rel="noopener"`
      : '';

    result = `<a href="${url}" ${target}>${content}</a>`;
  }

  return result;
};

const getElementImage = (element) => {
  let result = `<img src="${element.url}" alt="${element.alt || ''}">`;

  if (element.linkTo) {
    const url = prismicDOM.Link.url(element.linkTo, linkResolver);

    if (element.linkTo.link_type === 'Document') {
      result = `<a href="${url}" data-nuxt-link>${result}</a>`;
    } else {
      const target = element.linkTo.target
        ? `target="${element.linkTo.target}" rel="noopener"`
        : '';

      result = `<a href="${url}" ${target}>${result}</a>`;
    }
  }
  const wrapperClassList = [element.label || '', 'block-img'];

  result = `<p class="${wrapperClassList.join(' ')}">${result}</p>`;

  return result;
};

const getElement = (type, children, element, content) => {
  switch (type) {
    case Elements.heading1:
      return `<h1>${children.join('')}</h1>`;

    case Elements.heading2:
      return `<h2>${children.join('')}</h2>`;

    case Elements.heading3:
      return `<h3>${children.join('')}</h3>`;

    case Elements.heading4:
      return `<h4>${children.join('')}</h4>`;

    case Elements.heading5:
      return `<h5>${children.join('')}</h5>`;

    case Elements.heading6:
      return `<h6>${children.join('')}</h6>`;

    case Elements.paragraph:
      return `<p>${children.join('')}</p>`;

    case Elements.preformatted:
      return `<pre>${children.join('')}</pre>`;

    case Elements.strong:
      return `<b>${children.join('')}</b>`;

    case Elements.em:
      return `<em>${children.join('')}</em>`;

    case Elements.listItem:
      return `<li><p>${children.join('')}</p></li>`;

    case Elements.oListItem:
      return `<li><p>${children.join('')}<p/></li>`;

    case Elements.list:
      return `<ul>${children.join('')}</ul>`;

    case Elements.oList:
      return `<ol>${children.join('')}</ol>`;

    case Elements.embed:
      return `
        <div data-oembed="${element.oembed.embed_url}"
          data-oembed-type="${element.oembed.type}"
          data-oembed-provider="${element.oembed.provider_name}"
        >
          ${element.oembed.html}
        </div>
      `;

    case Elements.label: {
      return getLabel(element.data.label, children, content);
    }

    case Elements.span:
      return content ? content.replace(/\n/g, '<br />') : '';

    default:
      return null;
  }
};

export default (type, element, content, children) => {
  // Generate links to Prismic Documents as <router-link> components
  if (type === Elements.hyperlink) {
    return getHyperlink(element, content);
  }

  // If the image is also a link to a Prismic Document, it will return a <router-link> component
  if (type === Elements.image) {
    return getElementImage(element);
  }

  return getElement(type, children, element, content);
};
