
import { components } from '@/slices';
import getHead from '@/utils/get-head';
import setHead from '@/utils/set-head';
import setLisaAutoFill from '@/utils/set-lisa-auto-fill';
import setAlternateLanguages from '@/utils/set-alternate-languages';

export default {
  name: 'ErrorPage',
  data() {
    return {
      components,
      data: {},
    };
  },
  head() {
    return getHead(this, [], { hasOrganization: true });
  },
  computed: {
    locale() {
      return this.$i18n.localeProperties.iso;
    },
  },
  watch: {
    locale() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const lang = this.$i18n.localeProperties.iso;
      const { $prismic } = this.$root.context;
      const page = await $prismic.api.getSingle('page_error', { lang });
      const alternateIds = page.alternate_languages.map((lang) => lang.id);
      this.data = page?.data;
      const altLangs = await $prismic.api.query(
        $prismic.predicate.in('document.id', alternateIds),
        { lang: '*' }
      );
      setHead(page?.data, this.$store);
      setLisaAutoFill(page?.data, this.$store, this.$i18n);
      setAlternateLanguages(altLangs?.results, this.$store);
    },
  },
};
