function urlQuery(url) {
  return `${url}${url.includes('?') ? '&' : '?'}`;
}

export const buildFluidSrcSet = (url, widths, q) =>
  widths
    .map((resolution) => {
      const quality = q ? `&q=${q}` : '';
      return `${urlQuery(url)}w=${resolution}${quality} ${resolution}w`;
    })
    .join(', ');

// The values are based on this trick
// https://blog.imgix.com/2016/03/30/dpr-quality
export const defaultPixelRatios = [
  { density: 1, quality: 90 },
  { density: 1.5, quality: 90 },
];

export const buildSingleSrcSet = (url, width) => {
  if (width) {
    return `${urlQuery(url)}w=${Number(width)}`;
  }
  return url;
};

export const buildFixedSrcSet = (
  url,
  width,
  pixelRatios = defaultPixelRatios
) =>
  pixelRatios
    .map((pixelRatio) => `${urlQuery(url)}w=${width}&q=${pixelRatio.quality}`)
    .join(', ');

export const commonProps = {
  srcSetWidths: {
    type: Array,
    required: false,
    default: () => [320, 580, 640, 1024, 1232, 1440, 1705, 1960],
  },
  quality: {
    type: String,
    required: false,
  },
  filename: {
    type: String,
    required: true,
  },
  sizes: {
    type: String,
    default: null,
  },
  fixed: {
    type: Boolean,
    required: false,
    default: false,
  },
  singleSrc: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export const adapter = (data, alt) => {
  return {
    filename: data?.url || '',
    alt,
    size: {
      width: data?.info?.img_w || null,
      height: data?.info?.img_h || null,
    },
  };
};

export default null;
