import { stripRichTextWrapperTag } from '@/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';
import { lisaLinks, routerLinks } from '@/utils/fetch-links';

const generateField = (
  name,
  options,
  i18n,
  useLisaTitle,
  uid,
  hidden = false,
  disabled = true
) => {
  let id = '';
  if (uid) {
    id += 'panel';
  } else if (useLisaTitle) {
    id += 'hero';
  } else {
    id += 'content';
  }

  return {
    name: i18n.t(`lisa.name.${name}`),
    value: '',
    required: false,
    field: {
      id: id + '-' + i18n.t(`lisa.name.${name}`),
      label: i18n.t(`lisa.label.${name}`),
      type: 'select',
      options,
      disabled,
      hidden,
      resetButton: true,
    },
    messages: {
      required: i18n.t('lisa.messages.required'),
    },
  };
};

export const generateFormData = (
  i18n,
  lisaData,
  useLisaTitle,
  uid,
  ctaLabel = i18n.t('cta.lisa-submit')
) => {
  const lang = i18n.localeProperties.iso;

  const inputs = [
    generateField(
      'residence',
      lisaData
        ? lisaData.countries
            .sort((a, b) => a?.fields[lang]?.localeCompare(b?.fields[lang]))
            .map(({ id, fields }) => ({
              label: fields[lang],
              value: id,
            }))
        : [],
      i18n,
      useLisaTitle,
      uid,
      false,
      false
    ),
    generateField('destination', [], i18n, useLisaTitle, uid),
    generateField('profile', [], i18n, useLisaTitle, uid),
    generateField('nationality', [], i18n, useLisaTitle, uid, true, true),
  ];

  return {
    inputs,
    ...(!uid
      ? {
          buttons: [
            {
              label: ctaLabel,
              cta: {
                disabled: true,
                type: 'submit',
              },
            },
          ],
        }
      : {}),
  };
};

export default async function (
  { primary },
  { prismic, i18n, lisaData, useLisaTitle, uid }
) {
  const lang = i18n.localeProperties.iso;
  const module = uid
    ? await prismic.api.getByUID('module_lisa', uid, {
        fetchLinks: [...routerLinks, lisaLinks],
        lang,
      })
    : await prismic.api.getByID(primary?.module_lisa?.id, {
        fetchLinks: [...routerLinks, lisaLinks],
        lang,
      });

  const moduleData = module?.data;

  if (!moduleData) {
    return null;
  }

  return {
    title:
      useLisaTitle || !primary
        ? stripRichTextWrapperTag(moduleData.title)
        : stripRichTextWrapperTag(primary?.title),
    image: imageAdapter(moduleData.image),
    formLisaData: {
      formData: generateFormData(
        i18n,
        lisaData,
        useLisaTitle,
        uid,
        moduleData.cta_submit_label
      ),
    },
    ...(uid
      ? {
          submitCta: {
            disabled: true,
            label: i18n.t('cta.lisa-submit'),
            variant: 'secondary',
          },
          cta: {
            disabled: true,
            label: i18n.t('cta.get-a-rate'),
            variant: 'primary',
          },
        }
      : {}),
  };
}
