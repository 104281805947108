import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0a9b3eb8 = () => interopDefault(import('../pages/guides-sitemap/index.vue' /* webpackChunkName: "pages/guides-sitemap/index" */))
const _5e96a5f4 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _029ab42a = () => interopDefault(import('../pages/taxonomy/index.vue' /* webpackChunkName: "pages/taxonomy/index" */))
const _4d16407d = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _62372f86 = () => interopDefault(import('../pages/guides-themes/index.vue' /* webpackChunkName: "pages/guides-themes/index" */))
const _7fc4ad10 = () => interopDefault(import('./prismic/pages/preview.vue' /* webpackChunkName: "" */))
const _ddf31f3a = () => interopDefault(import('../pages/slice-simulator.vue' /* webpackChunkName: "pages/slice-simulator" */))
const _0ef7ebfc = () => interopDefault(import('../pages/corporate/index.vue' /* webpackChunkName: "pages/corporate/index" */))
const _50edba70 = () => interopDefault(import('../pages/offers/index.vue' /* webpackChunkName: "pages/offers/index" */))
const _72e992cb = () => interopDefault(import('../pages/regions/destinations.vue' /* webpackChunkName: "pages/regions/destinations" */))
const _ea637e68 = () => interopDefault(import('../pages/support/faq/faqs.vue' /* webpackChunkName: "pages/support/faq/faqs" */))
const _155f7c6e = () => interopDefault(import('../pages/about/news/index.vue' /* webpackChunkName: "pages/about/news/index" */))
const _9271c0bc = () => interopDefault(import('../pages/corporate/guides-sitemap/index.vue' /* webpackChunkName: "pages/corporate/guides-sitemap/index" */))
const _0a3e3cdc = () => interopDefault(import('../pages/corporate/login/index.vue' /* webpackChunkName: "pages/corporate/login/index" */))
const _9c57e6d8 = () => interopDefault(import('../pages/corporate/taxonomy/index.vue' /* webpackChunkName: "pages/corporate/taxonomy/index" */))
const _4a7b32d3 = () => interopDefault(import('../pages/corporate/contact/index.vue' /* webpackChunkName: "pages/corporate/contact/index" */))
const _cab87bda = () => interopDefault(import('../pages/corporate/guides-themes/index.vue' /* webpackChunkName: "pages/corporate/guides-themes/index" */))
const _1f1573b5 = () => interopDefault(import('../pages/corporate/regions/destinations.vue' /* webpackChunkName: "pages/corporate/regions/destinations" */))
const _55bc464e = () => interopDefault(import('../pages/guide/index.vue' /* webpackChunkName: "pages/guide/index" */))
const _511326b6 = () => interopDefault(import('../pages/corporate/support/faq/faqs.vue' /* webpackChunkName: "pages/corporate/support/faq/faqs" */))
const _48b73b50 = () => interopDefault(import('../pages/corporate/about/news/index.vue' /* webpackChunkName: "pages/corporate/about/news/index" */))
const _0eab6caf = () => interopDefault(import('../pages/corporate/guide/index.vue' /* webpackChunkName: "pages/corporate/guide/index" */))
const _ac97a31a = () => interopDefault(import('../pages/corporate/support/faq/_faq/index.vue' /* webpackChunkName: "pages/corporate/support/faq/_faq/index" */))
const _8dbfa438 = () => interopDefault(import('../pages/corporate/about/news/_article/index.vue' /* webpackChunkName: "pages/corporate/about/news/_article/index" */))
const _4b4a4ec4 = () => interopDefault(import('../pages/corporate/guide/_uid.vue' /* webpackChunkName: "pages/corporate/guide/_uid" */))
const _58fce009 = () => interopDefault(import('../pages/support/faq/_faq/index.vue' /* webpackChunkName: "pages/support/faq/_faq/index" */))
const _94dbe6e4 = () => interopDefault(import('../pages/about/news/_article/index.vue' /* webpackChunkName: "pages/about/news/_article/index" */))
const _d223d9d8 = () => interopDefault(import('../pages/corporate/support/_uid/index.vue' /* webpackChunkName: "pages/corporate/support/_uid/index" */))
const _9538cb06 = () => interopDefault(import('../pages/corporate/contact/form/_uid.vue' /* webpackChunkName: "pages/corporate/contact/form/_uid" */))
const _20ade409 = () => interopDefault(import('../pages/corporate/landing/_uid.vue' /* webpackChunkName: "pages/corporate/landing/_uid" */))
const _414878ea = () => interopDefault(import('../pages/corporate/legal/_uid/index.vue' /* webpackChunkName: "pages/corporate/legal/_uid/index" */))
const _484b9e3f = () => interopDefault(import('../pages/corporate/taxonomy/_uid.vue' /* webpackChunkName: "pages/corporate/taxonomy/_uid" */))
const _b62bfe56 = () => interopDefault(import('../pages/corporate/services/_uid/index.vue' /* webpackChunkName: "pages/corporate/services/_uid/index" */))
const _62b87596 = () => interopDefault(import('../pages/corporate/about/_uid/index.vue' /* webpackChunkName: "pages/corporate/about/_uid/index" */))
const _751ee0f6 = () => interopDefault(import('../pages/corporate/regions/_region/index.vue' /* webpackChunkName: "pages/corporate/regions/_region/index" */))
const _64df9d4c = () => interopDefault(import('../pages/guide/_uid.vue' /* webpackChunkName: "pages/guide/_uid" */))
const _26dc5b0e = () => interopDefault(import('../pages/corporate/support/_uid/_child/index.vue' /* webpackChunkName: "pages/corporate/support/_uid/_child/index" */))
const _9f9c4f10 = () => interopDefault(import('../pages/corporate/legal/_uid/_child/index.vue' /* webpackChunkName: "pages/corporate/legal/_uid/_child/index" */))
const _3fcea22d = () => interopDefault(import('../pages/corporate/services/_uid/_child/index.vue' /* webpackChunkName: "pages/corporate/services/_uid/_child/index" */))
const _5758824c = () => interopDefault(import('../pages/corporate/about/_uid/_child/index.vue' /* webpackChunkName: "pages/corporate/about/_uid/_child/index" */))
const _7a7103a6 = () => interopDefault(import('../pages/corporate/regions/_region/_country/index.vue' /* webpackChunkName: "pages/corporate/regions/_region/_country/index" */))
const _4e4affaa = () => interopDefault(import('../pages/support/_uid/index.vue' /* webpackChunkName: "pages/support/_uid/index" */))
const _99e879b2 = () => interopDefault(import('../pages/contact/form/_uid.vue' /* webpackChunkName: "pages/contact/form/_uid" */))
const _2905791f = () => interopDefault(import('../pages/landing/_uid.vue' /* webpackChunkName: "pages/landing/_uid" */))
const _7b039300 = () => interopDefault(import('../pages/legal/_uid/index.vue' /* webpackChunkName: "pages/legal/_uid/index" */))
const _4ae6abe9 = () => interopDefault(import('../pages/taxonomy/_uid.vue' /* webpackChunkName: "pages/taxonomy/_uid" */))
const _4daab202 = () => interopDefault(import('../pages/services/_uid/index.vue' /* webpackChunkName: "pages/services/_uid/index" */))
const _c718e0a8 = () => interopDefault(import('../pages/about/_uid/index.vue' /* webpackChunkName: "pages/about/_uid/index" */))
const _4dc7f1a0 = () => interopDefault(import('../pages/corporate/_offer/index.vue' /* webpackChunkName: "pages/corporate/_offer/index" */))
const _c862b6c0 = () => interopDefault(import('../pages/regions/_region/index.vue' /* webpackChunkName: "pages/regions/_region/index" */))
const _e982b466 = () => interopDefault(import('../pages/corporate/_offer/guide/index.vue' /* webpackChunkName: "pages/corporate/_offer/guide/index" */))
const _04fc79e6 = () => interopDefault(import('../pages/corporate/_offer/guide/_uid.vue' /* webpackChunkName: "pages/corporate/_offer/guide/_uid" */))
const _234e39b8 = () => interopDefault(import('../pages/support/_uid/_child/index.vue' /* webpackChunkName: "pages/support/_uid/_child/index" */))
const _2c014cbc = () => interopDefault(import('../pages/legal/_uid/_child/index.vue' /* webpackChunkName: "pages/legal/_uid/_child/index" */))
const _5ccece7a = () => interopDefault(import('../pages/services/_uid/_child/index.vue' /* webpackChunkName: "pages/services/_uid/_child/index" */))
const _ddb3f914 = () => interopDefault(import('../pages/about/_uid/_child/index.vue' /* webpackChunkName: "pages/about/_uid/_child/index" */))
const _fc2d2756 = () => interopDefault(import('../pages/corporate/_offer/_product/index.vue' /* webpackChunkName: "pages/corporate/_offer/_product/index" */))
const _dadc8188 = () => interopDefault(import('../pages/regions/_region/_country/index.vue' /* webpackChunkName: "pages/regions/_region/_country/index" */))
const _20b92aa9 = () => interopDefault(import('../pages/corporate/_offer/_product/_form/index.vue' /* webpackChunkName: "pages/corporate/_offer/_product/_form/index" */))
const _561f86b6 = () => interopDefault(import('../pages/_offer/index.vue' /* webpackChunkName: "pages/_offer/index" */))
const _429b9263 = () => interopDefault(import('../pages/_offer/guide/index.vue' /* webpackChunkName: "pages/_offer/guide/index" */))
const _02a4a290 = () => interopDefault(import('../pages/_offer/guide/_uid.vue' /* webpackChunkName: "pages/_offer/guide/_uid" */))
const _aecda002 = () => interopDefault(import('../pages/_offer/_product/index.vue' /* webpackChunkName: "pages/_offer/_product/index" */))
const _6fa3f25a = () => interopDefault(import('../pages/_offer/_product/_form/index.vue' /* webpackChunkName: "pages/_offer/_product/_form/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _98173ca8,
    name: "index___de"
  }, {
    path: "/en",
    component: _98173ca8,
    name: "index___en"
  }, {
    path: "/es",
    component: _98173ca8,
    name: "index___es"
  }, {
    path: "/fr",
    component: _98173ca8,
    name: "index___fr"
  }, {
    path: "/nl",
    component: _98173ca8,
    name: "index___nl"
  }, {
    path: "/de/alle-unsere-guides",
    component: _0a9b3eb8,
    name: "guides-sitemap___de"
  }, {
    path: "/de/anmelden",
    component: _5e96a5f4,
    name: "login___de"
  }, {
    path: "/de/guides-thematiques",
    component: _029ab42a,
    name: "taxonomy___de"
  }, {
    path: "/de/kontakt",
    component: _4d16407d,
    name: "contact___de"
  }, {
    path: "/de/leïtfaden",
    component: _62372f86,
    name: "guides-themes___de"
  }, {
    path: "/de/preview",
    component: _7fc4ad10,
    name: "prismic-preview___de"
  }, {
    path: "/de/slice-simulator",
    component: _ddf31f3a,
    name: "slice-simulator___de"
  }, {
    path: "/de/unternehmen",
    component: _0ef7ebfc,
    name: "corporate___de"
  }, {
    path: "/de/versicherungen",
    component: _50edba70,
    name: "offers___de"
  }, {
    path: "/de/ziellaender",
    component: _72e992cb,
    name: "regions-destinations___de"
  }, {
    path: "/en/all-our-guides",
    component: _0a9b3eb8,
    name: "guides-sitemap___en"
  }, {
    path: "/en/contact",
    component: _4d16407d,
    name: "contact___en"
  }, {
    path: "/en/corporate",
    component: _0ef7ebfc,
    name: "corporate___en"
  }, {
    path: "/en/destinations",
    component: _72e992cb,
    name: "regions-destinations___en"
  }, {
    path: "/en/guides",
    component: _62372f86,
    name: "guides-themes___en"
  }, {
    path: "/en/guides-thematiques",
    component: _029ab42a,
    name: "taxonomy___en"
  }, {
    path: "/en/login",
    component: _5e96a5f4,
    name: "login___en"
  }, {
    path: "/en/plans",
    component: _50edba70,
    name: "offers___en"
  }, {
    path: "/en/preview",
    component: _7fc4ad10,
    name: "prismic-preview___en"
  }, {
    path: "/en/slice-simulator",
    component: _ddf31f3a,
    name: "slice-simulator___en"
  }, {
    path: "/es/contacto",
    component: _4d16407d,
    name: "contact___es"
  }, {
    path: "/es/destinos",
    component: _72e992cb,
    name: "regions-destinations___es"
  }, {
    path: "/es/empresas",
    component: _0ef7ebfc,
    name: "corporate___es"
  }, {
    path: "/es/guias",
    component: _62372f86,
    name: "guides-themes___es"
  }, {
    path: "/es/guides-thematiques",
    component: _029ab42a,
    name: "taxonomy___es"
  }, {
    path: "/es/iniciar-sesion",
    component: _5e96a5f4,
    name: "login___es"
  }, {
    path: "/es/preview",
    component: _7fc4ad10,
    name: "prismic-preview___es"
  }, {
    path: "/es/seguros",
    component: _50edba70,
    name: "offers___es"
  }, {
    path: "/es/slice-simulator",
    component: _ddf31f3a,
    name: "slice-simulator___es"
  }, {
    path: "/es/todas-nuestras-guias",
    component: _0a9b3eb8,
    name: "guides-sitemap___es"
  }, {
    path: "/fr/assurances",
    component: _50edba70,
    name: "offers___fr"
  }, {
    path: "/fr/connexion",
    component: _5e96a5f4,
    name: "login___fr"
  }, {
    path: "/fr/contact",
    component: _4d16407d,
    name: "contact___fr"
  }, {
    path: "/fr/destinations",
    component: _72e992cb,
    name: "regions-destinations___fr"
  }, {
    path: "/fr/entreprises",
    component: _0ef7ebfc,
    name: "corporate___fr"
  }, {
    path: "/fr/guides",
    component: _62372f86,
    name: "guides-themes___fr"
  }, {
    path: "/fr/guides-thematiques",
    component: _029ab42a,
    name: "taxonomy___fr"
  }, {
    path: "/fr/preview",
    component: _7fc4ad10,
    name: "prismic-preview___fr"
  }, {
    path: "/fr/slice-simulator",
    component: _ddf31f3a,
    name: "slice-simulator___fr"
  }, {
    path: "/fr/tous-nos-guides",
    component: _0a9b3eb8,
    name: "guides-sitemap___fr"
  }, {
    path: "/nl/alle-gidsen",
    component: _0a9b3eb8,
    name: "guides-sitemap___nl"
  }, {
    path: "/nl/bedrijven",
    component: _0ef7ebfc,
    name: "corporate___nl"
  }, {
    path: "/nl/contacten",
    component: _4d16407d,
    name: "contact___nl"
  }, {
    path: "/nl/destinations",
    component: _72e992cb,
    name: "regions-destinations___nl"
  }, {
    path: "/nl/gidsen",
    component: _62372f86,
    name: "guides-themes___nl"
  }, {
    path: "/nl/guides-thematiques",
    component: _029ab42a,
    name: "taxonomy___nl"
  }, {
    path: "/nl/inloggen",
    component: _5e96a5f4,
    name: "login___nl"
  }, {
    path: "/nl/preview",
    component: _7fc4ad10,
    name: "prismic-preview___nl"
  }, {
    path: "/nl/slice-simulator",
    component: _ddf31f3a,
    name: "slice-simulator___nl"
  }, {
    path: "/nl/verzekeringen",
    component: _50edba70,
    name: "offers___nl"
  }, {
    path: "/de/hilfe/haeufig-gestellte-fragen",
    component: _ea637e68,
    name: "support-faq-faqs___de"
  }, {
    path: "/de/ueber-april/neuigkeiten",
    component: _155f7c6e,
    name: "about-news___de"
  }, {
    path: "/de/unternehmen/alle-unsere-guides",
    component: _9271c0bc,
    name: "corporate-guides-sitemap___de"
  }, {
    path: "/de/unternehmen/anmelden",
    component: _0a3e3cdc,
    name: "corporate-login___de"
  }, {
    path: "/de/unternehmen/guides-thematiques",
    component: _9c57e6d8,
    name: "corporate-taxonomy___de"
  }, {
    path: "/de/unternehmen/kontakt",
    component: _4a7b32d3,
    name: "corporate-contact___de"
  }, {
    path: "/de/unternehmen/leïtfaden",
    component: _cab87bda,
    name: "corporate-guides-themes___de"
  }, {
    path: "/de/unternehmen/ziellaender",
    component: _1f1573b5,
    name: "corporate-regions-destinations___de"
  }, {
    path: "/de/versicherungen/guide",
    component: _55bc464e,
    name: "guide___de"
  }, {
    path: "/en/about-april/news",
    component: _155f7c6e,
    name: "about-news___en"
  }, {
    path: "/en/corporate/all-our-guides",
    component: _9271c0bc,
    name: "corporate-guides-sitemap___en"
  }, {
    path: "/en/corporate/contact",
    component: _4a7b32d3,
    name: "corporate-contact___en"
  }, {
    path: "/en/corporate/destinations",
    component: _1f1573b5,
    name: "corporate-regions-destinations___en"
  }, {
    path: "/en/corporate/guides",
    component: _cab87bda,
    name: "corporate-guides-themes___en"
  }, {
    path: "/en/corporate/guides-thematiques",
    component: _9c57e6d8,
    name: "corporate-taxonomy___en"
  }, {
    path: "/en/corporate/login",
    component: _0a3e3cdc,
    name: "corporate-login___en"
  }, {
    path: "/en/insurance/guide",
    component: _55bc464e,
    name: "guide___en"
  }, {
    path: "/en/support/faq",
    component: _ea637e68,
    name: "support-faq-faqs___en"
  }, {
    path: "/es/acerca-de-april/noticias",
    component: _155f7c6e,
    name: "about-news___es"
  }, {
    path: "/es/empresas/contacto",
    component: _4a7b32d3,
    name: "corporate-contact___es"
  }, {
    path: "/es/empresas/destinos",
    component: _1f1573b5,
    name: "corporate-regions-destinations___es"
  }, {
    path: "/es/empresas/guias",
    component: _cab87bda,
    name: "corporate-guides-themes___es"
  }, {
    path: "/es/empresas/guides-thematiques",
    component: _9c57e6d8,
    name: "corporate-taxonomy___es"
  }, {
    path: "/es/empresas/iniciar-sesion",
    component: _0a3e3cdc,
    name: "corporate-login___es"
  }, {
    path: "/es/empresas/todas-nuestras-guias",
    component: _9271c0bc,
    name: "corporate-guides-sitemap___es"
  }, {
    path: "/es/seguro/guia",
    component: _55bc464e,
    name: "guide___es"
  }, {
    path: "/es/soporte/preguntas-frecuentes",
    component: _ea637e68,
    name: "support-faq-faqs___es"
  }, {
    path: "/fr/a-propos/actualites",
    component: _155f7c6e,
    name: "about-news___fr"
  }, {
    path: "/fr/aide/faq",
    component: _ea637e68,
    name: "support-faq-faqs___fr"
  }, {
    path: "/fr/assurance/guide",
    component: _55bc464e,
    name: "guide___fr"
  }, {
    path: "/fr/entreprises/connexion",
    component: _0a3e3cdc,
    name: "corporate-login___fr"
  }, {
    path: "/fr/entreprises/contact",
    component: _4a7b32d3,
    name: "corporate-contact___fr"
  }, {
    path: "/fr/entreprises/destinations",
    component: _1f1573b5,
    name: "corporate-regions-destinations___fr"
  }, {
    path: "/fr/entreprises/guides",
    component: _cab87bda,
    name: "corporate-guides-themes___fr"
  }, {
    path: "/fr/entreprises/guides-thematiques",
    component: _9c57e6d8,
    name: "corporate-taxonomy___fr"
  }, {
    path: "/fr/entreprises/tous-nos-guides",
    component: _9271c0bc,
    name: "corporate-guides-sitemap___fr"
  }, {
    path: "/nl/bedrijven/alle-gidsen",
    component: _9271c0bc,
    name: "corporate-guides-sitemap___nl"
  }, {
    path: "/nl/bedrijven/contacten",
    component: _4a7b32d3,
    name: "corporate-contact___nl"
  }, {
    path: "/nl/bedrijven/destinations",
    component: _1f1573b5,
    name: "corporate-regions-destinations___nl"
  }, {
    path: "/nl/bedrijven/gidsen",
    component: _cab87bda,
    name: "corporate-guides-themes___nl"
  }, {
    path: "/nl/bedrijven/guides-thematiques",
    component: _9c57e6d8,
    name: "corporate-taxonomy___nl"
  }, {
    path: "/nl/bedrijven/inloggen",
    component: _0a3e3cdc,
    name: "corporate-login___nl"
  }, {
    path: "/nl/ondersteuning/veel-gestelde-vragen",
    component: _ea637e68,
    name: "support-faq-faqs___nl"
  }, {
    path: "/nl/over-april/nieuws",
    component: _155f7c6e,
    name: "about-news___nl"
  }, {
    path: "/nl/verzekeringen/gids",
    component: _55bc464e,
    name: "guide___nl"
  }, {
    path: "/de/unternehmen/hilfe/haeufig-gestellte-fragen",
    component: _511326b6,
    name: "corporate-support-faq-faqs___de"
  }, {
    path: "/de/unternehmen/ueber-april/neuigkeiten",
    component: _48b73b50,
    name: "corporate-about-news___de"
  }, {
    path: "/de/unternehmen/versicherungen/guide",
    component: _0eab6caf,
    name: "corporate-guide___de"
  }, {
    path: "/en/corporate/about-april/news",
    component: _48b73b50,
    name: "corporate-about-news___en"
  }, {
    path: "/en/corporate/insurance/guide",
    component: _0eab6caf,
    name: "corporate-guide___en"
  }, {
    path: "/en/corporate/support/faq",
    component: _511326b6,
    name: "corporate-support-faq-faqs___en"
  }, {
    path: "/es/empresas/acerca-de-april/noticias",
    component: _48b73b50,
    name: "corporate-about-news___es"
  }, {
    path: "/es/empresas/seguro/guia",
    component: _0eab6caf,
    name: "corporate-guide___es"
  }, {
    path: "/es/empresas/soporte/preguntas-frecuentes",
    component: _511326b6,
    name: "corporate-support-faq-faqs___es"
  }, {
    path: "/fr/entreprises/a-propos/actualites",
    component: _48b73b50,
    name: "corporate-about-news___fr"
  }, {
    path: "/fr/entreprises/aide/faq",
    component: _511326b6,
    name: "corporate-support-faq-faqs___fr"
  }, {
    path: "/fr/entreprises/assurance/guide",
    component: _0eab6caf,
    name: "corporate-guide___fr"
  }, {
    path: "/nl/bedrijven/ondersteuning/veel-gestelde-vragen",
    component: _511326b6,
    name: "corporate-support-faq-faqs___nl"
  }, {
    path: "/nl/bedrijven/over-april/nieuws",
    component: _48b73b50,
    name: "corporate-about-news___nl"
  }, {
    path: "/nl/bedrijven/verzekeringen/gids",
    component: _0eab6caf,
    name: "corporate-guide___nl"
  }, {
    path: "/de/unternehmen/hilfe/haeufig-gestellte-fragen/:faq",
    component: _ac97a31a,
    name: "corporate-support-faq-faq___de"
  }, {
    path: "/de/unternehmen/ueber-april/neuigkeiten/:article",
    component: _8dbfa438,
    name: "corporate-about-news-article___de"
  }, {
    path: "/de/unternehmen/versicherungen/guide/:uid",
    component: _4b4a4ec4,
    name: "corporate-guide-uid___de"
  }, {
    path: "/en/corporate/about-april/news/:article",
    component: _8dbfa438,
    name: "corporate-about-news-article___en"
  }, {
    path: "/en/corporate/insurance/guide/:uid",
    component: _4b4a4ec4,
    name: "corporate-guide-uid___en"
  }, {
    path: "/en/corporate/support/faq/:faq",
    component: _ac97a31a,
    name: "corporate-support-faq-faq___en"
  }, {
    path: "/es/empresas/acerca-de-april/noticias/:article",
    component: _8dbfa438,
    name: "corporate-about-news-article___es"
  }, {
    path: "/es/empresas/seguro/guia/:uid",
    component: _4b4a4ec4,
    name: "corporate-guide-uid___es"
  }, {
    path: "/es/empresas/soporte/preguntas-frecuentes/:faq",
    component: _ac97a31a,
    name: "corporate-support-faq-faq___es"
  }, {
    path: "/fr/entreprises/a-propos/actualites/:article",
    component: _8dbfa438,
    name: "corporate-about-news-article___fr"
  }, {
    path: "/fr/entreprises/aide/faq/:faq",
    component: _ac97a31a,
    name: "corporate-support-faq-faq___fr"
  }, {
    path: "/fr/entreprises/assurance/guide/:uid",
    component: _4b4a4ec4,
    name: "corporate-guide-uid___fr"
  }, {
    path: "/nl/bedrijven/ondersteuning/veel-gestelde-vragen/:faq",
    component: _ac97a31a,
    name: "corporate-support-faq-faq___nl"
  }, {
    path: "/nl/bedrijven/over-april/nieuws/:article",
    component: _8dbfa438,
    name: "corporate-about-news-article___nl"
  }, {
    path: "/nl/bedrijven/verzekeringen/gids/:uid",
    component: _4b4a4ec4,
    name: "corporate-guide-uid___nl"
  }, {
    path: "/de/hilfe/haeufig-gestellte-fragen/:faq",
    component: _58fce009,
    name: "support-faq-faq___de"
  }, {
    path: "/de/ueber-april/neuigkeiten/:article",
    component: _94dbe6e4,
    name: "about-news-article___de"
  }, {
    path: "/de/unternehmen/hilfe/:uid",
    component: _d223d9d8,
    name: "corporate-support-uid___de"
  }, {
    path: "/de/unternehmen/kontakt/:uid",
    component: _9538cb06,
    name: "corporate-contact-form-uid___de"
  }, {
    path: "/de/unternehmen/landing/:uid",
    component: _20ade409,
    name: "corporate-landing-uid___de"
  }, {
    path: "/de/unternehmen/legal/:uid",
    component: _414878ea,
    name: "corporate-legal-uid___de"
  }, {
    path: "/de/unternehmen/leïtfaden/:uid",
    component: _484b9e3f,
    name: "corporate-taxonomy-uid___de"
  }, {
    path: "/de/unternehmen/serviceleistungen/:uid",
    component: _b62bfe56,
    name: "corporate-services-uid___de"
  }, {
    path: "/de/unternehmen/ueber-april/:uid",
    component: _62b87596,
    name: "corporate-about-uid___de"
  }, {
    path: "/de/unternehmen/ziellaender/:region",
    component: _751ee0f6,
    name: "corporate-regions-region___de"
  }, {
    path: "/de/versicherungen/guide/:uid",
    component: _64df9d4c,
    name: "guide-uid___de"
  }, {
    path: "/en/about-april/news/:article",
    component: _94dbe6e4,
    name: "about-news-article___en"
  }, {
    path: "/en/corporate/about-april/:uid",
    component: _62b87596,
    name: "corporate-about-uid___en"
  }, {
    path: "/en/corporate/contact/:uid",
    component: _9538cb06,
    name: "corporate-contact-form-uid___en"
  }, {
    path: "/en/corporate/destinations/:region",
    component: _751ee0f6,
    name: "corporate-regions-region___en"
  }, {
    path: "/en/corporate/guides/:uid",
    component: _484b9e3f,
    name: "corporate-taxonomy-uid___en"
  }, {
    path: "/en/corporate/landing/:uid",
    component: _20ade409,
    name: "corporate-landing-uid___en"
  }, {
    path: "/en/corporate/legal/:uid",
    component: _414878ea,
    name: "corporate-legal-uid___en"
  }, {
    path: "/en/corporate/services/:uid",
    component: _b62bfe56,
    name: "corporate-services-uid___en"
  }, {
    path: "/en/corporate/support/:uid",
    component: _d223d9d8,
    name: "corporate-support-uid___en"
  }, {
    path: "/en/insurance/guide/:uid",
    component: _64df9d4c,
    name: "guide-uid___en"
  }, {
    path: "/en/support/faq/:faq",
    component: _58fce009,
    name: "support-faq-faq___en"
  }, {
    path: "/es/acerca-de-april/noticias/:article",
    component: _94dbe6e4,
    name: "about-news-article___es"
  }, {
    path: "/es/empresas/acerca-de-april/:uid",
    component: _62b87596,
    name: "corporate-about-uid___es"
  }, {
    path: "/es/empresas/contacto/:uid",
    component: _9538cb06,
    name: "corporate-contact-form-uid___es"
  }, {
    path: "/es/empresas/destinos/:region",
    component: _751ee0f6,
    name: "corporate-regions-region___es"
  }, {
    path: "/es/empresas/guias/:uid",
    component: _484b9e3f,
    name: "corporate-taxonomy-uid___es"
  }, {
    path: "/es/empresas/landing/:uid",
    component: _20ade409,
    name: "corporate-landing-uid___es"
  }, {
    path: "/es/empresas/legal/:uid",
    component: _414878ea,
    name: "corporate-legal-uid___es"
  }, {
    path: "/es/empresas/servicios/:uid",
    component: _b62bfe56,
    name: "corporate-services-uid___es"
  }, {
    path: "/es/empresas/soporte/:uid",
    component: _d223d9d8,
    name: "corporate-support-uid___es"
  }, {
    path: "/es/seguro/guia/:uid",
    component: _64df9d4c,
    name: "guide-uid___es"
  }, {
    path: "/es/soporte/preguntas-frecuentes/:faq",
    component: _58fce009,
    name: "support-faq-faq___es"
  }, {
    path: "/fr/a-propos/actualites/:article",
    component: _94dbe6e4,
    name: "about-news-article___fr"
  }, {
    path: "/fr/aide/faq/:faq",
    component: _58fce009,
    name: "support-faq-faq___fr"
  }, {
    path: "/fr/assurance/guide/:uid",
    component: _64df9d4c,
    name: "guide-uid___fr"
  }, {
    path: "/fr/entreprises/a-propos/:uid",
    component: _62b87596,
    name: "corporate-about-uid___fr"
  }, {
    path: "/fr/entreprises/aide/:uid",
    component: _d223d9d8,
    name: "corporate-support-uid___fr"
  }, {
    path: "/fr/entreprises/contact/:uid",
    component: _9538cb06,
    name: "corporate-contact-form-uid___fr"
  }, {
    path: "/fr/entreprises/destinations/:region",
    component: _751ee0f6,
    name: "corporate-regions-region___fr"
  }, {
    path: "/fr/entreprises/guides/:uid",
    component: _484b9e3f,
    name: "corporate-taxonomy-uid___fr"
  }, {
    path: "/fr/entreprises/landing/:uid",
    component: _20ade409,
    name: "corporate-landing-uid___fr"
  }, {
    path: "/fr/entreprises/legal/:uid",
    component: _414878ea,
    name: "corporate-legal-uid___fr"
  }, {
    path: "/fr/entreprises/services/:uid",
    component: _b62bfe56,
    name: "corporate-services-uid___fr"
  }, {
    path: "/nl/bedrijven/contacten/:uid",
    component: _9538cb06,
    name: "corporate-contact-form-uid___nl"
  }, {
    path: "/nl/bedrijven/destinations/:region",
    component: _751ee0f6,
    name: "corporate-regions-region___nl"
  }, {
    path: "/nl/bedrijven/diensten/:uid",
    component: _b62bfe56,
    name: "corporate-services-uid___nl"
  }, {
    path: "/nl/bedrijven/gidsen/:uid",
    component: _484b9e3f,
    name: "corporate-taxonomy-uid___nl"
  }, {
    path: "/nl/bedrijven/landing/:uid",
    component: _20ade409,
    name: "corporate-landing-uid___nl"
  }, {
    path: "/nl/bedrijven/legal/:uid",
    component: _414878ea,
    name: "corporate-legal-uid___nl"
  }, {
    path: "/nl/bedrijven/ondersteuning/:uid",
    component: _d223d9d8,
    name: "corporate-support-uid___nl"
  }, {
    path: "/nl/bedrijven/over-april/:uid",
    component: _62b87596,
    name: "corporate-about-uid___nl"
  }, {
    path: "/nl/ondersteuning/veel-gestelde-vragen/:faq",
    component: _58fce009,
    name: "support-faq-faq___nl"
  }, {
    path: "/nl/over-april/nieuws/:article",
    component: _94dbe6e4,
    name: "about-news-article___nl"
  }, {
    path: "/nl/verzekeringen/gids/:uid",
    component: _64df9d4c,
    name: "guide-uid___nl"
  }, {
    path: "/de/unternehmen/hilfe/:uid/:child",
    component: _26dc5b0e,
    name: "corporate-support-uid-child___de"
  }, {
    path: "/de/unternehmen/legal/:uid/:child",
    component: _9f9c4f10,
    name: "corporate-legal-uid-child___de"
  }, {
    path: "/de/unternehmen/serviceleistungen/:uid/:child",
    component: _3fcea22d,
    name: "corporate-services-uid-child___de"
  }, {
    path: "/de/unternehmen/ueber-april/:uid/:child",
    component: _5758824c,
    name: "corporate-about-uid-child___de"
  }, {
    path: "/de/unternehmen/ziellaender/:region/:country",
    component: _7a7103a6,
    name: "corporate-regions-region-country___de"
  }, {
    path: "/en/corporate/about-april/:uid/:child",
    component: _5758824c,
    name: "corporate-about-uid-child___en"
  }, {
    path: "/en/corporate/destinations/:region/:country",
    component: _7a7103a6,
    name: "corporate-regions-region-country___en"
  }, {
    path: "/en/corporate/legal/:uid/:child",
    component: _9f9c4f10,
    name: "corporate-legal-uid-child___en"
  }, {
    path: "/en/corporate/services/:uid/:child",
    component: _3fcea22d,
    name: "corporate-services-uid-child___en"
  }, {
    path: "/en/corporate/support/:uid/:child",
    component: _26dc5b0e,
    name: "corporate-support-uid-child___en"
  }, {
    path: "/es/empresas/acerca-de-april/:uid/:child",
    component: _5758824c,
    name: "corporate-about-uid-child___es"
  }, {
    path: "/es/empresas/destinos/:region/:country",
    component: _7a7103a6,
    name: "corporate-regions-region-country___es"
  }, {
    path: "/es/empresas/legal/:uid/:child",
    component: _9f9c4f10,
    name: "corporate-legal-uid-child___es"
  }, {
    path: "/es/empresas/servicios/:uid/:child",
    component: _3fcea22d,
    name: "corporate-services-uid-child___es"
  }, {
    path: "/es/empresas/soporte/:uid/:child",
    component: _26dc5b0e,
    name: "corporate-support-uid-child___es"
  }, {
    path: "/fr/entreprises/a-propos/:uid/:child",
    component: _5758824c,
    name: "corporate-about-uid-child___fr"
  }, {
    path: "/fr/entreprises/aide/:uid/:child",
    component: _26dc5b0e,
    name: "corporate-support-uid-child___fr"
  }, {
    path: "/fr/entreprises/destinations/:region/:country",
    component: _7a7103a6,
    name: "corporate-regions-region-country___fr"
  }, {
    path: "/fr/entreprises/legal/:uid/:child",
    component: _9f9c4f10,
    name: "corporate-legal-uid-child___fr"
  }, {
    path: "/fr/entreprises/services/:uid/:child",
    component: _3fcea22d,
    name: "corporate-services-uid-child___fr"
  }, {
    path: "/nl/bedrijven/destinations/:region/:country",
    component: _7a7103a6,
    name: "corporate-regions-region-country___nl"
  }, {
    path: "/nl/bedrijven/diensten/:uid/:child",
    component: _3fcea22d,
    name: "corporate-services-uid-child___nl"
  }, {
    path: "/nl/bedrijven/legal/:uid/:child",
    component: _9f9c4f10,
    name: "corporate-legal-uid-child___nl"
  }, {
    path: "/nl/bedrijven/ondersteuning/:uid/:child",
    component: _26dc5b0e,
    name: "corporate-support-uid-child___nl"
  }, {
    path: "/nl/bedrijven/over-april/:uid/:child",
    component: _5758824c,
    name: "corporate-about-uid-child___nl"
  }, {
    path: "/de/hilfe/:uid",
    component: _4e4affaa,
    name: "support-uid___de"
  }, {
    path: "/de/kontakt/:uid",
    component: _99e879b2,
    name: "contact-form-uid___de"
  }, {
    path: "/de/landing/:uid",
    component: _2905791f,
    name: "landing-uid___de"
  }, {
    path: "/de/legal/:uid",
    component: _7b039300,
    name: "legal-uid___de"
  }, {
    path: "/de/leïtfaden/:uid",
    component: _4ae6abe9,
    name: "taxonomy-uid___de"
  }, {
    path: "/de/serviceleistungen/:uid",
    component: _4daab202,
    name: "services-uid___de"
  }, {
    path: "/de/ueber-april/:uid",
    component: _c718e0a8,
    name: "about-uid___de"
  }, {
    path: "/de/unternehmen/:offer",
    component: _4dc7f1a0,
    name: "corporate-offer___de"
  }, {
    path: "/de/ziellaender/:region",
    component: _c862b6c0,
    name: "regions-region___de"
  }, {
    path: "/en/about-april/:uid",
    component: _c718e0a8,
    name: "about-uid___en"
  }, {
    path: "/en/contact/:uid",
    component: _99e879b2,
    name: "contact-form-uid___en"
  }, {
    path: "/en/corporate/:offer",
    component: _4dc7f1a0,
    name: "corporate-offer___en"
  }, {
    path: "/en/destinations/:region",
    component: _c862b6c0,
    name: "regions-region___en"
  }, {
    path: "/en/guides/:uid",
    component: _4ae6abe9,
    name: "taxonomy-uid___en"
  }, {
    path: "/en/landing/:uid",
    component: _2905791f,
    name: "landing-uid___en"
  }, {
    path: "/en/legal/:uid",
    component: _7b039300,
    name: "legal-uid___en"
  }, {
    path: "/en/services/:uid",
    component: _4daab202,
    name: "services-uid___en"
  }, {
    path: "/en/support/:uid",
    component: _4e4affaa,
    name: "support-uid___en"
  }, {
    path: "/es/acerca-de-april/:uid",
    component: _c718e0a8,
    name: "about-uid___es"
  }, {
    path: "/es/contacto/:uid",
    component: _99e879b2,
    name: "contact-form-uid___es"
  }, {
    path: "/es/destinos/:region",
    component: _c862b6c0,
    name: "regions-region___es"
  }, {
    path: "/es/empresas/:offer",
    component: _4dc7f1a0,
    name: "corporate-offer___es"
  }, {
    path: "/es/guias/:uid",
    component: _4ae6abe9,
    name: "taxonomy-uid___es"
  }, {
    path: "/es/landing/:uid",
    component: _2905791f,
    name: "landing-uid___es"
  }, {
    path: "/es/legal/:uid",
    component: _7b039300,
    name: "legal-uid___es"
  }, {
    path: "/es/servicios/:uid",
    component: _4daab202,
    name: "services-uid___es"
  }, {
    path: "/es/soporte/:uid",
    component: _4e4affaa,
    name: "support-uid___es"
  }, {
    path: "/fr/a-propos/:uid",
    component: _c718e0a8,
    name: "about-uid___fr"
  }, {
    path: "/fr/aide/:uid",
    component: _4e4affaa,
    name: "support-uid___fr"
  }, {
    path: "/fr/contact/:uid",
    component: _99e879b2,
    name: "contact-form-uid___fr"
  }, {
    path: "/fr/destinations/:region",
    component: _c862b6c0,
    name: "regions-region___fr"
  }, {
    path: "/fr/entreprises/:offer",
    component: _4dc7f1a0,
    name: "corporate-offer___fr"
  }, {
    path: "/fr/guides/:uid",
    component: _4ae6abe9,
    name: "taxonomy-uid___fr"
  }, {
    path: "/fr/landing/:uid",
    component: _2905791f,
    name: "landing-uid___fr"
  }, {
    path: "/fr/legal/:uid",
    component: _7b039300,
    name: "legal-uid___fr"
  }, {
    path: "/fr/services/:uid",
    component: _4daab202,
    name: "services-uid___fr"
  }, {
    path: "/nl/bedrijven/:offer",
    component: _4dc7f1a0,
    name: "corporate-offer___nl"
  }, {
    path: "/nl/contacten/:uid",
    component: _99e879b2,
    name: "contact-form-uid___nl"
  }, {
    path: "/nl/destinations/:region",
    component: _c862b6c0,
    name: "regions-region___nl"
  }, {
    path: "/nl/diensten/:uid",
    component: _4daab202,
    name: "services-uid___nl"
  }, {
    path: "/nl/gidsen/:uid",
    component: _4ae6abe9,
    name: "taxonomy-uid___nl"
  }, {
    path: "/nl/landing/:uid",
    component: _2905791f,
    name: "landing-uid___nl"
  }, {
    path: "/nl/legal/:uid",
    component: _7b039300,
    name: "legal-uid___nl"
  }, {
    path: "/nl/ondersteuning/:uid",
    component: _4e4affaa,
    name: "support-uid___nl"
  }, {
    path: "/nl/over-april/:uid",
    component: _c718e0a8,
    name: "about-uid___nl"
  }, {
    path: "/de/unternehmen/:offer/guide",
    component: _e982b466,
    name: "corporate-offer-guide___de"
  }, {
    path: "/en/corporate/:offer/guide",
    component: _e982b466,
    name: "corporate-offer-guide___en"
  }, {
    path: "/es/empresas/:offer/guia",
    component: _e982b466,
    name: "corporate-offer-guide___es"
  }, {
    path: "/fr/entreprises/:offer/guide",
    component: _e982b466,
    name: "corporate-offer-guide___fr"
  }, {
    path: "/nl/bedrijven/:offer/gids",
    component: _e982b466,
    name: "corporate-offer-guide___nl"
  }, {
    path: "/de/unternehmen/:offer/guide/:uid",
    component: _04fc79e6,
    name: "corporate-offer-guide-uid___de"
  }, {
    path: "/en/corporate/:offer/guide/:uid",
    component: _04fc79e6,
    name: "corporate-offer-guide-uid___en"
  }, {
    path: "/es/empresas/:offer/guia/:uid",
    component: _04fc79e6,
    name: "corporate-offer-guide-uid___es"
  }, {
    path: "/fr/entreprises/:offer/guide/:uid",
    component: _04fc79e6,
    name: "corporate-offer-guide-uid___fr"
  }, {
    path: "/nl/bedrijven/:offer/gids/:uid",
    component: _04fc79e6,
    name: "corporate-offer-guide-uid___nl"
  }, {
    path: "/de/hilfe/:uid/:child",
    component: _234e39b8,
    name: "support-uid-child___de"
  }, {
    path: "/de/legal/:uid/:child",
    component: _2c014cbc,
    name: "legal-uid-child___de"
  }, {
    path: "/de/serviceleistungen/:uid/:child",
    component: _5ccece7a,
    name: "services-uid-child___de"
  }, {
    path: "/de/ueber-april/:uid/:child",
    component: _ddb3f914,
    name: "about-uid-child___de"
  }, {
    path: "/de/unternehmen/:offer/:product",
    component: _fc2d2756,
    name: "corporate-offer-product___de"
  }, {
    path: "/de/ziellaender/:region/:country",
    component: _dadc8188,
    name: "regions-region-country___de"
  }, {
    path: "/en/about-april/:uid/:child",
    component: _ddb3f914,
    name: "about-uid-child___en"
  }, {
    path: "/en/corporate/:offer/:product",
    component: _fc2d2756,
    name: "corporate-offer-product___en"
  }, {
    path: "/en/destinations/:region/:country",
    component: _dadc8188,
    name: "regions-region-country___en"
  }, {
    path: "/en/legal/:uid/:child",
    component: _2c014cbc,
    name: "legal-uid-child___en"
  }, {
    path: "/en/services/:uid/:child",
    component: _5ccece7a,
    name: "services-uid-child___en"
  }, {
    path: "/en/support/:uid/:child",
    component: _234e39b8,
    name: "support-uid-child___en"
  }, {
    path: "/es/acerca-de-april/:uid/:child",
    component: _ddb3f914,
    name: "about-uid-child___es"
  }, {
    path: "/es/destinos/:region/:country",
    component: _dadc8188,
    name: "regions-region-country___es"
  }, {
    path: "/es/empresas/:offer/:product",
    component: _fc2d2756,
    name: "corporate-offer-product___es"
  }, {
    path: "/es/legal/:uid/:child",
    component: _2c014cbc,
    name: "legal-uid-child___es"
  }, {
    path: "/es/servicios/:uid/:child",
    component: _5ccece7a,
    name: "services-uid-child___es"
  }, {
    path: "/es/soporte/:uid/:child",
    component: _234e39b8,
    name: "support-uid-child___es"
  }, {
    path: "/fr/a-propos/:uid/:child",
    component: _ddb3f914,
    name: "about-uid-child___fr"
  }, {
    path: "/fr/aide/:uid/:child",
    component: _234e39b8,
    name: "support-uid-child___fr"
  }, {
    path: "/fr/destinations/:region/:country",
    component: _dadc8188,
    name: "regions-region-country___fr"
  }, {
    path: "/fr/entreprises/:offer/:product",
    component: _fc2d2756,
    name: "corporate-offer-product___fr"
  }, {
    path: "/fr/legal/:uid/:child",
    component: _2c014cbc,
    name: "legal-uid-child___fr"
  }, {
    path: "/fr/services/:uid/:child",
    component: _5ccece7a,
    name: "services-uid-child___fr"
  }, {
    path: "/nl/bedrijven/:offer/:product",
    component: _fc2d2756,
    name: "corporate-offer-product___nl"
  }, {
    path: "/nl/destinations/:region/:country",
    component: _dadc8188,
    name: "regions-region-country___nl"
  }, {
    path: "/nl/diensten/:uid/:child",
    component: _5ccece7a,
    name: "services-uid-child___nl"
  }, {
    path: "/nl/legal/:uid/:child",
    component: _2c014cbc,
    name: "legal-uid-child___nl"
  }, {
    path: "/nl/ondersteuning/:uid/:child",
    component: _234e39b8,
    name: "support-uid-child___nl"
  }, {
    path: "/nl/over-april/:uid/:child",
    component: _ddb3f914,
    name: "about-uid-child___nl"
  }, {
    path: "/de/unternehmen/:offer/:product/:form",
    component: _20b92aa9,
    name: "corporate-offer-product-form___de"
  }, {
    path: "/en/corporate/:offer/:product/:form",
    component: _20b92aa9,
    name: "corporate-offer-product-form___en"
  }, {
    path: "/es/empresas/:offer/:product/:form",
    component: _20b92aa9,
    name: "corporate-offer-product-form___es"
  }, {
    path: "/fr/entreprises/:offer/:product/:form",
    component: _20b92aa9,
    name: "corporate-offer-product-form___fr"
  }, {
    path: "/nl/bedrijven/:offer/:product/:form",
    component: _20b92aa9,
    name: "corporate-offer-product-form___nl"
  }, {
    path: "/de/:offer",
    component: _561f86b6,
    name: "offer___de"
  }, {
    path: "/en/:offer",
    component: _561f86b6,
    name: "offer___en"
  }, {
    path: "/es/:offer",
    component: _561f86b6,
    name: "offer___es"
  }, {
    path: "/fr/:offer",
    component: _561f86b6,
    name: "offer___fr"
  }, {
    path: "/nl/:offer",
    component: _561f86b6,
    name: "offer___nl"
  }, {
    path: "/de/:offer/guide",
    component: _429b9263,
    name: "offer-guide___de"
  }, {
    path: "/en/:offer/guide",
    component: _429b9263,
    name: "offer-guide___en"
  }, {
    path: "/es/:offer/guia",
    component: _429b9263,
    name: "offer-guide___es"
  }, {
    path: "/fr/:offer/guide",
    component: _429b9263,
    name: "offer-guide___fr"
  }, {
    path: "/nl/:offer/gids",
    component: _429b9263,
    name: "offer-guide___nl"
  }, {
    path: "/de/:offer/guide/:uid",
    component: _02a4a290,
    name: "offer-guide-uid___de"
  }, {
    path: "/en/:offer/guide/:uid",
    component: _02a4a290,
    name: "offer-guide-uid___en"
  }, {
    path: "/es/:offer/guia/:uid",
    component: _02a4a290,
    name: "offer-guide-uid___es"
  }, {
    path: "/fr/:offer/guide/:uid",
    component: _02a4a290,
    name: "offer-guide-uid___fr"
  }, {
    path: "/nl/:offer/gids/:uid",
    component: _02a4a290,
    name: "offer-guide-uid___nl"
  }, {
    path: "/de/:offer/:product",
    component: _aecda002,
    name: "offer-product___de"
  }, {
    path: "/en/:offer/:product",
    component: _aecda002,
    name: "offer-product___en"
  }, {
    path: "/es/:offer/:product",
    component: _aecda002,
    name: "offer-product___es"
  }, {
    path: "/fr/:offer/:product",
    component: _aecda002,
    name: "offer-product___fr"
  }, {
    path: "/nl/:offer/:product",
    component: _aecda002,
    name: "offer-product___nl"
  }, {
    path: "/de/:offer/:product/:form",
    component: _6fa3f25a,
    name: "offer-product-form___de"
  }, {
    path: "/en/:offer/:product/:form",
    component: _6fa3f25a,
    name: "offer-product-form___en"
  }, {
    path: "/es/:offer/:product/:form",
    component: _6fa3f25a,
    name: "offer-product-form___es"
  }, {
    path: "/fr/:offer/:product/:form",
    component: _6fa3f25a,
    name: "offer-product-form___fr"
  }, {
    path: "/nl/:offer/:product/:form",
    component: _6fa3f25a,
    name: "offer-product-form___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
