import getLink from '~/utils/helpers/get-link';

export default function (doc) {
  if (!doc || doc.isBroken) {
    return null;
  }

  const isPro = doc.tags.includes('pro') || doc.tags.includes('corporate');
  const lang = doc.lang.slice(0, 2);

  const type = doc.type;
  const pageType = doc.data?.page_type;
  const offer = doc.data?.offer?.uid;
  const region = doc.data?.region?.uid;
  const product = doc.data?.product?.uid;
  const parentPage = doc.data?.parent?.uid;

  const notFound = `/${lang}/error-404`;

  if (doc.isBroken) {
    return notFound;
  }

  return getLink({
    uid: doc.uid,
    lang,
    type,
    isPro,
    pageType,
    offer,
    region,
    product,
    parentPage,
    notFound,
  });
}
