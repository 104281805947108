import {
  getCorporateRoot,
  getDestinationsRoot,
  getContactRoot,
  getNewsRoot,
  getGuideRoot,
  getAllGuidesRoot,
  getAllGuidesThemesRoot,
  getFaqRoot,
  getSupportRoot,
  getServicesRoot,
  getLegalRoot,
  getAboutRoot,
  getOffersRoot,
  getLandingRoot,
  getLoginRoot,
  getInsuranceRoot,
  getTaxonomyRoot,
} from '../../config/routes';

// Page free template resolver
const getPageLink = ({
  uid,
  prefix,
  pageType,
  parentPage,
  supportRoot,
  servicesRoot,
  legalRoot,
  aboutRoot,
}) => {
  if (pageType === 'about') {
    // about
    if (parentPage) {
      return `/${prefix}/${aboutRoot}/${parentPage}/${uid}`;
    }
    return `/${prefix}/${aboutRoot}/${uid}`;
  } else if (pageType === 'legal') {
    // legal
    if (parentPage) {
      return `/${prefix}/${legalRoot}/${parentPage}/${uid}`;
    }
    return `/${prefix}/${legalRoot}/${uid}`;
  } else if (pageType === 'support') {
    // legal
    if (parentPage) {
      return `/${prefix}/${supportRoot}/${parentPage}/${uid}`;
    }
    return `/${prefix}/${supportRoot}/${uid}`;
  } else {
    // service & default
    if (parentPage) {
      return `/${prefix}/${servicesRoot}/${parentPage}/${uid}`;
    }
    return `/${prefix}/${servicesRoot}/${uid}`;
  }
};

// Form page template resolver
const getFormLink = ({
  uid,
  prefix,
  pageType,
  offer,
  product,
  landingRoot,
  contactRoot,
}) => {
  if (pageType === 'Product form page') {
    return `/${prefix}/${offer}/${product}/${uid}`;
  } else if (pageType === 'Landing form page') {
    return `/${prefix}/${landingRoot}/${uid}`;
  } else {
    return `/${prefix}/${contactRoot}/${uid}`;
  }
};

export default function ({
  uid,
  lang,
  type,
  pageType,
  isPro,
  offer,
  region,
  product,
  parentPage,
  notFound,
}) {
  const corporateRoot = getCorporateRoot(lang);
  const destinationsRoot = getDestinationsRoot(lang);
  const contactRoot = getContactRoot(lang);
  const newsRoot = getNewsRoot(lang);
  const guideRoot = getGuideRoot(lang);
  const allGuidesRoot = getAllGuidesRoot(lang);
  const allGuidesThemesRoot = getAllGuidesThemesRoot(lang);
  const taxonomyRoot = getTaxonomyRoot(lang);
  const faqRoot = getFaqRoot(lang);
  const servicesRoot = getServicesRoot(lang);
  const supportRoot = getSupportRoot(lang);
  const legalRoot = getLegalRoot(lang);
  const offersRoot = getOffersRoot(lang);
  const landingRoot = getLandingRoot(lang);
  const insuranceRoot = getInsuranceRoot(lang);
  const aboutRoot = getAboutRoot(lang);
  const loginRoot = getLoginRoot(lang);

  const prefix = isPro ? `${lang}/${corporateRoot}` : lang;

  switch (type) {
    case 'home_page':
      return `/${prefix}`;
    case 'page_contact':
      return `/${prefix}/${contactRoot}`;
    case 'page':
      return getPageLink({
        uid,
        prefix,
        pageType,
        parentPage,
        supportRoot,
        servicesRoot,
        legalRoot,
        aboutRoot,
      });
    case 'page_user_login':
      return `/${prefix}/${loginRoot}`;
    case 'page_destinations':
      return `/${prefix}/${destinationsRoot}`;
    case 'page_region':
      return `/${prefix}/${destinationsRoot}/${uid}`;
    case 'page_country':
      return `/${prefix}/${destinationsRoot}/${region}/${uid}`;
    case 'page_guide':
      return offer
        ? `/${prefix}/${offer}/${guideRoot}/${uid}`
        : `/${prefix}/${insuranceRoot}/${guideRoot}/${uid}`;
    case 'page_guides':
      return offer
        ? `/${prefix}/${offer}/${guideRoot}`
        : `/${prefix}/${insuranceRoot}/${guideRoot}`;
    case 'page_all_guides':
      return `/${prefix}/${allGuidesRoot}`;
    case 'page_guides_themes':
      return `/${prefix}/${allGuidesThemesRoot}`;
    case 'page_guides_taxonomies_list':
      return `/${prefix}/${taxonomyRoot}/${uid}`;
    case 'page_offers':
      return `/${prefix}/${offersRoot}`;
    case 'page_offer':
      return `/${prefix}/${uid}`;
    case 'product_page':
      return `/${prefix}/${offer}/${uid}`;
    case 'page_form':
      return getFormLink({
        uid,
        lang,
        prefix,
        pageType,
        offer,
        product,
        landingRoot,
        contactRoot,
      });
    case 'page_article_home':
      return `/${prefix}/${aboutRoot}/${newsRoot}`;
    case 'page_article':
      return `/${prefix}/${aboutRoot}/${newsRoot}/${uid}`;
    case 'page_faq_home':
      return `/${prefix}/${supportRoot}/${faqRoot}`;
    case 'page_faq':
      return `/${prefix}/${supportRoot}/${faqRoot}/${uid}`;
    default:
      return notFound;
  }
}
