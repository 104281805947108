
import lisaAdapter from '@/slices/LisaContent/adapter';
import translations from '@/mixins/componentTranslations.js';
import lisa from '@/mixins/lisa';

export default {
  name: 'LisaPanel',
  mixins: [translations, lisa],
  props: {
    uid: {
      type: String,
      default: 'part-lisa-common',
    },
  },
  computed: {
    locale() {
      return this.$i18n.localeProperties.iso;
    },
  },
  watch: {
    locale(value) {
      this.setLisaPanel();
      this.lang = value;
    },
  },
  async mounted() {
    await this.setLisaData();
    this.setLisaPanel();
  },
  methods: {
    async setLisaPanel() {
      await this.fetchTranslations('lisa');

      const data = await lisaAdapter(
        {},
        {
          prismic: this.$prismic,
          i18n: this.$i18n,
          lisaData: this.lisaData,
          uid: this.uid,
        }
      );
      this.sliceData = data;
    },
  },
};
