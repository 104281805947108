import { LAYOUT_LISA_AUTOFILL } from '@/store/mutation-types';

export default function setLisaAutoFill(data, store, i18n) {
  const coverType = data.cover_type ? data.cover_type.id : null;
  const language = i18n.localeProperties.code;

  const countryCode =
    data.country_code?.length >= 2 ? data.country_code.substring(0, 2) : null;

  const destination = countryCode;

  const lisaFields = { coverType, language, destination };

  store.commit(`layout/${LAYOUT_LISA_AUTOFILL}`, lisaFields);
}
