const languages = {
  English: {
    code: 'en',
    locales: {
      en: 'English',
      fr: 'Anglais',
      es: 'Inglés',
      de: 'Englisch',
      nl: 'Engels',
    },
  },
  French: {
    code: 'fr',
    locales: {
      en: 'French',
      fr: 'Français',
      es: 'Francés',
      de: 'Französisch',
      nl: 'Frans',
    },
  },
  German: {
    code: 'de',
    locales: {
      en: 'German',
      fr: 'Allemand',
      es: 'Alemán',
      de: 'Deutsch',
      nl: 'Deutsch',
    },
  },
  Spanish: {
    code: 'es',
    locales: {
      en: 'Spanish',
      fr: 'Espagnol',
      es: 'Español',
      de: 'Spanisch',
      nl: 'Spaans',
    },
  },
};

export const getLanguageOptions = (lang) => {
  return Object.keys(languages).map((key) => {
    return {
      code: languages[key].code ?? null,
      label: languages[key].locales[lang],
      value: key,
    };
  });
};
