import {
  productLinks,
  contactLinks,
  routerLinks,
  regionLinks,
  countryLinks,
  offerLinks,
  cardLinks,
} from '@/utils/fetch-links';

const getModules = async ($prismic, lang, requestIds) => {
  const modules = {};

  if (requestIds.length) {
    const entriesContent = await $prismic.api.getByIDs(requestIds, {
      pageSize: 30,
      fetchLinks: [
        ...routerLinks,
        ...productLinks,
        ...contactLinks,
        ...regionLinks,
        ...countryLinks,
        ...offerLinks,
        ...cardLinks,
      ],
      lang,
    });

    entriesContent.results.forEach((entry) => {
      modules[entry.id] = entry;
    });
  }

  return modules;
};

export default async ($prismic, lang, { isPro }) => {
  const request = await $prismic.api.query(
    [$prismic.predicates.at('my.module_header.is_pro', isPro)],
    {
      fetchLinks: [...routerLinks],
      lang,
    }
  );
  const header = request.results[0];

  if (!header) return;

  const data = header.data;
  const entries = data.slices.length && data.slices;
  const requestIds = [];

  if (!entries) return;

  entries.forEach(({ slice_type: sliceType, primary = {}, items = [] }) => {
    if (sliceType === 'nav_item') {
      const id = primary?.navigation_module?.id;
      if (id) {
        requestIds.push(id);
      }
    } else if (sliceType === 'nav_tabs') {
      items.forEach((item) => {
        const id = item?.navigation_module?.id;
        if (id) {
          requestIds.push(id);
        }
      });
    }
  });

  const modules = await getModules($prismic, lang, requestIds);

  return { data, modules };
};
