export const getFlagEmoji = (countryCode) => {
  // Reference: https://dev.to/jorik/country-code-to-flag-emoji-a21
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

export const countries = {
  AF: {
    locales: {
      en: 'Afghanistan',
      fr: 'Afghanistan',
      es: 'Afganistán',
      de: 'Afghanistan',
      nl: 'Afghanistan',
    },
    alpha3: 'AFG',
    countryConl: '4',
    name: 'AF - Afghanistan',
    dialCode: '+93',
  },
  AX: {
    locales: {
      en: 'Åland Islands',
      fr: 'Åland, Iles',
      es: 'Islas Åland',
      de: 'Åland Inseln',
      nl: 'Åland, Islands',
    },
    alpha3: 'ALA',
    countryConl: '248',
    name: 'AX - Åland Islands',
    dialCode: '+358',
  },
  AL: {
    locales: {
      en: 'Albania',
      fr: 'Albanie',
      es: 'Albania',
      de: 'Albanien',
      nl: 'Albania',
    },
    alpha3: 'ALB',
    countryConl: '8',
    name: 'AL - Albania',
    dialCode: '+355',
  },
  DZ: {
    locales: {
      en: 'Algeria',
      fr: 'Algérie',
      es: 'Argelia',
      de: 'Algerien',
      nl: 'Algeria',
    },
    alpha3: 'DZA',
    countryConl: '12',
    name: 'DZ - Algeria',
    dialCode: '+213',
  },
  AS: {
    locales: {
      en: 'American Samoa',
      fr: 'Samoa américaines',
      es: 'Samoa Americana',
      de: 'Amerikanisch-Samoa',
      nl: 'American Samoa',
    },
    alpha3: 'ASM',
    countryConl: '16',
    name: 'AS - American Samoa',
    dialCode: '+1684',
  },
  AD: {
    locales: {
      en: 'Andorra',
      fr: 'Andorre',
      es: 'Andorra',
      de: 'Andorra',
      nl: 'Andorra',
    },
    alpha3: 'AND',
    countryConl: '20',
    name: 'AD - Andorra',
    dialCode: '+376',
  },
  AO: {
    locales: {
      en: 'Angola',
      fr: 'Angola',
      es: 'Angola',
      de: 'Angola',
      nl: 'Angola',
    },
    alpha3: 'AGO',
    countryConl: '24',
    name: 'AO - Angola',
    dialCode: '+244',
  },
  AI: {
    locales: {
      en: 'Anguilla',
      fr: 'Anguilla',
      es: 'Anguila',
      de: 'Anguilla',
      nl: 'Anguilla',
    },
    alpha3: 'AIA',
    countryConl: '660',
    name: 'AI - Anguilla',
    dialCode: '+1264',
  },
  AQ: {
    locales: {
      en: 'Antarctica',
      fr: 'Antarctique',
      es: 'Antártida',
      de: 'Antarktis',
      nl: 'Antarctica',
    },
    alpha3: 'ATA',
    countryConl: '10',
    name: 'AQ - Antarctica',
    dialCode: '+672',
  },
  AG: {
    locales: {
      en: 'Antigua and Barbuda',
      fr: 'Antigua-et-Barbuda',
      es: 'Antigua y Barbuda',
      de: 'Antigua und Barbuda',
      nl: 'Antigua and Barbuda',
    },
    alpha3: 'ATG',
    countryConl: '28',
    name: 'AG - Antigua and Barbuda',
    dialCode: '+1268',
  },
  AR: {
    locales: {
      en: 'Argentina',
      fr: 'Argentine',
      es: 'Argentina',
      de: 'Argentinien',
      nl: 'Argentina',
    },
    alpha3: 'ARG',
    countryConl: '32',
    name: 'AR - Argentina',
    dialCode: '+54',
  },
  AM: {
    locales: {
      en: 'Armenia',
      fr: 'Arménie',
      es: 'Armenia',
      de: 'Armenien',
      nl: 'Armenia',
    },
    alpha3: 'ARM',
    countryConl: '51',
    name: 'AM - Armenia',
    dialCode: '+374',
  },
  AW: {
    locales: {
      en: 'Aruba',
      fr: 'Aruba',
      es: 'Aruba',
      de: 'Aruba',
      nl: 'Aruba',
    },
    alpha3: 'ABW',
    countryConl: '533',
    name: 'AW - Aruba',
    dialCode: '+297',
  },
  AU: {
    locales: {
      en: 'Australia',
      fr: 'Australie',
      es: 'Australia',
      de: 'Australien',
      nl: 'Australia',
    },
    alpha3: 'AUS',
    countryConl: '36',
    name: 'AU - Australia',
    dialCode: '+61',
  },
  AT: {
    locales: {
      en: 'Austria',
      fr: 'Autriche',
      es: 'Austria',
      de: 'Österreich',
      nl: 'Austria',
    },
    alpha3: 'AUT',
    countryConl: '40',
    name: 'AT - Austria',
    dialCode: '+43',
  },
  AZ: {
    locales: {
      en: 'Azerbaijan',
      fr: 'Azerbaïdjan',
      es: 'Azerbaiyán',
      de: 'Aserbaidschan',
      nl: 'Azerbaijan',
    },
    alpha3: 'AZE',
    countryConl: '31',
    name: 'AZ - Azerbaijan',
    dialCode: '+994',
  },
  BS: {
    locales: {
      en: 'Bahamas',
      fr: 'Bahamas',
      es: 'Bahamas',
      de: 'Bahamas',
      nl: 'Bahamas',
    },
    alpha3: 'BHS',
    countryConl: '44',
    name: 'BS - Bahamas',
    dialCode: '+1242',
  },
  BH: {
    locales: {
      en: 'Bahrain',
      fr: 'Bahreïn',
      es: 'Bahrein',
      de: 'Bahrain',
      nl: 'Bahrain',
    },
    alpha3: 'BHR',
    countryConl: '48',
    name: 'BH - Bahrain',
    dialCode: '+973',
  },
  BD: {
    locales: {
      en: 'Bangladesh',
      fr: 'Bangladesh',
      es: 'Bangladesh',
      de: 'Bangladesch',
      nl: 'Bangladesh',
    },
    alpha3: 'BGD',
    countryConl: '50',
    name: 'BD - Bangladesh',
    dialCode: '+880',
  },
  BB: {
    locales: {
      en: 'Barbados',
      fr: 'Barbade',
      es: 'Barbados',
      de: 'Barbados',
      nl: 'Barbados',
    },
    alpha3: 'BRB',
    countryConl: '52',
    name: 'BB - Barbados',
    dialCode: '+1246',
  },
  BY: {
    locales: {
      en: 'Belarus',
      fr: 'Biélorussie',
      es: 'Bielorrusia',
      de: 'Belarus',
      nl: 'Belarus',
    },
    alpha3: 'BLR',
    countryConl: '112',
    name: 'BY - Belarus',
    dialCode: '+375',
  },
  BE: {
    locales: {
      en: 'Belgium',
      fr: 'Belgique',
      es: 'Bélgica',
      de: 'Belgien',
      nl: 'Belgium',
    },
    alpha3: 'BEL',
    countryConl: '56',
    name: 'BE - Belgium',
    dialCode: '+32',
  },
  BZ: {
    locales: {
      en: 'Belize',
      fr: 'Belize',
      es: 'Belice',
      de: 'Belize',
      nl: 'Belize',
    },
    alpha3: 'BLZ',
    countryConl: '84',
    name: 'BZ - Belize',
    dialCode: '+501',
  },
  BJ: {
    locales: {
      en: 'Benin',
      fr: 'Bénin',
      es: 'Benín',
      de: 'Benin',
      nl: 'Benin',
    },
    alpha3: 'BEN',
    countryConl: '204',
    name: 'BJ - Benin',
    dialCode: '+229',
  },
  BM: {
    locales: {
      en: 'Bermuda',
      fr: 'Bermudes',
      es: 'Bermudas',
      de: 'Bermuda',
      nl: 'Bermuda',
    },
    alpha3: 'BMU',
    countryConl: '60',
    name: 'BM - Bermuda',
    dialCode: '+1441',
  },
  BT: {
    locales: {
      en: 'Bhutan',
      fr: 'Bhoutan',
      es: 'Bután',
      de: 'Bhutan',
      nl: 'Bhutan',
    },
    alpha3: 'BTN',
    countryConl: '64',
    name: 'BT - Bhutan',
    dialCode: '+975',
  },
  BO: {
    locales: {
      en: 'Bolivia (Plurinational State of)',
      fr: 'Bolivie',
      es: 'Bolivia',
      de: 'Bolivien',
      nl: 'Bolivia',
    },
    alpha3: 'BOL',
    countryConl: '68',
    name: 'BO - Bolivia (Plurinational State of)',
    dialCode: '+591',
  },
  BQ: {
    locales: {
      en: 'Bonaire, Sint Eustatius and Saba',
      fr: 'Bonaire, Saint-Eustache et Saba',
      es: 'Bonaire, San Eustaquio y Saba',
      de: 'Bonaire, St. Eustatius und Saba',
      nl: 'Bonaire, St Eustatius and Saba',
    },
    alpha3: 'BES',
    countryConl: '535',
    name: 'BQ - Bonaire, Sint Eustatius and Saba',
  },
  BA: {
    locales: {
      en: 'Bosnia and Herzegovina',
      fr: 'Bosnie-Herzégovine',
      es: 'Bosnia y Herzegovina',
      de: 'Bosnien und Herzegowina',
      nl: 'Bosnia and Herzegovina',
    },
    alpha3: 'BIH',
    countryConl: '70',
    name: 'BA - Bosnia and Herzegovina',
    dialCode: '+387',
  },
  BW: {
    locales: {
      en: 'Botswana',
      fr: 'Botswana',
      es: 'Botswana',
      de: 'Botsuana',
      nl: 'Botswana',
    },
    alpha3: 'BWA',
    countryConl: '72',
    name: 'BW - Botswana',
    dialCode: '+267',
  },
  BV: {
    locales: {
      en: 'Bouvet Island',
      fr: 'Bouvet, Ile',
      es: 'Isla de Bouvet',
      de: 'Bouvet Insel',
      nl: 'Bouvet Island',
    },
    alpha3: 'BVT',
    countryConl: '74',
    name: 'BV - Bouvet Island',
    dialCode: '+47',
  },
  BR: {
    locales: {
      en: 'Brazil',
      fr: 'Brésil',
      es: 'Brasil',
      de: 'Brasilien',
      nl: 'Brazil',
    },
    alpha3: 'BRA',
    countryConl: '76',
    name: 'BR - Brazil',
    dialCode: '+55',
  },
  IO: {
    locales: {
      en: 'British Indian Ocean Territory',
      fr: "Océan Indien, Territoire britannique de l'",
      es: 'Océano Índico, Territorio Británico del',
      de: 'Britisches Territorium im Indischen Ozean',
      nl: 'Indian Ocean, British Territory of the',
    },
    alpha3: 'IOT',
    countryConl: '86',
    name: 'IO - British Indian Ocean Territory',
    dialCode: '+246',
  },
  BN: {
    locales: {
      en: 'Brunei Darussalam',
      fr: 'Brunéi Darussalam',
      es: 'Brunei Darussalam',
      de: 'Brunei Darussalam',
      nl: 'Brunei Darussalam',
    },
    alpha3: 'BRN',
    countryConl: '96',
    name: 'BN - Brunei Darussalam',
    dialCode: '+673',
  },
  BG: {
    locales: {
      en: 'Bulgaria',
      fr: 'Bulgarie',
      es: 'Bulgaria',
      de: 'Bulgarien',
      nl: 'Bulgaria',
    },
    alpha3: 'BGR',
    countryConl: '100',
    name: 'BG - Bulgaria',
    dialCode: '+359',
  },
  BF: {
    locales: {
      en: 'Burkina Faso',
      fr: 'Burkina Faso',
      es: 'Burkina Faso',
      de: 'Burkina Faso',
      nl: 'Burkina Faso',
    },
    alpha3: 'BFA',
    countryConl: '854',
    name: 'BF - Burkina Faso',
    dialCode: '+226',
  },
  BI: {
    locales: {
      en: 'Burundi',
      fr: 'Burundi',
      es: 'Burundi',
      de: 'Burundi',
      nl: 'Burundi',
    },
    alpha3: 'BDI',
    countryConl: '108',
    name: 'BI - Burundi',
    dialCode: '+257',
  },
  CV: {
    locales: {
      en: 'Cabo Verde',
      fr: 'Cap-Vert',
      es: 'Cabo Verde',
      de: 'Cabo Verde',
      nl: 'Cabo Verde',
    },
    alpha3: 'CPV',
    countryConl: '132',
    name: 'CV - Cabo Verde',
    dialCode: '+238',
  },
  KH: {
    locales: {
      en: 'Cambodia',
      fr: 'Cambodge',
      es: 'Camboya',
      de: 'Kambodscha',
      nl: 'Cambodia',
    },
    alpha3: 'KHM',
    countryConl: '116',
    name: 'KH - Cambodia',
    dialCode: '+855',
  },
  CM: {
    locales: {
      en: 'Cameroon',
      fr: 'Cameroune',
      es: 'Camerún',
      de: 'Kamerun',
      nl: 'Cameroon',
    },
    alpha3: 'CMR',
    countryConl: '120',
    name: 'CM - Cameroon',
    dialCode: '+237',
  },
  CA: {
    locales: {
      en: 'Canada',
      fr: 'Canada',
      es: 'Canadá',
      de: 'Kanada',
      nl: 'Canada',
    },
    alpha3: 'CAN',
    countryConl: '124',
    name: 'CA - Canada',
    dialCode: '+1',
  },
  KY: {
    locales: {
      en: 'Cayman Islands',
      fr: 'Caïman, Iles',
      es: 'Islas Caimán',
      de: 'Kaimaninseln',
      nl: 'Cayman Islands',
    },
    alpha3: 'CYM',
    countryConl: '136',
    name: 'KY - Cayman Islands',
    dialCode: '+345',
  },
  CF: {
    locales: {
      en: 'Central African Republic',
      fr: 'République centrafricaine',
      es: 'República Centroafricana',
      de: 'Zentralafrikanische Republik',
      nl: 'Central African Republic',
    },
    alpha3: 'CAF',
    countryConl: '140',
    name: 'CF - Central African Republic',
    dialCode: '+236',
  },
  TD: {
    locales: {
      en: 'Chad',
      fr: 'Tchad',
      es: 'Chad',
      de: 'Tschad',
      nl: 'Chad',
    },
    alpha3: 'TCD',
    countryConl: '148',
    name: 'TD - Chad',
    dialCode: '+235',
  },
  CL: {
    locales: {
      en: 'Chile',
      fr: 'Chili',
      es: 'Chile',
      de: 'Chile',
      nl: 'Chile',
    },
    alpha3: 'CHL',
    countryConl: '152',
    name: 'CL - Chile',
    dialCode: '+56',
  },
  CN: {
    locales: {
      en: 'China',
      fr: 'Chine',
      es: 'China',
      de: 'China',
      nl: 'China',
    },
    alpha3: 'CHN',
    countryConl: '156',
    name: 'CN - China',
    dialCode: '+86',
  },
  CX: {
    locales: {
      en: 'Christmas Island',
      fr: 'Christmas, Ile',
      es: 'Isla de Navidad',
      de: 'Christmas Insel',
      nl: 'Christmas Island',
    },
    alpha3: 'CXR',
    countryConl: '162',
    name: 'CX - Christmas Island',
    dialCode: '+61',
  },
  CC: {
    locales: {
      en: 'Cocos (Keeling) Islands',
      fr: 'Cocos (Keeling), Iles',
      es: 'Islas Cocos (Keeling)',
      de: 'Cocos (Keeling) Inseln',
      nl: 'Cocos (Keeling) Islands',
    },
    alpha3: 'CCK',
    countryConl: '166',
    name: 'CC - Cocos (Keeling) Islands',
    dialCode: '+61',
  },
  CO: {
    locales: {
      en: 'Colombia',
      fr: 'Colombie',
      es: 'Colombia',
      de: 'Kolumbien',
      nl: 'Colombia',
    },
    alpha3: 'COL',
    countryConl: '170',
    name: 'CO - Colombia',
    dialCode: '+57',
  },
  KM: {
    locales: {
      en: 'Comoros',
      fr: 'Comores',
      es: 'Comoras',
      de: 'Komoren',
      nl: 'Comoros',
    },
    alpha3: 'COM',
    countryConl: '174',
    name: 'KM - Comoros',
    dialCode: '+269',
  },
  CG: {
    locales: {
      en: 'Congo',
      fr: 'Congo',
      es: 'Congo',
      de: 'Kongo',
      nl: 'Congo',
    },
    alpha3: 'COG',
    countryConl: '178',
    name: 'CG - Congo',
    dialCode: '+242',
  },
  CD: {
    locales: {
      en: 'Congo, Democratic Republic of the',
      fr: 'République démocratique du Congo',
      es: 'República Democrática del Congo',
      de: 'Demokratische Republik Kongo',
      nl: 'Democratic Republic of the Congo',
    },
    alpha3: 'COD',
    countryConl: '180',
    name: 'CD - Congo, Democratic Republic of the',
    dialCode: '+243',
  },
  CK: {
    locales: {
      en: 'Cook Islands',
      fr: 'Cook, Iles',
      es: 'Islas Cook',
      de: 'Cook-Inseln',
      nl: 'Cook Islands',
    },
    alpha3: 'COK',
    countryConl: '184',
    name: 'CK - Cook Islands',
    dialCode: '+682',
  },
  CR: {
    locales: {
      en: 'Costa Rica',
      fr: 'Costa Rica',
      es: 'Costa Rica',
      de: 'Costa Rica',
      nl: 'Costa Rica',
    },
    alpha3: 'CRI',
    countryConl: '188',
    name: 'CR - Costa Rica',
    dialCode: '+506',
  },
  CI: {
    locales: {
      en: "Côte d'Ivoire",
      fr: "Cote d'Ivoire",
      es: 'Costa de Marfil',
      de: 'Elfenbeinküste',
      nl: 'Ivory Coast',
    },
    alpha3: 'CIV',
    countryConl: '384',
    name: "CI - Côte d'Ivoire",
    dialCode: '+225',
  },
  HR: {
    locales: {
      en: 'Croatia',
      fr: 'Coatie',
      es: 'Croacia',
      de: 'Kroatien',
      nl: 'Croatia',
    },
    alpha3: 'HRV',
    countryConl: '191',
    name: 'HR - Croatia',
    dialCode: '+385',
  },
  CU: {
    locales: {
      en: 'Cuba',
      fr: 'Cuba',
      es: 'Cuba',
      de: 'Kuba',
      nl: 'Cuba',
    },
    alpha3: 'CUB',
    countryConl: '192',
    name: 'CU - Cuba',
    dialCode: '+53',
  },
  CW: {
    locales: {
      en: 'Curaçao',
      fr: 'Curaçao',
      es: 'Curaçao',
      de: 'Curaçao',
      nl: 'Curaçao',
    },
    alpha3: 'CUW',
    countryConl: '531',
    name: 'CW - Curaçao',
  },
  CY: {
    locales: {
      en: 'Cyprus',
      fr: 'Chypre',
      es: 'Chipre',
      de: 'Zypern',
      nl: 'Cyprus',
    },
    alpha3: 'CYP',
    countryConl: '196',
    name: 'CY - Cyprus',
    dialCode: '+357',
  },
  CZ: {
    locales: {
      en: 'Czechia',
      fr: 'République tchèque',
      es: 'República Checa',
      de: 'Tschechische Republik',
      nl: 'Czechia',
    },
    alpha3: 'CZE',
    countryConl: '203',
    name: 'CZ - Czechia',
    dialCode: '+420',
  },
  DK: {
    locales: {
      en: 'Denmark',
      fr: 'Danemark',
      es: 'Dinamarca',
      de: 'Dänemark',
      nl: 'Denmark',
    },
    alpha3: 'DNK',
    countryConl: '208',
    name: 'DK - Denmark',
    dialCode: '+45',
  },
  DJ: {
    locales: {
      en: 'Djibouti',
      fr: 'Djibouti',
      es: 'Yibuti',
      de: 'Dschibuti',
      nl: 'Djibouti',
    },
    alpha3: 'DJI',
    countryConl: '262',
    name: 'DJ - Djibouti',
    dialCode: '+253',
  },
  DM: {
    locales: {
      en: 'Dominica',
      fr: 'Dominique',
      es: 'Dominica',
      de: 'Dominica',
      nl: 'Dominica',
    },
    alpha3: 'DMA',
    countryConl: '212',
    name: 'DM - Dominica',
    dialCode: '+1767',
  },
  DO: {
    locales: {
      en: 'Dominican Republic',
      fr: 'République dominicaine',
      es: 'República Dominicana',
      de: 'Dominikanische Republik',
      nl: 'Dominican Republic',
    },
    alpha3: 'DOM',
    countryConl: '214',
    name: 'DO - Dominican Republic',
    dialCode: '+1849',
  },
  EC: {
    locales: {
      en: 'Ecuador',
      fr: 'Équateur',
      es: 'Ecuador',
      de: 'Ecuador',
      nl: 'Ecuador',
    },
    alpha3: 'ECU',
    countryConl: '218',
    name: 'EC - Ecuador',
    dialCode: '+593',
  },
  EG: {
    locales: {
      en: 'Egypt',
      fr: 'Égypte',
      es: 'Egipto',
      de: 'Ägypten',
      nl: 'Egypt',
    },
    alpha3: 'EGY',
    countryConl: '818',
    name: 'EG - Egypt',
    dialCode: '+20',
  },
  SV: {
    locales: {
      en: 'El Salvador',
      fr: 'Salvador',
      es: 'El Salvador',
      de: 'El Salvador',
      nl: 'El Salvador',
    },
    alpha3: 'SLV',
    countryConl: '222',
    name: 'SV - El Salvador',
    dialCode: '+503',
  },
  GQ: {
    locales: {
      en: 'Equatorial Guinea',
      fr: 'Guinée Équatoriale',
      es: 'Guinea Ecuatorial',
      de: 'Äquatorialguinea',
      nl: 'Equatorial Guinea',
    },
    alpha3: 'GNQ',
    countryConl: '226',
    name: 'GQ - Equatorial Guinea',
    dialCode: '+240',
  },
  ER: {
    locales: {
      en: 'Eritrea',
      fr: 'Érythrée',
      es: 'Eritrea',
      de: 'Eritrea',
      nl: 'Eritrea',
    },
    alpha3: 'ERI',
    countryConl: '232',
    name: 'ER - Eritrea',
    dialCode: '+291',
  },
  EE: {
    locales: {
      en: 'Estonia',
      fr: 'Estonie',
      es: 'Estonia',
      de: 'Estland',
      nl: 'Estonia',
    },
    alpha3: 'EST',
    countryConl: '233',
    name: 'EE - Estonia',
    dialCode: '+372',
  },
  SZ: {
    locales: {
      en: 'Eswatini',
      fr: 'Swaziland',
      es: 'Eswatini',
      de: 'Eswatini',
      nl: 'Eswatini',
    },
    alpha3: 'SWZ',
    countryConl: '748',
    name: 'SZ - Eswatini',
    dialCode: '+268',
  },
  ET: {
    locales: {
      en: 'Ethiopia',
      fr: 'Éthiopie',
      es: 'Etiopía',
      de: 'Äthiopien',
      nl: 'Ethiopia',
    },
    alpha3: 'ETH',
    countryConl: '231',
    name: 'ET - Ethiopia',
    dialCode: '+251',
  },
  FK: {
    locales: {
      en: 'Falkland Islands (Malvinas)',
      fr: 'Falkland, Iles (Malvinas)',
      es: 'Islas Malvinas (Falkland Islands)',
      de: 'Falkland-Inseln (Malvinas)',
      nl: 'Falkland Islands (Malvinas)',
    },
    alpha3: 'FLK',
    countryConl: '238',
    name: 'FK - Falkland Islands (Malvinas)',
    dialCode: '+500',
  },
  FO: {
    locales: {
      en: 'Faroe Islands',
      fr: 'Féroé, Iles',
      es: 'Islas Feroe',
      de: 'Färöer Inseln',
      nl: 'Faroe Islands',
    },
    alpha3: 'FRO',
    countryConl: '234',
    name: 'FO - Faroe Islands',
    dialCode: '+298',
  },
  FJ: {
    locales: {
      en: 'Fiji',
      fr: 'Fidji',
      es: 'Fiji',
      de: 'Fidschi',
      nl: 'Fiji',
    },
    alpha3: 'FJI',
    countryConl: '242',
    name: 'FJ - Fiji',
    dialCode: '+679',
  },
  FI: {
    locales: {
      en: 'Finland',
      fr: 'Finlande',
      es: 'Finlandia',
      de: 'Finnland',
      nl: 'Finland',
    },
    alpha3: 'FIN',
    countryConl: '246',
    name: 'FI - Finland',
    dialCode: '+358',
  },
  FR: {
    locales: {
      en: 'France',
      fr: 'France',
      es: 'Francia',
      de: 'Frankreich',
      nl: 'France',
    },
    alpha3: 'FRA',
    countryConl: '250',
    name: 'FR - France',
    dialCode: '+33',
  },
  GF: {
    locales: {
      en: 'French Guiana',
      fr: 'Guyane française',
      es: 'Guayana Francesa',
      de: 'Französisch-Guayana',
      nl: 'French Guiana',
    },
    alpha3: 'GUF',
    countryConl: '254',
    name: 'GF - French Guiana',
    dialCode: '+594',
  },
  PF: {
    locales: {
      en: 'French Polynesia',
      fr: 'Polynésie française',
      es: 'Polinesia Francesa',
      de: 'Französisch-Polynesien',
      nl: 'French Polynesia',
    },
    alpha3: 'PYF',
    countryConl: '258',
    name: 'PF - French Polynesia',
    dialCode: '+689',
  },
  TF: {
    locales: {
      en: 'French Southern Territories',
      fr: 'Terres australes françaises',
      es: 'Territorios Australes Franceses',
      de: 'Französische Südgebiete',
      nl: 'French Southern Territories',
    },
    alpha3: 'ATF',
    countryConl: '260',
    name: 'TF - French Southern Territories',
    dialCode: '+262',
  },
  GA: {
    locales: {
      en: 'Gabon',
      fr: 'Gabon',
      es: 'Gabón',
      de: 'Gabun',
      nl: 'Gabon',
    },
    alpha3: 'GAB',
    countryConl: '266',
    name: 'GA - Gabon',
    dialCode: '+241',
  },
  GM: {
    locales: {
      en: 'Gambia',
      fr: 'Gambie',
      es: 'Gambia',
      de: 'Gambia',
      nl: 'Gambia',
    },
    alpha3: 'GMB',
    countryConl: '270',
    name: 'GM - Gambia',
    dialCode: '+220',
  },
  GE: {
    locales: {
      en: 'Georgia',
      fr: 'Géorgie',
      es: 'Georgia',
      de: 'Georgien',
      nl: 'Georgia',
    },
    alpha3: 'GEO',
    countryConl: '268',
    name: 'GE - Georgia',
    dialCode: '+995',
  },
  DE: {
    locales: {
      en: 'Germany',
      fr: 'Allemagne',
      es: 'Alemania',
      de: 'Deutschland',
      nl: 'Germany',
    },
    alpha3: 'DEU',
    countryConl: '276',
    name: 'DE - Germany',
    dialCode: '+49',
  },
  GH: {
    locales: {
      en: 'Ghana',
      fr: 'Ghana',
      es: 'Ghana',
      de: 'Ghana',
      nl: 'Ghana',
    },
    alpha3: 'GHA',
    countryConl: '288',
    name: 'GH - Ghana',
    dialCode: '+233',
  },
  GI: {
    locales: {
      en: 'Gibraltar',
      fr: 'Gibraltar',
      es: 'Gibraltar',
      de: 'Gibraltar',
      nl: 'Gibraltar',
    },
    alpha3: 'GIB',
    countryConl: '292',
    name: 'GI - Gibraltar',
    dialCode: '+350',
  },
  GR: {
    locales: {
      en: 'Greece',
      fr: 'Grèce',
      es: 'Grecia',
      de: 'Griechenland',
      nl: 'Greece',
    },
    alpha3: 'GRC',
    countryConl: '300',
    name: 'GR - Greece',
    dialCode: '+30',
  },
  GL: {
    locales: {
      en: 'Greenland',
      fr: 'Groenland',
      es: 'Groenlandia',
      de: 'Grönland',
      nl: 'Greenland',
    },
    alpha3: 'GRL',
    countryConl: '304',
    name: 'GL - Greenland',
    dialCode: '+299',
  },
  GD: {
    locales: {
      en: 'Grenada',
      fr: 'Grenade',
      es: 'Granada',
      de: 'Grenada',
      nl: 'Grenada',
    },
    alpha3: 'GRD',
    countryConl: '308',
    name: 'GD - Grenada',
    dialCode: '+1473',
  },
  GP: {
    locales: {
      en: 'Guadeloupe',
      fr: 'Guadeloupe',
      es: 'Guadalupe',
      de: 'Guadeloupe',
      nl: 'Guadeloupe',
    },
    alpha3: 'GLP',
    countryConl: '312',
    name: 'GP - Guadeloupe',
    dialCode: '+590',
  },
  GU: {
    locales: {
      en: 'Guam',
      fr: 'Guam',
      es: 'Guam',
      de: 'Guam',
      nl: 'Guam',
    },
    alpha3: 'GUM',
    countryConl: '316',
    name: 'GU - Guam',
    dialCode: '+1671',
  },
  GT: {
    locales: {
      en: 'Guatemala',
      fr: 'Guatemala',
      es: 'Guatemala',
      de: 'Guatemala',
      nl: 'Guatemala',
    },
    alpha3: 'GTM',
    countryConl: '320',
    name: 'GT - Guatemala',
    dialCode: '+502',
  },
  GG: {
    locales: {
      en: 'Guernsey',
      fr: 'Guernesey',
      es: 'Guernsey',
      de: 'Guernsey',
      nl: 'Guernsey',
    },
    alpha3: 'GGY',
    countryConl: '831',
    name: 'GG - Guernsey',
    dialCode: '+44',
  },
  GN: {
    locales: {
      en: 'Guinea',
      fr: 'Guinée',
      es: 'Guinea',
      de: 'Guinea',
      nl: 'Guinea',
    },
    alpha3: 'GIN',
    countryConl: '324',
    name: 'GN - Guinea',
    dialCode: '+224',
  },
  GW: {
    locales: {
      en: 'Guinea-Bissau',
      fr: 'Guinée-Bissau',
      es: 'Guinea-Bissau',
      de: 'Guinea-Bissau',
      nl: 'Guinea-Bissau',
    },
    alpha3: 'GNB',
    countryConl: '624',
    name: 'GW - Guinea-Bissau',
    dialCode: '+245',
  },
  GY: {
    locales: {
      en: 'Guyana',
      fr: 'Guyana',
      es: 'Guyana',
      de: 'Guyana',
      nl: 'Guyana',
    },
    alpha3: 'GUY',
    countryConl: '328',
    name: 'GY - Guyana',
    dialCode: '+592',
  },
  HT: {
    locales: {
      en: 'Haiti',
      fr: 'Haïti',
      es: 'Haití',
      de: 'Haiti',
      nl: 'Haiti',
    },
    alpha3: 'HTI',
    countryConl: '332',
    name: 'HT - Haiti',
    dialCode: '+509',
  },
  HM: {
    locales: {
      en: 'Heard Island and McDonald Islands',
      fr: 'Heard et McDonald, Iles',
      es: 'Isla Heard e Islas McDonald',
      de: 'Heard Insel und McDonald Inseln',
      nl: 'Heard Island and McDonald Islands',
    },
    alpha3: 'HMD',
    countryConl: '334',
    name: 'HM - Heard Island and McDonald Islands',
    dialCode: '+672',
  },
  VA: {
    locales: {
      en: 'Holy See',
      fr: 'Vatican, État de la Cité du',
      es: 'Santa Sede (Estado de la Ciudad del Vaticano)',
      de: '(Heiliger Stuhl) Vatikanstaat',
      nl: 'Vatican City State',
    },
    alpha3: 'VAT',
    countryConl: '336',
    name: 'VA - Holy See',
    dialCode: '+379',
  },
  HN: {
    locales: {
      en: 'Honduras',
      fr: 'Honduras',
      es: 'Honduras',
      de: 'Honduras',
      nl: 'Honduras',
    },
    alpha3: 'HND',
    countryConl: '340',
    name: 'HN - Honduras',
    dialCode: '+504',
  },
  HK: {
    locales: {
      en: 'Hong Kong',
      fr: 'Hong-Kong',
      es: 'Hong Kong',
      de: 'Hongkong',
      nl: 'Hong Kong',
    },
    alpha3: 'HKG',
    countryConl: '344',
    name: 'HK - Hong Kong',
    dialCode: '+852',
  },
  HU: {
    locales: {
      en: 'Hungary',
      fr: 'Hongrie',
      es: 'Hungría',
      de: 'Ungarn',
      nl: 'Hungary',
    },
    alpha3: 'HUN',
    countryConl: '348',
    name: 'HU - Hungary',
    dialCode: '+36',
  },
  IS: {
    locales: {
      en: 'Iceland',
      fr: 'Islande',
      es: 'Islandia',
      de: 'Island',
      nl: 'Iceland',
    },
    alpha3: 'ISL',
    countryConl: '352',
    name: 'IS - Iceland',
    dialCode: '+354',
  },
  IN: {
    locales: {
      en: 'India',
      fr: 'Inde',
      es: 'India',
      de: 'Indien',
      nl: 'India',
    },
    alpha3: 'IND',
    countryConl: '356',
    name: 'IN - India',
    dialCode: '+91',
  },
  ID: {
    locales: {
      en: 'Indonesia',
      fr: 'Indonésie',
      es: 'Indonesia',
      de: 'Indonesien',
      nl: 'Indonesia',
    },
    alpha3: 'IDN',
    countryConl: '360',
    name: 'ID - Indonesia',
    dialCode: '+62',
  },
  IR: {
    locales: {
      en: 'Iran (Islamic Republic of)',
      fr: 'Iran',
      es: 'Irán',
      de: 'Iran',
      nl: 'Iran',
    },
    alpha3: 'IRN',
    countryConl: '364',
    name: 'IR - Iran (Islamic Republic of)',
    dialCode: '+98',
  },
  IQ: {
    locales: {
      en: 'Iraq',
      fr: 'Irak',
      es: 'Irak',
      de: 'Irak',
      nl: 'Iraq',
    },
    alpha3: 'IRQ',
    countryConl: '368',
    name: 'IQ - Iraq',
    dialCode: '+964',
  },
  IE: {
    locales: {
      en: 'Ireland',
      fr: 'Irlande',
      es: 'Irlanda',
      de: 'Irland',
      nl: 'Ireland',
    },
    alpha3: 'IRL',
    countryConl: '372',
    name: 'IE - Ireland',
    dialCode: '+353',
  },
  IM: {
    locales: {
      en: 'Isle of Man',
      fr: 'Ile de Man',
      es: 'Isla de Man',
      de: 'Insel Man',
      nl: 'Isle of Man',
    },
    alpha3: 'IMN',
    countryConl: '833',
    name: 'IM - Isle of Man',
    dialCode: '+44',
  },
  IL: {
    locales: {
      en: 'Israel',
      fr: 'Israël',
      es: 'Israel',
      de: 'Israel',
      nl: 'Israel',
    },
    alpha3: 'ISR',
    countryConl: '376',
    name: 'IL - Israel',
    dialCode: '+972',
  },
  IT: {
    locales: {
      en: 'Italy',
      fr: 'Italie',
      es: 'Italia',
      de: 'Italien',
      nl: 'Italy',
    },
    alpha3: 'ITA',
    countryConl: '380',
    name: 'IT - Italy',
    dialCode: '+39',
  },
  JM: {
    locales: {
      en: 'Jamaica',
      fr: 'Jamaïque',
      es: 'Jamaica',
      de: 'Jamaika',
      nl: 'Jamaica',
    },
    alpha3: 'JAM',
    countryConl: '388',
    name: 'JM - Jamaica',
    dialCode: '+1876',
  },
  JP: {
    locales: {
      en: 'Japan',
      fr: 'Japon',
      es: 'Japón',
      de: 'Japan',
      nl: 'Japan',
    },
    alpha3: 'JPN',
    countryConl: '392',
    name: 'JP - Japan',
    dialCode: '+81',
  },
  JE: {
    locales: {
      en: 'Jersey',
      fr: 'Jersey',
      es: 'Jersey',
      de: 'Jersey',
      nl: 'Jersey',
    },
    alpha3: 'JEY',
    countryConl: '832',
    name: 'JE - Jersey',
    dialCode: '+44',
  },
  JO: {
    locales: {
      en: 'Jordan',
      fr: 'Jordanie',
      es: 'Jordania',
      de: 'Jordanien',
      nl: 'Jordan',
    },
    alpha3: 'JOR',
    countryConl: '400',
    name: 'JO - Jordan',
    dialCode: '+962',
  },
  KZ: {
    locales: {
      en: 'Kazakhstan',
      fr: 'Kazakhstan',
      es: 'Kazajistán',
      de: 'Kasachstan',
      nl: 'Kazakhstan',
    },
    alpha3: 'KAZ',
    countryConl: '398',
    name: 'KZ - Kazakhstan',
    dialCode: '+7',
  },
  KE: {
    locales: {
      en: 'Kenya',
      fr: 'Kenya',
      es: 'Kenia',
      de: 'Kenia',
      nl: 'Kenya',
    },
    alpha3: 'KEN',
    countryConl: '404',
    name: 'KE - Kenya',
    dialCode: '+254',
  },
  KI: {
    locales: {
      en: 'Kiribati',
      fr: 'Kiribati',
      es: 'Kiribati',
      de: 'Kiribati',
      nl: 'Kiribati',
    },
    alpha3: 'KIR',
    countryConl: '296',
    name: 'KI - Kiribati',
    dialCode: '+686',
  },
  KP: {
    locales: {
      en: "Korea (Democratic People's Republic of)",
      fr: 'Corée du Nord',
      es: 'Corea (del Norte)',
      de: 'Korea (Norden)',
      nl: 'Korea (North)',
    },
    alpha3: 'PRK',
    countryConl: '408',
    name: "KP - Korea (Democratic People's Republic of)",
    dialCode: '+850',
  },
  KR: {
    locales: {
      en: 'Korea, Republic of',
      fr: 'Corée, République de',
      es: 'Corea, República de',
      de: 'Korea, Republik',
      nl: 'Korea, Republic of',
    },
    alpha3: 'KOR',
    countryConl: '410',
    name: 'KR - Korea, Republic of',
    dialCode: '+82',
  },
  KW: {
    locales: {
      en: 'Kuwait',
      fr: 'Koweït',
      es: 'Kuwait',
      de: 'Kuwait',
      nl: 'Kuwait',
    },
    alpha3: 'KWT',
    countryConl: '414',
    name: 'KW - Kuwait',
    dialCode: '+965',
  },
  KG: {
    locales: {
      en: 'Kyrgyzstan',
      fr: 'Kirghizistan',
      es: 'Kirguistán',
      de: 'Kirgisistan',
      nl: 'Kyrgyzstan',
    },
    alpha3: 'KGZ',
    countryConl: '417',
    name: 'KG - Kyrgyzstan',
    dialCode: '+996',
  },
  LA: {
    locales: {
      en: 'Laos',
      fr: 'Laos',
      es: 'Laos',
      de: 'Laos , Demokratische Volksrepublik',
      nl: "Laos People's Democratic Republic",
    },
    alpha3: 'LAO',
    countryConl: '418',
    name: "LA - Laos People's Democratic Republic",
    dialCode: '+856',
  },
  LV: {
    locales: {
      en: 'Latvia',
      fr: 'Lettonie',
      es: 'Letonia',
      de: 'Lettland',
      nl: 'Latvia',
    },
    alpha3: 'LVA',
    countryConl: '428',
    name: 'LV - Latvia',
    dialCode: '+371',
  },
  LB: {
    locales: {
      en: 'Lebanon',
      fr: 'Liban',
      es: 'Líbano',
      de: 'Libanon',
      nl: 'Lebanon',
    },
    alpha3: 'LBN',
    countryConl: '422',
    name: 'LB - Lebanon',
    dialCode: '+961',
  },
  LS: {
    locales: {
      en: 'Lesotho',
      fr: 'Lesotho',
      es: 'Lesotho',
      de: 'Lesotho',
      nl: 'Lesotho',
    },
    alpha3: 'LSO',
    countryConl: '426',
    name: 'LS - Lesotho',
    dialCode: '+266',
  },
  LR: {
    locales: {
      en: 'Liberia',
      fr: 'Libéria',
      es: 'Liberia',
      de: 'Liberia',
      nl: 'Liberia',
    },
    alpha3: 'LBR',
    countryConl: '430',
    name: 'LR - Liberia',
    dialCode: '+231',
  },
  LY: {
    locales: {
      en: 'Libya',
      fr: 'Libye',
      es: 'Libia',
      de: 'Libyen',
      nl: 'Libya',
    },
    alpha3: 'LBY',
    countryConl: '434',
    name: 'LY - Libya',
    dialCode: '+218',
  },
  LI: {
    locales: {
      en: 'Liechtenstein',
      fr: 'Liechtenstein',
      es: 'Liechtenstein',
      de: 'Liechtenstein',
      nl: 'Liechtenstein',
    },
    alpha3: 'LIE',
    countryConl: '438',
    name: 'LI - Liechtenstein',
    dialCode: '+423',
  },
  LT: {
    locales: {
      en: 'Lithuania',
      fr: 'Lituanie',
      es: 'Lituania',
      de: 'Litauen',
      nl: 'Lithuania',
    },
    alpha3: 'LTU',
    countryConl: '440',
    name: 'LT - Lithuania',
    dialCode: '+370',
  },
  LU: {
    locales: {
      en: 'Luxembourg',
      fr: 'Luxembourg',
      es: 'Luxemburgo',
      de: 'Luxemburg',
      nl: 'Luxembourg',
    },
    alpha3: 'LUX',
    countryConl: '442',
    name: 'LU - Luxembourg',
    dialCode: '+352',
  },
  MO: {
    locales: {
      en: 'Macao',
      fr: 'Macao',
      es: 'Macao',
      de: 'Macau',
      nl: 'Macau',
    },
    alpha3: 'MAC',
    countryConl: '446',
    name: 'MO - Macao',
    dialCode: '+853',
  },
  MG: {
    locales: {
      en: 'Madagascar',
      fr: 'Madagascar',
      es: 'Madagascar',
      de: 'Madagaskar',
      nl: 'Madagascar',
    },
    alpha3: 'MDG',
    countryConl: '450',
    name: 'MG - Madagascar',
    dialCode: '+261',
  },
  MW: {
    locales: {
      en: 'Malawi',
      fr: 'Malawi',
      es: 'Malawi',
      de: 'Malawi',
      nl: 'Malawi',
    },
    alpha3: 'MWI',
    countryConl: '454',
    name: 'MW - Malawi',
    dialCode: '+265',
  },
  MY: {
    locales: {
      en: 'Malaysia',
      fr: 'Malaisie',
      es: 'Malasia',
      de: 'Malaysia',
      nl: 'Malaysia',
    },
    alpha3: 'MYS',
    countryConl: '458',
    name: 'MY - Malaysia',
    dialCode: '+60',
  },
  MV: {
    locales: {
      en: 'Maldives',
      fr: 'Maldives',
      es: 'Maldivas',
      de: 'Malediven',
      nl: 'Maldives',
    },
    alpha3: 'MDV',
    countryConl: '462',
    name: 'MV - Maldives',
    dialCode: '+960',
  },
  ML: {
    locales: {
      en: 'Mali',
      fr: 'Mali',
      es: 'Malí',
      de: 'Mali',
      nl: 'Mali',
    },
    alpha3: 'MLI',
    countryConl: '466',
    name: 'ML - Mali',
    dialCode: '+223',
  },
  MT: {
    locales: {
      en: 'Malta',
      fr: 'Malte',
      es: 'Malta',
      de: 'Malta',
      nl: 'Malta',
    },
    alpha3: 'MLT',
    countryConl: '470',
    name: 'MT - Malta',
    dialCode: '+356',
  },
  MH: {
    locales: {
      en: 'Marshall Islands',
      fr: 'Iles Marshall',
      es: 'Islas Marshall',
      de: 'Marshallinseln',
      nl: 'Marshall Islands',
    },
    alpha3: 'MHL',
    countryConl: '584',
    name: 'MH - Marshall Islands',
    dialCode: '+692',
  },
  MQ: {
    locales: {
      en: 'Martinique',
      fr: 'Martinique',
      es: 'Martinica',
      de: 'Martinique',
      nl: 'Martinique',
    },
    alpha3: 'MTQ',
    countryConl: '474',
    name: 'MQ - Martinique',
    dialCode: '+596',
  },
  MR: {
    locales: {
      en: 'Mauritania',
      fr: 'Mauritanie',
      es: 'Mauritania',
      de: 'Mauretanien',
      nl: 'Mauritania',
    },
    alpha3: 'MRT',
    countryConl: '478',
    name: 'MR - Mauritania',
    dialCode: '+222',
  },
  MU: {
    locales: {
      en: 'Mauritius',
      fr: 'Maurice',
      es: 'Mauricio',
      de: 'Mauritius',
      nl: 'Mauritius',
    },
    alpha3: 'MUS',
    countryConl: '480',
    name: 'MU - Mauritius',
    dialCode: '+230',
  },
  YT: {
    locales: {
      en: 'Mayotte',
      fr: 'Mayotte',
      es: 'Mayotte',
      de: 'Mayotte',
      nl: 'Mayotte',
    },
    alpha3: 'MYT',
    countryConl: '175',
    name: 'YT - Mayotte',
    dialCode: '+262',
  },
  MX: {
    locales: {
      en: 'Mexico',
      fr: 'Mexique',
      es: 'México',
      de: 'Mexiko',
      nl: 'Mexico',
    },
    alpha3: 'MEX',
    countryConl: '484',
    name: 'MX - Mexico',
    dialCode: '+52',
  },
  FM: {
    locales: {
      en: 'Micronesia (Federated States of)',
      fr: 'Micronésie, États fédérés de',
      es: 'Micronesia, Estados Federados de',
      de: 'Mikronesien, Föderierte Staaten von',
      nl: 'Micronesia, Federated States of',
    },
    alpha3: 'FSM',
    countryConl: '583',
    name: 'FM - Micronesia (Federated States of)',
    dialCode: '+691',
  },
  MD: {
    locales: {
      en: 'Moldova, Republic of',
      fr: 'Moldavie, République de ',
      es: 'Moldavia, República de',
      de: 'Moldau, Republik',
      nl: 'Moldova, Republic of',
    },
    alpha3: 'MDA',
    countryConl: '498',
    name: 'MD - Moldova, Republic of',
    dialCode: '+373',
  },
  MC: {
    locales: {
      en: 'Monaco',
      fr: 'Monaco',
      es: 'Mónaco',
      de: 'Monaco',
      nl: 'Monaco',
    },
    alpha3: 'MCO',
    countryConl: '492',
    name: 'MC - Monaco',
    dialCode: '+377',
  },
  MN: {
    locales: {
      en: 'Mongolia',
      fr: 'Mongalie',
      es: 'Mongolia',
      de: 'Mongolei',
      nl: 'Mongolia',
    },
    alpha3: 'MNG',
    countryConl: '496',
    name: 'MN - Mongolia',
    dialCode: '+976',
  },
  ME: {
    locales: {
      en: 'Montenegro',
      fr: 'Monténégro',
      es: 'Montenegro',
      de: 'Montenegro',
      nl: 'Montenegro',
    },
    alpha3: 'MNE',
    countryConl: '499',
    name: 'ME - Montenegro',
    dialCode: '+382',
  },
  MS: {
    locales: {
      en: 'Montserrat',
      fr: 'Montserrat',
      es: 'Montserrat',
      de: 'Montserrat',
      nl: 'Montserrat',
    },
    alpha3: 'MSR',
    countryConl: '500',
    name: 'MS - Montserrat',
    dialCode: '+1664',
  },
  MA: {
    locales: {
      en: 'Morocco',
      fr: 'Maroc',
      es: 'Marruecos',
      de: 'Marokko',
      nl: 'Morocco',
    },
    alpha3: 'MAR',
    countryConl: '504',
    name: 'MA - Morocco',
    dialCode: '+212',
  },
  MZ: {
    locales: {
      en: 'Mozambique',
      fr: 'Mozambique',
      es: 'Mozambique',
      de: 'Mosambik',
      nl: 'Mozambique',
    },
    alpha3: 'MOZ',
    countryConl: '508',
    name: 'MZ - Mozambique',
    dialCode: '+258',
  },
  MM: {
    locales: {
      en: 'Myanmar',
      fr: 'Myanmar',
      es: 'Myanmar',
      de: 'Myanmar',
      nl: 'Myanmar',
    },
    alpha3: 'MMR',
    countryConl: '104',
    name: 'MM - Myanmar',
    dialCode: '+95',
  },
  NA: {
    locales: {
      en: 'Namibia',
      fr: 'Namibie',
      es: 'Namibia',
      de: 'Namibia',
      nl: 'Namibia',
    },
    alpha3: 'NAM',
    countryConl: '516',
    name: 'NA - Namibia',
    dialCode: '+264',
  },
  NR: {
    locales: {
      en: 'Nauru',
      fr: 'Nauru',
      es: 'Nauru',
      de: 'Nauru',
      nl: 'Nauru',
    },
    alpha3: 'NRU',
    countryConl: '520',
    name: 'NR - Nauru',
    dialCode: '+674',
  },
  NP: {
    locales: {
      en: 'Nepal',
      fr: 'Népal',
      es: 'Nepal',
      de: 'Nepal',
      nl: 'Nepal',
    },
    alpha3: 'NPL',
    countryConl: '524',
    name: 'NP - Nepal',
    dialCode: '+977',
  },
  NL: {
    locales: {
      en: 'Netherlands',
      fr: 'Pays-Bas',
      es: 'Países Bajos',
      de: 'Niederlande',
      nl: 'Netherlands',
    },
    alpha3: 'NLD',
    countryConl: '528',
    name: 'NL - Netherlands',
    dialCode: '+31',
  },
  NC: {
    locales: {
      en: 'New Caledonia',
      fr: 'Nouvelle-Calédonie',
      es: 'Nueva Caledonia',
      de: 'Neukaledonien',
      nl: 'New Caledonia',
    },
    alpha3: 'NCL',
    countryConl: '540',
    name: 'NC - New Caledonia',
    dialCode: '+687',
  },
  NZ: {
    locales: {
      en: 'New Zealand',
      fr: 'Nouvelle-Zélande',
      es: 'Nueva Zelanda',
      de: 'Neuseeland',
      nl: 'New Zealand',
    },
    alpha3: 'NZL',
    countryConl: '554',
    name: 'NZ - New Zealand',
    dialCode: '+64',
  },
  NI: {
    locales: {
      en: 'Nicaragua',
      fr: 'Nicaragua',
      es: 'Nicaragua',
      de: 'Nicaragua',
      nl: 'Nicaragua',
    },
    alpha3: 'NIC',
    countryConl: '558',
    name: 'NI - Nicaragua',
    dialCode: '+505',
  },
  NE: {
    locales: {
      en: 'Niger',
      fr: 'Niger',
      es: 'Níger',
      de: 'Niger',
      nl: 'Niger',
    },
    alpha3: 'NER',
    countryConl: '562',
    name: 'NE - Niger',
    dialCode: '+227',
  },
  NG: {
    locales: {
      en: 'Nigeria',
      fr: 'Nigéria',
      es: 'Nigeria',
      de: 'Nigeria',
      nl: 'Nigeria',
    },
    alpha3: 'NGA',
    countryConl: '566',
    name: 'NG - Nigeria',
    dialCode: '+234',
  },
  NU: {
    locales: {
      en: 'Niue',
      fr: 'Niue',
      es: 'Niue',
      de: 'Niue',
      nl: 'Niue',
    },
    alpha3: 'NIU',
    countryConl: '570',
    name: 'NU - Niue',
    dialCode: '+683',
  },
  NF: {
    locales: {
      en: 'Norfolk Island',
      fr: 'Norfolk, Ile',
      es: 'Isla de Norfolk',
      de: 'Norfolk Insel',
      nl: 'Norfolk Island',
    },
    alpha3: 'NFK',
    countryConl: '574',
    name: 'NF - Norfolk Island',
    dialCode: '+672',
  },
  MK: {
    locales: {
      en: 'North Macedonia',
      fr: 'Macédoine du Nord',
      es: 'Macedonia del Norte',
      de: 'Nordmazedonien',
      nl: 'North Macedonia',
    },
    alpha3: 'MKD',
    countryConl: '807',
    name: 'MK - North Macedonia',
    dialCode: '+389',
  },
  MP: {
    locales: {
      en: 'Northern Mariana Islands',
      fr: 'Mariannes du Nord, Iles',
      es: 'Islas Marianas del Norte',
      de: 'Nördliche Marianen Inseln',
      nl: 'Northern Mariana Islands',
    },
    alpha3: 'MNP',
    countryConl: '580',
    name: 'MP - Northern Mariana Islands',
    dialCode: '+1670',
  },
  NO: {
    locales: {
      en: 'Norway',
      fr: 'Norvège',
      es: 'Noruega',
      de: 'Norwegen',
      nl: 'Norway',
    },
    alpha3: 'NOR',
    countryConl: '578',
    name: 'NO - Norway',
    dialCode: '+47',
  },
  OM: {
    locales: {
      en: 'Oman',
      fr: 'Oman',
      es: 'Omán',
      de: 'Oman',
      nl: 'Oman',
    },
    alpha3: 'OMN',
    countryConl: '512',
    name: 'OM - Oman',
    dialCode: '+968',
  },
  PK: {
    locales: {
      en: 'Pakistan',
      fr: 'Pakistan',
      es: 'Pakistán',
      de: 'Pakistan',
      nl: 'Pakistan',
    },
    alpha3: 'PAK',
    countryConl: '586',
    name: 'PK - Pakistan',
    dialCode: '+92',
  },
  PW: {
    locales: {
      en: 'Palau',
      fr: 'Palaos',
      es: 'Palau',
      de: 'Palaos',
      nl: 'Palau',
    },
    alpha3: 'PLW',
    countryConl: '585',
    name: 'PW - Palau',
    dialCode: '+680',
  },
  PS: {
    locales: {
      en: 'Palestine, State of',
      fr: 'Palestine, État de',
      es: 'Palestina, Estado de',
      de: 'Palästina, Staat von',
      nl: 'Palestine, State of',
    },
    alpha3: 'PSE',
    countryConl: '275',
    name: 'PS - Palestine, State of',
    dialCode: '+970',
  },
  PA: {
    locales: {
      en: 'Panama',
      fr: 'Panama',
      es: 'Panamá',
      de: 'Panama',
      nl: 'Panama',
    },
    alpha3: 'PAN',
    countryConl: '591',
    name: 'PA - Panama',
    dialCode: '+507',
  },
  PG: {
    locales: {
      en: 'Papua New Guinea',
      fr: 'Papouasie-Nouvelle-Guinée',
      es: 'Papúa Nueva Guinea',
      de: 'Papua-Neuguinea',
      nl: 'Papua New Guinea',
    },
    alpha3: 'PNG',
    countryConl: '598',
    name: 'PG - Papua New Guinea',
    dialCode: '+675',
  },
  PY: {
    locales: {
      en: 'Paraguay',
      fr: 'Paraguay',
      es: 'Paraguay',
      de: 'Paraguay',
      nl: 'Paraguay',
    },
    alpha3: 'PRY',
    countryConl: '600',
    name: 'PY - Paraguay',
    dialCode: '+595',
  },
  PE: {
    locales: {
      en: 'Peru',
      fr: 'Pérou',
      es: 'Perú',
      de: 'Peru',
      nl: 'Peru',
    },
    alpha3: 'PER',
    countryConl: '604',
    name: 'PE - Peru',
    dialCode: '+51',
  },
  PH: {
    locales: {
      en: 'Philippines',
      fr: 'Philippines',
      es: 'Filipinas',
      de: 'Philippinen',
      nl: 'Philippines',
    },
    alpha3: 'PHL',
    countryConl: '608',
    name: 'PH - Philippines',
    dialCode: '+63',
  },
  PN: {
    locales: {
      en: 'Pitcairn',
      fr: 'Pitcairn',
      es: 'Pitcairn',
      de: 'Pitcairn',
      nl: 'Pitcairn',
    },
    alpha3: 'PCN',
    countryConl: '612',
    name: 'PN - Pitcairn',
    dialCode: '+64',
  },
  PL: {
    locales: {
      en: 'Poland',
      fr: 'Pologne',
      es: 'Polonia',
      de: 'Polen',
      nl: 'Poland',
    },
    alpha3: 'POL',
    countryConl: '616',
    name: 'PL - Poland',
    dialCode: '+48',
  },
  PT: {
    locales: {
      en: 'Portugal',
      fr: 'Portugal',
      es: 'Portugal',
      de: 'Portugal',
      nl: 'Portugal',
    },
    alpha3: 'PRT',
    countryConl: '620',
    name: 'PT - Portugal',
    dialCode: '+351',
  },
  PR: {
    locales: {
      en: 'Puerto Rico',
      fr: 'Porto Rico',
      es: 'Puerto Rico',
      de: 'Puerto Rico',
      nl: 'Puerto Rico',
    },
    alpha3: 'PRI',
    countryConl: '630',
    name: 'PR - Puerto Rico',
    dialCode: '+1939',
  },
  QA: {
    locales: {
      en: 'Qatar',
      fr: 'Quatar',
      es: 'Qatar',
      de: 'Katar',
      nl: 'Qatar',
    },
    alpha3: 'QAT',
    countryConl: '634',
    name: 'QA - Qatar',
    dialCode: '+974',
  },
  RE: {
    locales: {
      en: 'Réunion',
      fr: 'Réunion',
      es: 'Isla de la Reunión',
      de: 'Réunion, La',
      nl: 'Reunion Island',
    },
    alpha3: 'REU',
    countryConl: '638',
    name: 'RE - Réunion',
    dialCode: '+262',
  },
  RO: {
    locales: {
      en: 'Romania',
      fr: 'Roumanie',
      es: 'Rumanía',
      de: 'Rumänien',
      nl: 'Romania',
    },
    alpha3: 'ROU',
    countryConl: '642',
    name: 'RO - Romania',
    dialCode: '+40',
  },
  RU: {
    locales: {
      en: 'Russian Federation',
      fr: 'Russie, Fédération de',
      es: 'Rusia, Federación de',
      de: 'Russische Föderation',
      nl: 'Russia, Federation of',
    },
    alpha3: 'RUS',
    countryConl: '643',
    name: 'RU - Russian Federation',
    dialCode: '+7',
  },
  RW: {
    locales: {
      en: 'Rwanda',
      fr: 'Rwanda',
      es: 'Ruanda',
      de: 'Ruanda',
      nl: 'Rwanda',
    },
    alpha3: 'RWA',
    countryConl: '646',
    name: 'RW - Rwanda',
    dialCode: '+250',
  },
  BL: {
    locales: {
      en: 'Saint Barthélemy',
      fr: 'Saint-Barthélemy',
      es: 'San Bartolomé',
      de: 'Saint-Barthélemy',
      nl: 'Saint Barthélemy',
    },
    alpha3: 'BLM',
    countryConl: '652',
    name: 'BL - Saint Barthélemy',
    dialCode: '+590',
  },
  SH: {
    locales: {
      en: 'Saint Helena, Ascension and Tristan da Cunha',
      fr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
      es: 'Santa Elena, Ascensión y Tristán da Cunha',
      de: 'St. Helena, Ascension und Tristan da Cunha',
      nl: 'St Helena, Ascension and Tristan da Cunha',
    },
    alpha3: 'SHN',
    countryConl: '654',
    name: 'SH - Saint Helena, Ascension and Tristan da Cunha',
    dialCode: '+290',
  },
  KN: {
    locales: {
      en: 'Saint Kitts and Nevis',
      fr: 'Saint-Kitts-et-Nevis',
      es: 'San Cristóbal y Nieves',
      de: 'St. Kitts und Nevis',
      nl: 'Saint Kitts and Nevis',
    },
    alpha3: 'KNA',
    countryConl: '659',
    name: 'KN - Saint Kitts and Nevis',
    dialCode: '+1869',
  },
  LC: {
    locales: {
      en: 'Saint Lucia',
      fr: 'Sainte-Lucie',
      es: 'Santa Lucía',
      de: 'St. Lucia',
      nl: 'Saint Lucia',
    },
    alpha3: 'LCA',
    countryConl: '662',
    name: 'LC - Saint Lucia',
    dialCode: '+1758',
  },
  MF: {
    locales: {
      en: 'Saint Martin (French part)',
      fr: 'Saint-Martin (partie française)',
      es: 'Saint-Martin (parte francesa)',
      de: 'Saint-Martin (französischer Teil)',
      nl: 'Saint Martin (French part)',
    },
    alpha3: 'MAF',
    countryConl: '663',
    name: 'MF - Saint Martin (French part)',
    dialCode: '+590',
  },
  PM: {
    locales: {
      en: 'Saint Pierre and Miquelon',
      fr: 'Saint-Pierre-et-Miquelon',
      es: 'San Pedro y Miquelón',
      de: 'Saint-Pierre und Miquelon',
      nl: 'Saint Pierre and Miquelon',
    },
    alpha3: 'SPM',
    countryConl: '666',
    name: 'PM - Saint Pierre and Miquelon',
    dialCode: '+508',
  },
  VC: {
    locales: {
      en: 'Saint Vincent and the Grenadines',
      fr: 'Saint-Vincent-et-les Grenadines',
      es: 'San Vicente y las Granadinas',
      de: 'St. Vincent und die Grenadinen',
      nl: 'Saint Vincent and the Grenadines',
    },
    alpha3: 'VCT',
    countryConl: '670',
    name: 'VC - Saint Vincent and the Grenadines',
    dialCode: '+1784',
  },
  WS: {
    locales: {
      en: 'Samoa',
      fr: 'Samoa',
      es: 'Samoa',
      de: 'Samoa',
      nl: 'Samoa',
    },
    alpha3: 'WSM',
    countryConl: '882',
    name: 'WS - Samoa',
    dialCode: '+685',
  },
  SM: {
    locales: {
      en: 'San Marino',
      fr: 'Saint-Marin',
      es: 'San Marino',
      de: 'San Marin',
      nl: 'San Marino',
    },
    alpha3: 'SMR',
    countryConl: '674',
    name: 'SM - San Marino',
    dialCode: '+378',
  },
  ST: {
    locales: {
      en: 'Sao Tome and Principe',
      fr: 'Sao Tomé-et-Principe',
      es: 'Santo Tomé y Príncipe',
      de: 'Sao Tomé und Principe',
      nl: 'Sao Tome and Principe',
    },
    alpha3: 'STP',
    countryConl: '678',
    name: 'ST - Sao Tome and Principe',
    dialCode: '+239',
  },
  SA: {
    locales: {
      en: 'Saudi Arabia',
      fr: 'Arabie saoudite',
      es: 'Arabia Saudí',
      de: 'Saudi-Arabien',
      nl: 'Saudi Arabia',
    },
    alpha3: 'SAU',
    countryConl: '682',
    name: 'SA - Saudi Arabia',
    dialCode: '+966',
  },
  SN: {
    locales: {
      en: 'Senegal',
      fr: 'Sénégal',
      es: 'Senegal',
      de: 'Senegal',
      nl: 'Senegal',
    },
    alpha3: 'SEN',
    countryConl: '686',
    name: 'SN - Senegal',
    dialCode: '+221',
  },
  RS: {
    locales: {
      en: 'Serbia',
      fr: 'Serbie',
      es: 'Serbia',
      de: 'Serbien',
      nl: 'Serbia',
    },
    alpha3: 'SRB',
    countryConl: '688',
    name: 'RS - Serbia',
    dialCode: '+381',
  },
  SC: {
    locales: {
      en: 'Seychelles',
      fr: 'Seychelles',
      es: 'Seychelles',
      de: 'Seychellen',
      nl: 'Seychelles',
    },
    alpha3: 'SYC',
    countryConl: '690',
    name: 'SC - Seychelles',
    dialCode: '+248',
  },
  SL: {
    locales: {
      en: 'Sierra Leone',
      fr: 'Sierra Leone',
      es: 'Sierra Leona',
      de: 'Sierra Leone',
      nl: 'Sierra Leone',
    },
    alpha3: 'SLE',
    countryConl: '694',
    name: 'SL - Sierra Leone',
    dialCode: '+232',
  },
  SG: {
    locales: {
      en: 'Singapore',
      fr: 'Singapour',
      es: 'Singapur',
      de: 'Singapur',
      nl: 'Singapore',
    },
    alpha3: 'SGP',
    countryConl: '702',
    name: 'SG - Singapore',
    dialCode: '+65',
  },
  SX: {
    locales: {
      en: 'Sint Maarten (Dutch part)',
      fr: 'Saint-Martin (partie néerlandaise)',
      es: 'San Martín (parte holandesa)',
      de: 'Saint-Martin (niederländischer Teil)',
      nl: 'Sint Maarten (Dutch part)',
    },
    alpha3: 'SXM',
    countryConl: '534',
    name: 'SX - Sint Maarten (Dutch part)',
  },
  SK: {
    locales: {
      en: 'Slovakia',
      fr: 'Slovaquie',
      es: 'Eslovaquia',
      de: 'Slowakei',
      nl: 'Slovakia',
    },
    alpha3: 'SVK',
    countryConl: '703',
    name: 'SK - Slovakia',
    dialCode: '+421',
  },
  SI: {
    locales: {
      en: 'Slovenia',
      fr: 'Slovénie',
      es: 'Eslovenia',
      de: 'Slowenien',
      nl: 'Slovenia',
    },
    alpha3: 'SVN',
    countryConl: '705',
    name: 'SI - Slovenia',
    dialCode: '+386',
  },
  SB: {
    locales: {
      en: 'Solomon Islands',
      fr: 'Salomon, Iles',
      es: 'Islas Salomón',
      de: 'Salomon-Inseln',
      nl: 'Solomon Islands',
    },
    alpha3: 'SLB',
    countryConl: '90',
    name: 'SB - Solomon Islands',
    dialCode: '+677',
  },
  SO: {
    locales: {
      en: 'Somalia',
      fr: 'Somalie',
      es: 'Somalia',
      de: 'Somalia',
      nl: 'Somalia',
    },
    alpha3: 'SOM',
    countryConl: '706',
    name: 'SO - Somalia',
    dialCode: '+252',
  },
  ZA: {
    locales: {
      en: 'South Africa',
      fr: 'Afrique du Sud',
      es: 'Sudáfrica',
      de: 'Südafrika',
      nl: 'South Africa',
    },
    alpha3: 'ZAF',
    countryConl: '710',
    name: 'ZA - South Africa',
    dialCode: '+27',
  },
  GS: {
    locales: {
      en: 'South Georgia and the South Sandwich Islands',
      fr: 'Géorgie du Sud, Iles Sandwich du Sud',
      es: 'Georgia del Sur y las Islas Sandwich del Sur',
      de: 'Südgeorgien und die Südlichen Sandwichinseln',
      nl: 'South Georgia and the South Sandwich Islands',
    },
    alpha3: 'SGS',
    countryConl: '239',
    name: 'GS - South Georgia and the South Sandwich Islands',
    dialCode: '+500',
  },
  SS: {
    locales: {
      en: 'South Sudan',
      fr: 'Soudan du Sud',
      es: 'Sudán del Sur',
      de: 'Südsudan',
      nl: 'South Sudan',
    },
    alpha3: 'SSD',
    countryConl: '728',
    name: 'SS - South Sudan',
    dialCode: '+211',
  },
  ES: {
    locales: {
      en: 'Spain',
      fr: 'Espagne',
      es: 'España',
      de: 'Spanien',
      nl: 'Spain',
    },
    alpha3: 'ESP',
    countryConl: '724',
    name: 'ES - Spain',
    dialCode: '+34',
  },
  LK: {
    locales: {
      en: 'Sri Lanka',
      fr: 'Sri Lanka',
      es: 'Sri Lanka',
      de: 'Sri Lanka',
      nl: 'Sri Lanka',
    },
    alpha3: 'LKA',
    countryConl: '144',
    name: 'LK - Sri Lanka',
    dialCode: '+94',
  },
  SD: {
    locales: {
      en: 'Sudan',
      fr: 'Soudan',
      es: 'Sudán',
      de: 'Sudan',
      nl: 'Sudan',
    },
    alpha3: 'SDN',
    countryConl: '729',
    name: 'SD - Sudan',
    dialCode: '+249',
  },
  SR: {
    locales: {
      en: 'Suriname',
      fr: 'Surinam',
      es: 'Surinam',
      de: 'Surinam',
      nl: 'Suriname',
    },
    alpha3: 'SUR',
    countryConl: '740',
    name: 'SR - Suriname',
    dialCode: '+597',
  },
  SJ: {
    locales: {
      en: 'Svalbard and Jan Mayen',
      fr: 'Svalbard et Ile Jan Mayen',
      es: 'Islas Svalbard y Jan Mayen',
      de: 'Svalbard und Jan Mayen',
      nl: 'Svalbard and Jan Mayen Island',
    },
    alpha3: 'SJM',
    countryConl: '744',
    name: 'SJ - Svalbard and Jan Mayen',
    dialCode: '+47',
  },
  SE: {
    locales: {
      en: 'Sweden',
      fr: 'Suède',
      es: 'Suecia',
      de: 'Schweden',
      nl: 'Sweden',
    },
    alpha3: 'SWE',
    countryConl: '752',
    name: 'SE - Sweden',
    dialCode: '+46',
  },
  CH: {
    locales: {
      en: 'Switzerland',
      fr: 'Suisse',
      es: 'Suiza',
      de: 'Schweiz',
      nl: 'Switzerland',
    },
    alpha3: 'CHE',
    countryConl: '756',
    name: 'CH - Switzerland',
    dialCode: '+41',
  },
  SY: {
    locales: {
      en: 'Syrian Arab Republic',
      fr: 'Syrie',
      es: 'Siria',
      de: 'Syrien',
      nl: 'Syria',
    },
    alpha3: 'SYR',
    countryConl: '760',
    name: 'SY - Syrian Arab Republic',
    dialCode: '+963',
  },
  TW: {
    locales: {
      en: 'Taiwan , Province of China',
      fr: 'Taïwan , Province de Chine',
      es: 'Taiwán , provincia de China',
      de: 'Taiwan , Provinz China',
      nl: 'Taiwan , Province of China',
    },
    alpha3: 'TWN',
    countryConl: '158',
    name: 'TW - Taiwan , Province of China',
    dialCode: '+886',
  },
  TJ: {
    locales: {
      en: 'Tajikistan',
      fr: 'Tadjikistan',
      es: 'Tayikistán',
      de: 'Tadschikistan',
      nl: 'Tajikistan',
    },
    alpha3: 'TJK',
    countryConl: '762',
    name: 'TJ - Tajikistan',
    dialCode: '+992',
  },
  TZ: {
    locales: {
      en: 'Tanzania, United Republic of',
      fr: 'Tanzanie',
      es: 'Tanzania',
      de: 'Tansania',
      nl: 'Tanzania',
    },
    alpha3: 'TZA',
    countryConl: '834',
    name: 'TZ - Tanzania, United Republic of',
    dialCode: '+255',
  },
  TH: {
    locales: {
      en: 'Thailand',
      fr: 'Thaïlande',
      es: 'Tailandia',
      de: 'Thailand',
      nl: 'Thailand',
    },
    alpha3: 'THA',
    countryConl: '764',
    name: 'TH - Thailand',
    dialCode: '+66',
  },
  TL: {
    locales: {
      en: 'Timor-Leste',
      fr: 'Timor-Leste',
      es: 'Timor-Leste',
      de: 'Timor-Leste',
      nl: 'Timor-Leste',
    },
    alpha3: 'TLS',
    countryConl: '626',
    name: 'TL - Timor-Leste',
    dialCode: '+670',
  },
  TG: {
    locales: {
      en: 'Togo',
      fr: 'Togo',
      es: 'Togo',
      de: 'Togo',
      nl: 'Togo',
    },
    alpha3: 'TGO',
    countryConl: '768',
    name: 'TG - Togo',
    dialCode: '+228',
  },
  TK: {
    locales: {
      en: 'Tokelau',
      fr: 'Tokelau',
      es: 'Tokelau',
      de: 'Tokelau',
      nl: 'Tokelau',
    },
    alpha3: 'TKL',
    countryConl: '772',
    name: 'TK - Tokelau',
    dialCode: '+690',
  },
  TO: {
    locales: {
      en: 'Tonga',
      fr: 'Tonga',
      es: 'Tonga',
      de: 'Tonga',
      nl: 'Tonga',
    },
    alpha3: 'TON',
    countryConl: '776',
    name: 'TO - Tonga',
    dialCode: '+676',
  },
  TT: {
    locales: {
      en: 'Trinidad and Tobago',
      fr: 'Trinité-et-Tobago',
      es: 'Trinidad y Tobago',
      de: 'Trinidad und Tobago',
      nl: 'Trinidad and Tobago',
    },
    alpha3: 'TTO',
    countryConl: '780',
    name: 'TT - Trinidad and Tobago',
    dialCode: '+1868',
  },
  TN: {
    locales: {
      en: 'Tunisia',
      fr: 'Tunisie',
      es: 'Túnez',
      de: 'Tunesien',
      nl: 'Tunisia',
    },
    alpha3: 'TUN',
    countryConl: '788',
    name: 'TN - Tunisia',
    dialCode: '+216',
  },
  TR: {
    locales: {
      en: 'Turkey',
      fr: 'Turquie',
      es: 'Turquía',
      de: 'Türkei',
      nl: 'Turkey',
    },
    alpha3: 'TUR',
    countryConl: '792',
    name: 'TR - Turkey',
    dialCode: '+90',
  },
  TM: {
    locales: {
      en: 'Turkmenistan',
      fr: 'Turkménistan',
      es: 'Turkmenistán',
      de: 'Turkmenistan',
      nl: 'Turkmenistan',
    },
    alpha3: 'TKM',
    countryConl: '795',
    name: 'TM - Turkmenistan',
    dialCode: '+993',
  },
  TC: {
    locales: {
      en: 'Turks and Caicos Islands',
      fr: 'Turques-et-Caïques, Iles',
      es: 'Islas Turcas y Caicos',
      de: 'Turks- und Caicosinseln',
      nl: 'Turks and Caicos Islands',
    },
    alpha3: 'TCA',
    countryConl: '796',
    name: 'TC - Turks and Caicos Islands',
    dialCode: '+1649',
  },
  TV: {
    locales: {
      en: 'Tuvalu',
      fr: 'Tuvalu',
      es: 'Tuvalu',
      de: 'Tuvalu',
      nl: 'Tuvalu',
    },
    alpha3: 'TUV',
    countryConl: '798',
    name: 'TV - Tuvalu',
    dialCode: '+688',
  },
  UG: {
    locales: {
      en: 'Uganda',
      fr: 'Ouganda',
      es: 'Uganda',
      de: 'Uganda',
      nl: 'Uganda',
    },
    alpha3: 'UGA',
    countryConl: '800',
    name: 'UG - Uganda',
    dialCode: '+256',
  },
  UA: {
    locales: {
      en: 'Ukraine',
      fr: 'Ukraine',
      es: 'Ucrania',
      de: 'Ukraine',
      nl: 'Ukraine',
    },
    alpha3: 'UKR',
    countryConl: '804',
    name: 'UA - Ukraine',
    dialCode: '+380',
  },
  AE: {
    locales: {
      en: 'United Arab Emirates',
      fr: 'Émirats Arabes Unis',
      es: 'Emiratos Árabes Unidos',
      de: 'Vereinigte Arabische Emirate',
      nl: 'United Arab Emirates',
    },
    alpha3: 'ARE',
    countryConl: '784',
    name: 'AE - United Arab Emirates',
    dialCode: '+971',
  },
  GB: {
    locales: {
      en: 'United Kingdom of Great Britain and Northern Ireland',
      fr: 'Royaume-Uni',
      es: 'Reino Unido',
      de: 'Vereinigtes Königreich',
      nl: 'United Kingdom',
    },
    alpha3: 'GBR',
    countryConl: '826',
    name: 'GB - United Kingdom of Great Britain and Northern Ireland',
    dialCode: '+44',
  },
  UM: {
    locales: {
      en: 'United States Minor Outlying Islands',
      fr: "Iles mineures éloignées des États-Unis d'Amérique",
      es: 'Islas Ultramarinas Menores de los Estados Unidos',
      de: 'Kleinere abgelegene Inseln der Vereinigten Staaten',
      nl: 'United States Minor Outlying Islands',
    },
    alpha3: 'UMI',
    countryConl: '581',
    name: 'UM - United States Minor Outlying Islands',
  },
  US: {
    locales: {
      en: 'United States of America',
      fr: "États-Unis d'Amérique",
      es: 'Estados Unidos de América',
      de: 'Vereinigte Staaten von Amerika',
      nl: 'United States of America',
    },
    alpha3: 'USA',
    countryConl: '840',
    name: 'US - United States of America',
    dialCode: '+1',
  },
  UY: {
    locales: {
      en: 'Uruguay',
      fr: 'Uruguay',
      es: 'Uruguay',
      de: 'Uruguay',
      nl: 'Uruguay',
    },
    alpha3: 'URY',
    countryConl: '858',
    name: 'UY - Uruguay',
    dialCode: '+598',
  },
  UZ: {
    locales: {
      en: 'Uzbekistan',
      fr: 'Ouzbékistan',
      es: 'Uzbekistán',
      de: 'Usbekistan',
      nl: 'Uzbekistan',
    },
    alpha3: 'UZB',
    countryConl: '860',
    name: 'UZ - Uzbekistan',
    dialCode: '+998',
  },
  VU: {
    locales: {
      en: 'Vanuatu',
      fr: 'Vanuatu',
      es: 'Vanuatu',
      de: 'Vanuatu',
      nl: 'Vanuatu',
    },
    alpha3: 'VUT',
    countryConl: '548',
    name: 'VU - Vanuatu',
    dialCode: '+678',
  },
  VE: {
    locales: {
      en: 'Venezuela (Bolivarian Republic of)',
      fr: 'Vénézuela',
      es: 'Venezuela, República Bolivariana de',
      de: 'Venezuela, Bolivarische Republik',
      nl: 'Venezuela, Bolivarian Republic of',
    },
    alpha3: 'VEN',
    countryConl: '862',
    name: 'VE - Venezuela (Bolivarian Republic of)',
    dialCode: '+58',
  },
  VN: {
    locales: {
      en: 'Vietnam',
      fr: 'Vietnam',
      es: 'Vietnam',
      de: 'Vietnam',
      nl: 'Vietnam',
    },
    alpha3: 'VNM',
    countryConl: '704',
    name: 'VN - Vietnam',
    dialCode: '+84',
  },
  VG: {
    locales: {
      en: 'Virgin Islands (British)',
      fr: 'Iles Vierges britanniques',
      es: 'Islas Vírgenes Británicas',
      de: 'Britische Jungferninseln',
      nl: 'British Virgin Islands',
    },
    alpha3: 'VGB',
    countryConl: '92',
    name: 'VG - Virgin Islands (British)',
    dialCode: '+1284',
  },
  VI: {
    locales: {
      en: 'Virgin Islands (U.S.)',
      fr: 'Iles Vierges des États-Unis',
      es: 'Islas Vírgenes de EE.UU.',
      de: 'Jungferninseln der Vereinigten Staaten',
      nl: 'US Virgin Islands',
    },
    alpha3: 'VIR',
    countryConl: '850',
    name: 'VI - Virgin Islands (U.S.)',
    dialCode: '+1340',
  },
  WF: {
    locales: {
      en: 'Wallis and Futuna',
      fr: 'Wallis et Futuna',
      es: 'Wallis y Futuna',
      de: 'Wallis und Futuna',
      nl: 'Wallis and Futuna',
    },
    alpha3: 'WLF',
    countryConl: '876',
    name: 'WF - Wallis and Futuna',
    dialCode: '+681',
  },
  EH: {
    locales: {
      en: 'Western Sahara',
      fr: 'Sahara occidental',
      es: 'Sahara Occidental',
      de: 'Westsahara',
      nl: 'Western Sahara',
    },
    alpha3: 'ESH',
    countryConl: '732',
    name: 'EH - Western Sahara',
  },
  YE: {
    locales: {
      en: 'Yemen',
      fr: 'Yémen',
      es: 'Yemen',
      de: 'Jemen',
      nl: 'Yemen',
    },
    alpha3: 'YEM',
    countryConl: '887',
    name: 'YE - Yemen',
    dialCode: '+967',
  },
  ZM: {
    locales: {
      en: 'Zambia',
      fr: 'Zambie',
      es: 'Zambia',
      de: 'Sambia',
      nl: 'Zambia',
    },
    alpha3: 'ZMB',
    countryConl: '894',
    name: 'ZM - Zambia',
    dialCode: '+260',
  },
  ZW: {
    locales: {
      en: 'Zimbabwe',
      fr: 'Zimbabwe',
      es: 'Zimbabue',
      de: 'Simbabwe',
      nl: 'Zimbabwe',
    },
    alpha3: 'ZWE',
    countryConl: '716',
    name: 'ZW - Zimbabwe',
    dialCode: '+263',
  },
};

export const getCountrySelectOptions = (lang) => {
  const selectOptions = Object.keys(countries).map((code) => {
    return {
      label: countries[code].locales[lang],
      value: countries[code].name,
    };
  });

  return selectOptions.sort((a, b) => a.label.localeCompare(b.label));
};
