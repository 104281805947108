// Slug variables

// Set 'corporate' localized root slugs
const frCorporateSlug = 'entreprises';
const enCorporateSlug = 'corporate';
const esCorporateSlug = 'empresas';
const deCorporateSlug = 'unternehmen';
const nlCorporateSlug = 'bedrijven';

// Set 'destinations' localized root slugs
const frDestinationsSlug = 'destinations';
const enDestinationsSlug = 'destinations';
const esDestinationsSlug = 'destinos';
const deDestinationsSlug = 'ziellaender';
const nlDestinationsSlug = 'destinations';

// Set 'guide' localized slugs
const frGuideSlug = 'guide';
const enGuideSlug = 'guide';
const esGuideSlug = 'guia';
const deGuideSlug = 'guide';
const nlGuideSlug = 'gids';

// Set 'guides' localized slugs
const frGuidesSlug = 'guides';
const enGuidesSlug = 'guides';
const esGuidesSlug = 'guias';
const deGuidesSlug = 'leïtfaden';
const nlGuidesSlug = 'gidsen';

// Set 'all guides' localized slugs
const frAllGuidesSlug = 'tous-nos-guides';
const enAllGuidesSlug = 'all-our-guides';
const esAllGuidesSlug = 'todas-nuestras-guias';
const deAllGuidesSlug = 'alle-unsere-guides';
const nlAllGuidesSlug = 'alle-gidsen';

// Set 'taxonomy' localized slugs
const frTaxonomySlug = 'guides-thematiques';
const enTaxonomySlug = 'guides-thematiques';
const esTaxonomySlug = 'guides-thematiques';
const deTaxonomySlug = 'guides-thematiques';
const nlTaxonomySlug = 'guides-thematiques';

// Set 'insurance' localized slugs (guide when no offer)
const frInsuranceSlug = 'assurance';
const enInsuranceSlug = 'insurance';
const esInsuranceSlug = 'seguro';
const deInsuranceSlug = 'versicherungen';
const nlInsuranceSlug = 'verzekeringen';

// Set 'contact' localized slugs
const frContactSlug = 'contact';
const enContactSlug = 'contact';
const esContactSlug = 'contacto';
const deContactSlug = 'kontakt';
const nlContactSlug = 'contacten';

// Set 'news' localized slugs
const frNewsSlug = 'actualites';
const enNewsSlug = 'news';
const esNewsSlug = 'noticias';
const deNewsSlug = 'neuigkeiten';
const nlNewsSlug = 'nieuws';

// Set 'services' localized slugs
const frServicesSlug = 'services';
const enServicesSlug = 'services';
const esServicesSlug = 'servicios';
const deServicesSlug = 'serviceleistungen';
const nlServicesSlug = 'diensten';

// Set 'support' localized slugs
const frSupportSlug = 'aide';
const enSupportSlug = 'support';
const esSupportSlug = 'soporte';
const deSupportSlug = 'hilfe';
const nlSupportSlug = 'ondersteuning';

// Set 'about' localized slugs
const frAboutSlug = 'a-propos';
const enAboutSlug = 'about-april';
const esAboutSlug = 'acerca-de-april';
const deAboutSlug = 'ueber-april';
const nlAboutSlug = 'over-april';

// Set 'about' localized slugs
const frLegalSlug = 'legal';
const enLegalSlug = 'legal';
const esLegalSlug = 'legal';
const deLegalSlug = 'legal';
const nlLegalSlug = 'legal';

// Set 'faq' localized slugs
const frFaqSlug = 'faq';
const enFaqSlug = 'faq';
const esFaqSlug = 'preguntas-frecuentes';
const deFaqSlug = 'haeufig-gestellte-fragen';
const nlFaqSlug = 'veel-gestelde-vragen';

// Set 'offers' localized slugs
const frOffersSlug = 'assurances';
const enOffersSlug = 'plans';
const esOffersSlug = 'seguros';
const deOffersSlug = 'versicherungen';
const nlOffersSlug = 'verzekeringen';

// Set 'landing' localized slugs
const frLandingSlug = 'landing';
const enLandingSlug = 'landing';
const esLandingSlug = 'landing';
const deLandingSlug = 'landing';
const nlLandingSlug = 'landing';

// Set 'login' localized slugs
const frLoginSlug = 'connexion';
const enLoginSlug = 'login';
const esLoginSlug = 'iniciar-sesion';
const deLoginSlug = 'anmelden';
const nlLoginSlug = 'inloggen';

// Routes

export default {
  // Routes corporate
  'corporate/index': {
    fr: `/${frCorporateSlug}`,
    en: `/${enCorporateSlug}`,
    es: `/${esCorporateSlug}`,
    de: `/${deCorporateSlug}`,
    nl: `/${nlCorporateSlug}`,
  },
  'corporate/contact/index': {
    fr: `/${frCorporateSlug}/${frContactSlug}`,
    en: `/${enCorporateSlug}/${enContactSlug}`,
    es: `/${esCorporateSlug}/${esContactSlug}`,
    de: `/${deCorporateSlug}/${deContactSlug}`,
    nl: `/${nlCorporateSlug}/${nlContactSlug}`,
  },
  'corporate/contact/form/_uid': {
    fr: `/${frCorporateSlug}/${frContactSlug}/:uid`,
    en: `/${enCorporateSlug}/${enContactSlug}/:uid`,
    es: `/${esCorporateSlug}/${esContactSlug}/:uid`,
    de: `/${deCorporateSlug}/${deContactSlug}/:uid`,
    nl: `/${nlCorporateSlug}/${nlContactSlug}/:uid`,
  },
  'corporate/support/_uid/index': {
    fr: `/${frCorporateSlug}/${frSupportSlug}/:uid`,
    en: `/${enCorporateSlug}/${enSupportSlug}/:uid`,
    es: `/${esCorporateSlug}/${esSupportSlug}/:uid`,
    de: `/${deCorporateSlug}/${deSupportSlug}/:uid`,
    nl: `/${nlCorporateSlug}/${nlSupportSlug}/:uid`,
  },
  'corporate/support/_uid/_child/index': {
    fr: `/${frCorporateSlug}/${frSupportSlug}/:uid/:child`,
    en: `/${enCorporateSlug}/${enSupportSlug}/:uid/:child`,
    es: `/${esCorporateSlug}/${esSupportSlug}/:uid/:child`,
    de: `/${deCorporateSlug}/${deSupportSlug}/:uid/:child`,
    nl: `/${nlCorporateSlug}/${nlSupportSlug}/:uid/:child`,
  },
  'corporate/services/_uid/index': {
    fr: `/${frCorporateSlug}/${frServicesSlug}/:uid`,
    en: `/${enCorporateSlug}/${enServicesSlug}/:uid`,
    es: `/${esCorporateSlug}/${esServicesSlug}/:uid`,
    de: `/${deCorporateSlug}/${deServicesSlug}/:uid`,
    nl: `/${nlCorporateSlug}/${nlServicesSlug}/:uid`,
  },
  'corporate/services/_uid/_child/index': {
    fr: `/${frCorporateSlug}/${frServicesSlug}/:uid/:child`,
    en: `/${enCorporateSlug}/${enServicesSlug}/:uid/:child`,
    es: `/${esCorporateSlug}/${esServicesSlug}/:uid/:child`,
    de: `/${deCorporateSlug}/${deServicesSlug}/:uid/:child`,
    nl: `/${nlCorporateSlug}/${nlServicesSlug}/:uid/:child`,
  },
  'corporate/support/faq/faqs': {
    fr: `/${frCorporateSlug}/${frSupportSlug}/${frFaqSlug}`,
    en: `/${enCorporateSlug}/${enSupportSlug}/${enFaqSlug}`,
    es: `/${esCorporateSlug}/${esSupportSlug}/${esFaqSlug}`,
    de: `/${deCorporateSlug}/${deSupportSlug}/${deFaqSlug}`,
    nl: `/${nlCorporateSlug}/${nlSupportSlug}/${nlFaqSlug}`,
  },
  'corporate/support/faq/_faq/index': {
    fr: `/${frCorporateSlug}/${frSupportSlug}/${frFaqSlug}/:faq`,
    en: `/${enCorporateSlug}/${enSupportSlug}/${enFaqSlug}/:faq`,
    es: `/${esCorporateSlug}/${esSupportSlug}/${esFaqSlug}/:faq`,
    de: `/${deCorporateSlug}/${deSupportSlug}/${deFaqSlug}/:faq`,
    nl: `/${nlCorporateSlug}/${nlSupportSlug}/${nlFaqSlug}/:faq`,
  },
  'corporate/about/_uid/index': {
    fr: `/${frCorporateSlug}/${frAboutSlug}/:uid`,
    en: `/${enCorporateSlug}/${enAboutSlug}/:uid`,
    es: `/${esCorporateSlug}/${esAboutSlug}/:uid`,
    de: `/${deCorporateSlug}/${deAboutSlug}/:uid`,
    nl: `/${nlCorporateSlug}/${nlAboutSlug}/:uid`,
  },
  'corporate/about/_uid/_child/index': {
    fr: `/${frCorporateSlug}/${frAboutSlug}/:uid/:child`,
    en: `/${enCorporateSlug}/${enAboutSlug}/:uid/:child`,
    es: `/${esCorporateSlug}/${esAboutSlug}/:uid/:child`,
    de: `/${deCorporateSlug}/${deAboutSlug}/:uid/:child`,
    nl: `/${nlCorporateSlug}/${nlAboutSlug}/:uid/:child`,
  },
  'corporate/legal/_uid/index': {
    fr: `/${frCorporateSlug}/${frLegalSlug}/:uid`,
    en: `/${enCorporateSlug}/${enLegalSlug}/:uid`,
    es: `/${esCorporateSlug}/${esLegalSlug}/:uid`,
    de: `/${deCorporateSlug}/${deLegalSlug}/:uid`,
    nl: `/${nlCorporateSlug}/${nlLegalSlug}/:uid`,
  },
  'corporate/legal/_uid/_child/index': {
    fr: `/${frCorporateSlug}/${frLegalSlug}/:uid/:child`,
    en: `/${enCorporateSlug}/${enLegalSlug}/:uid/:child`,
    es: `/${esCorporateSlug}/${esLegalSlug}/:uid/:child`,
    de: `/${deCorporateSlug}/${deLegalSlug}/:uid/:child`,
    nl: `/${nlCorporateSlug}/${nlLegalSlug}/:uid/:child`,
  },
  'corporate/about/news/index': {
    fr: `/${frCorporateSlug}/${frAboutSlug}/${frNewsSlug}`,
    en: `/${enCorporateSlug}/${enAboutSlug}/${enNewsSlug}`,
    es: `/${esCorporateSlug}/${esAboutSlug}/${esNewsSlug}`,
    de: `/${deCorporateSlug}/${deAboutSlug}/${deNewsSlug}`,
    nl: `/${nlCorporateSlug}/${nlAboutSlug}/${nlNewsSlug}`,
  },
  'corporate/about/news/_article/index': {
    fr: `/${frCorporateSlug}/${frAboutSlug}/${frNewsSlug}/:article`,
    en: `/${enCorporateSlug}/${enAboutSlug}/${enNewsSlug}/:article`,
    es: `/${esCorporateSlug}/${esAboutSlug}/${esNewsSlug}/:article`,
    de: `/${deCorporateSlug}/${deAboutSlug}/${deNewsSlug}/:article`,
    nl: `/${nlCorporateSlug}/${nlAboutSlug}/${nlNewsSlug}/:article`,
  },
  'corporate/_offer/index': {
    fr: `/${frCorporateSlug}/:offer`,
    en: `/${enCorporateSlug}/:offer`,
    es: `/${esCorporateSlug}/:offer`,
    de: `/${deCorporateSlug}/:offer`,
    nl: `/${nlCorporateSlug}/:offer`,
  },
  'corporate/guide/index': {
    fr: `/${frCorporateSlug}/${frInsuranceSlug}/${frGuideSlug}`,
    en: `/${enCorporateSlug}/${enInsuranceSlug}/${enGuideSlug}`,
    es: `/${esCorporateSlug}/${esInsuranceSlug}/${esGuideSlug}`,
    de: `/${deCorporateSlug}/${deInsuranceSlug}/${deGuideSlug}`,
    nl: `/${nlCorporateSlug}/${nlInsuranceSlug}/${nlGuideSlug}`,
  },
  'corporate/guide/_uid': {
    fr: `/${frCorporateSlug}/${frInsuranceSlug}/${frGuideSlug}/:uid`,
    en: `/${enCorporateSlug}/${enInsuranceSlug}/${enGuideSlug}/:uid`,
    es: `/${esCorporateSlug}/${esInsuranceSlug}/${esGuideSlug}/:uid`,
    de: `/${deCorporateSlug}/${deInsuranceSlug}/${deGuideSlug}/:uid`,
    nl: `/${nlCorporateSlug}/${nlInsuranceSlug}/${nlGuideSlug}/:uid`,
  },
  'corporate/_offer/guide/index': {
    fr: `/${frCorporateSlug}/:offer/${frGuideSlug}`,
    en: `/${enCorporateSlug}/:offer/${enGuideSlug}`,
    es: `/${esCorporateSlug}/:offer/${esGuideSlug}`,
    de: `/${deCorporateSlug}/:offer/${deGuideSlug}`,
    nl: `/${nlCorporateSlug}/:offer/${nlGuideSlug}`,
  },
  'corporate/_offer/guide/_uid': {
    fr: `/${frCorporateSlug}/:offer/${frGuideSlug}/:uid`,
    en: `/${enCorporateSlug}/:offer/${enGuideSlug}/:uid`,
    es: `/${esCorporateSlug}/:offer/${esGuideSlug}/:uid`,
    de: `/${deCorporateSlug}/:offer/${deGuideSlug}/:uid`,
    nl: `/${nlCorporateSlug}/:offer/${nlGuideSlug}/:uid`,
  },
  'corporate/guides-sitemap/index': {
    fr: `/${frCorporateSlug}/${frAllGuidesSlug}`,
    en: `/${enCorporateSlug}/${enAllGuidesSlug}`,
    es: `/${esCorporateSlug}/${esAllGuidesSlug}`,
    de: `/${deCorporateSlug}/${deAllGuidesSlug}`,
    nl: `/${nlCorporateSlug}/${nlAllGuidesSlug}`,
  },
  'corporate/taxonomy/index': {
    fr: `/${frCorporateSlug}/${frTaxonomySlug}`,
    en: `/${enCorporateSlug}/${enTaxonomySlug}`,
    es: `/${esCorporateSlug}/${esTaxonomySlug}`,
    de: `/${deCorporateSlug}/${deTaxonomySlug}`,
    nl: `/${nlCorporateSlug}/${nlTaxonomySlug}`,
  },
  'corporate/taxonomy/_uid': {
    fr: `/${frCorporateSlug}/${frGuidesSlug}/:uid`,
    en: `/${enCorporateSlug}/${enGuidesSlug}/:uid`,
    es: `/${esCorporateSlug}/${esGuidesSlug}/:uid`,
    de: `/${deCorporateSlug}/${deGuidesSlug}/:uid`,
    nl: `/${nlCorporateSlug}/${nlGuidesSlug}/:uid`,
  },
  'corporate/guides-themes/index': {
    fr: `/${frCorporateSlug}/${frGuidesSlug}`,
    en: `/${enCorporateSlug}/${enGuidesSlug}`,
    es: `/${esCorporateSlug}/${esGuidesSlug}`,
    de: `/${deCorporateSlug}/${deGuidesSlug}`,
    nl: `/${nlCorporateSlug}/${nlGuidesSlug}`,
  },
  'corporate/_offer/_product/index': {
    fr: `/${frCorporateSlug}/:offer/:product/`,
    en: `/${enCorporateSlug}/:offer/:product/`,
    es: `/${esCorporateSlug}/:offer/:product/`,
    de: `/${deCorporateSlug}/:offer/:product/`,
    nl: `/${nlCorporateSlug}/:offer/:product/`,
  },
  'corporate/_offer/_product/_form/index': {
    fr: `/${frCorporateSlug}/:offer/:product/:form`,
    en: `/${enCorporateSlug}/:offer/:product/:form`,
    es: `/${esCorporateSlug}/:offer/:product/:form`,
    de: `/${deCorporateSlug}/:offer/:product/:form`,
    nl: `/${nlCorporateSlug}/:offer/:product/:form`,
  },
  'corporate/regions/destinations': {
    fr: `/${frCorporateSlug}/${frDestinationsSlug}`,
    en: `/${enCorporateSlug}/${enDestinationsSlug}`,
    es: `/${esCorporateSlug}/${esDestinationsSlug}`,
    de: `/${deCorporateSlug}/${deDestinationsSlug}`,
    nl: `/${nlCorporateSlug}/${nlDestinationsSlug}`,
  },
  'corporate/regions/_region/index': {
    fr: `/${frCorporateSlug}/${frDestinationsSlug}/:region`,
    en: `/${enCorporateSlug}/${enDestinationsSlug}/:region`,
    es: `/${esCorporateSlug}/${esDestinationsSlug}/:region`,
    de: `/${deCorporateSlug}/${deDestinationsSlug}/:region`,
    nl: `/${nlCorporateSlug}/${nlDestinationsSlug}/:region`,
  },
  'corporate/regions/_region/_country/index': {
    fr: `/${frCorporateSlug}/${frDestinationsSlug}/:region/:country`,
    en: `/${enCorporateSlug}/${enDestinationsSlug}/:region/:country`,
    es: `/${esCorporateSlug}/${esDestinationsSlug}/:region/:country`,
    de: `/${deCorporateSlug}/${deDestinationsSlug}/:region/:country`,
    nl: `/${nlCorporateSlug}/${nlDestinationsSlug}/:region/:country`,
  },
  'corporate/landing/_uid': {
    fr: `/${frCorporateSlug}/${frLandingSlug}/:uid`,
    en: `/${enCorporateSlug}/${enLandingSlug}/:uid`,
    es: `/${esCorporateSlug}/${esLandingSlug}/:uid`,
    de: `/${deCorporateSlug}/${deLandingSlug}/:uid`,
    nl: `/${nlCorporateSlug}/${nlLandingSlug}/:uid`,
  },
  'corporate/login/index': {
    fr: `/${frCorporateSlug}/${frLoginSlug}`,
    en: `/${enCorporateSlug}/${enLoginSlug}`,
    es: `/${esCorporateSlug}/${esLoginSlug}`,
    de: `/${deCorporateSlug}/${deLoginSlug}`,
    nl: `/${nlCorporateSlug}/${nlLoginSlug}`,
  },
  // Routes individual
  index: {
    fr: '/',
    en: '/',
    es: '/',
    de: '/',
    nl: '/',
  },
  'contact/index': {
    fr: `/${frContactSlug}`,
    en: `/${enContactSlug}`,
    es: `/${esContactSlug}`,
    de: `/${deContactSlug}`,
    nl: `/${nlContactSlug}`,
  },
  'contact/form/_uid': {
    fr: `/${frContactSlug}/:uid`,
    en: `/${enContactSlug}/:uid`,
    es: `/${esContactSlug}/:uid`,
    de: `/${deContactSlug}/:uid`,
    nl: `/${nlContactSlug}/:uid`,
  },
  'offers/index': {
    fr: `/${frOffersSlug}`,
    en: `/${enOffersSlug}`,
    es: `/${esOffersSlug}`,
    de: `/${deOffersSlug}`,
    nl: `/${nlOffersSlug}`,
  },
  'guide/index': {
    fr: `/${frInsuranceSlug}/${frGuideSlug}`,
    en: `/${enInsuranceSlug}/${enGuideSlug}`,
    es: `/${esInsuranceSlug}/${esGuideSlug}`,
    de: `/${deInsuranceSlug}/${deGuideSlug}`,
    nl: `/${nlInsuranceSlug}/${nlGuideSlug}`,
  },
  'guide/_uid': {
    fr: `/${frInsuranceSlug}/${frGuideSlug}/:uid`,
    en: `/${enInsuranceSlug}/${enGuideSlug}/:uid`,
    es: `/${esInsuranceSlug}/${esGuideSlug}/:uid`,
    de: `/${deInsuranceSlug}/${deGuideSlug}/:uid`,
    nl: `/${nlInsuranceSlug}/${nlGuideSlug}/:uid`,
  },
  '_offer/guide/index': {
    fr: `/:offer/${frGuideSlug}`,
    en: `/:offer/${enGuideSlug}`,
    es: `/:offer/${esGuideSlug}`,
    de: `/:offer/${deGuideSlug}`,
    nl: `/:offer/${nlGuideSlug}`,
  },
  '_offer/guide/_uid': {
    fr: `/:offer/${frGuideSlug}/:uid`,
    en: `/:offer/${enGuideSlug}/:uid`,
    es: `/:offer/${esGuideSlug}/:uid`,
    de: `/:offer/${deGuideSlug}/:uid`,
    nl: `/:offer/${nlGuideSlug}/:uid`,
  },
  'guides-sitemap/index': {
    fr: `/${frAllGuidesSlug}`,
    en: `/${enAllGuidesSlug}`,
    es: `/${esAllGuidesSlug}`,
    de: `/${deAllGuidesSlug}`,
    nl: `/${nlAllGuidesSlug}`,
  },
  'taxonomy/index': {
    fr: `/${frTaxonomySlug}`,
    en: `/${enTaxonomySlug}`,
    es: `/${esTaxonomySlug}`,
    de: `/${deTaxonomySlug}`,
    nl: `/${nlTaxonomySlug}`,
  },
  'taxonomy/_uid': {
    fr: `/${frGuidesSlug}/:uid`,
    en: `/${enGuidesSlug}/:uid`,
    es: `/${esGuidesSlug}/:uid`,
    de: `/${deGuidesSlug}/:uid`,
    nl: `/${nlGuidesSlug}/:uid`,
  },
  'guides-themes/index': {
    fr: `/${frGuidesSlug}`,
    en: `/${enGuidesSlug}`,
    es: `/${esGuidesSlug}`,
    de: `/${deGuidesSlug}`,
    nl: `/${nlGuidesSlug}`,
  },
  '_offer/_product/index': {
    fr: `/:offer/:product/`,
    en: `/:offer/:product/`,
    es: `/:offer/:product/`,
    de: `/:offer/:product/`,
    nl: `/:offer/:product/`,
  },
  '_offer/_product/_form/index': {
    fr: `/:offer/:product/:form`,
    en: `/:offer/:product/:form`,
    es: `/:offer/:product/:form`,
    de: `/:offer/:product/:form`,
    nl: `/:offer/:product/:form`,
  },
  'support/_uid/index': {
    fr: `/${frSupportSlug}/:uid`,
    en: `/${enSupportSlug}/:uid`,
    es: `/${esSupportSlug}/:uid`,
    de: `/${deSupportSlug}/:uid`,
    nl: `/${nlSupportSlug}/:uid`,
  },
  'support/_uid/_child/index': {
    fr: `/${frSupportSlug}/:uid/:child`,
    en: `/${enSupportSlug}/:uid/:child`,
    es: `/${esSupportSlug}/:uid/:child`,
    de: `/${deSupportSlug}/:uid/:child`,
    nl: `/${nlSupportSlug}/:uid/:child`,
  },
  'services/_uid/index': {
    fr: `/${frServicesSlug}/:uid`,
    en: `/${enServicesSlug}/:uid`,
    es: `/${esServicesSlug}/:uid`,
    de: `/${deServicesSlug}/:uid`,
    nl: `/${nlServicesSlug}/:uid`,
  },
  'services/_uid/_child/index': {
    fr: `/${frServicesSlug}/:uid/:child`,
    en: `/${enServicesSlug}/:uid/:child`,
    es: `/${esServicesSlug}/:uid/:child`,
    de: `/${deServicesSlug}/:uid/:child`,
    nl: `/${nlServicesSlug}/:uid/:child`,
  },
  'legal/_uid/index': {
    fr: `/${frLegalSlug}/:uid`,
    en: `/${enLegalSlug}/:uid`,
    es: `/${esLegalSlug}/:uid`,
    de: `/${deLegalSlug}/:uid`,
    nl: `/${nlLegalSlug}/:uid`,
  },
  'legal/_uid/_child/index': {
    fr: `/${frLegalSlug}/:uid/:child`,
    en: `/${enLegalSlug}/:uid/:child`,
    es: `/${esLegalSlug}/:uid/:child`,
    de: `/${deLegalSlug}/:uid/:child`,
    nl: `/${nlLegalSlug}/:uid/:child`,
  },
  'about/_uid/index': {
    fr: `/${frAboutSlug}/:uid`,
    en: `/${enAboutSlug}/:uid`,
    es: `/${esAboutSlug}/:uid`,
    de: `/${deAboutSlug}/:uid`,
    nl: `/${nlAboutSlug}/:uid`,
  },
  'about/_uid/_child/index': {
    fr: `/${frAboutSlug}/:uid/:child`,
    en: `/${enAboutSlug}/:uid/:child`,
    es: `/${esAboutSlug}/:uid/:child`,
    de: `/${deAboutSlug}/:uid/:child`,
    nl: `/${nlAboutSlug}/:uid/:child`,
  },
  'about/news/index': {
    fr: `/${frAboutSlug}/${frNewsSlug}`,
    en: `/${enAboutSlug}/${enNewsSlug}`,
    es: `/${esAboutSlug}/${esNewsSlug}`,
    de: `/${deAboutSlug}/${deNewsSlug}`,
    nl: `/${nlAboutSlug}/${nlNewsSlug}`,
  },
  'about/news/_article/index': {
    fr: `/${frAboutSlug}/${frNewsSlug}/:article`,
    en: `/${enAboutSlug}/${enNewsSlug}/:article`,
    es: `/${esAboutSlug}/${esNewsSlug}/:article`,
    de: `/${deAboutSlug}/${deNewsSlug}/:article`,
    nl: `/${nlAboutSlug}/${nlNewsSlug}/:article`,
  },
  'support/faq/faqs': {
    fr: `/${frSupportSlug}/${frFaqSlug}`,
    en: `/${enSupportSlug}/${enFaqSlug}`,
    es: `/${esSupportSlug}/${esFaqSlug}`,
    de: `/${deSupportSlug}/${deFaqSlug}`,
    nl: `/${nlSupportSlug}/${nlFaqSlug}`,
  },
  'support/faq/_faq/index': {
    fr: `/${frSupportSlug}/${frFaqSlug}/:faq`,
    en: `/${enSupportSlug}/${enFaqSlug}/:faq`,
    es: `/${esSupportSlug}/${esFaqSlug}/:faq`,
    de: `/${deSupportSlug}/${deFaqSlug}/:faq`,
    nl: `/${nlSupportSlug}/${nlFaqSlug}/:faq`,
  },
  'regions/destinations': {
    fr: `/${frDestinationsSlug}`,
    en: `/${enDestinationsSlug}`,
    es: `/${esDestinationsSlug}`,
    de: `/${deDestinationsSlug}`,
    nl: `/${nlDestinationsSlug}`,
  },
  'regions/_region/index': {
    fr: `/${frDestinationsSlug}/:region`,
    en: `/${enDestinationsSlug}/:region`,
    es: `/${esDestinationsSlug}/:region`,
    de: `/${deDestinationsSlug}/:region`,
    nl: `/${nlDestinationsSlug}/:region`,
  },
  'regions/_region/_country/index': {
    fr: `/${frDestinationsSlug}/:region/:country`,
    en: `/${enDestinationsSlug}/:region/:country`,
    es: `/${esDestinationsSlug}/:region/:country`,
    de: `/${deDestinationsSlug}/:region/:country`,
    nl: `/${nlDestinationsSlug}/:region/:country`,
  },
  'landing/_uid': {
    fr: `/${frLandingSlug}/:uid`,
    en: `/${enLandingSlug}/:uid`,
    es: `/${esLandingSlug}/:uid`,
    de: `/${deLandingSlug}/:uid`,
    nl: `/${nlLandingSlug}/:uid`,
  },
  'login/index': {
    fr: `/${frLoginSlug}`,
    en: `/${enLoginSlug}`,
    es: `/${esLoginSlug}`,
    de: `/${deLoginSlug}`,
    nl: `/${nlLoginSlug}`,
  },
  slice_simulator: false,
  api: false,
};

// helpers

export const getCorporateRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esCorporateSlug;
    case 'de':
      return deCorporateSlug;
    case 'nl':
      return nlCorporateSlug;
    case 'fr':
      return frCorporateSlug;
    case 'en':
    default:
      return enCorporateSlug;
  }
};

export const getNewsRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esNewsSlug;
    case 'de':
      return deNewsSlug;
    case 'nl':
      return nlNewsSlug;
    case 'fr':
      return frNewsSlug;
    case 'en':
    default:
      return enNewsSlug;
  }
};

export const getGuideRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esGuideSlug;
    case 'de':
      return deGuideSlug;
    case 'nl':
      return nlGuideSlug;
    case 'fr':
      return frGuideSlug;
    case 'en':
    default:
      return enGuideSlug;
  }
};

export const getTaxonomyRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esGuidesSlug;
    case 'de':
      return deGuidesSlug;
    case 'nl':
      return nlGuidesSlug;
    case 'fr':
      return frGuidesSlug;
    case 'en':
    default:
      return enGuidesSlug;
  }
};

export const getAllGuidesRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esAllGuidesSlug;
    case 'de':
      return deAllGuidesSlug;
    case 'nl':
      return nlAllGuidesSlug;
    case 'fr':
      return frAllGuidesSlug;
    case 'en':
    default:
      return enAllGuidesSlug;
  }
};

export const getAllGuidesThemesRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esGuidesSlug;
    case 'de':
      return deGuidesSlug;
    case 'nl':
      return nlGuidesSlug;
    case 'fr':
      return frGuidesSlug;
    case 'en':
    default:
      return enGuidesSlug;
  }
};

export const getFaqRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esFaqSlug;
    case 'de':
      return deFaqSlug;
    case 'nl':
      return nlFaqSlug;
    case 'fr':
      return frFaqSlug;
    case 'en':
    default:
      return enFaqSlug;
  }
};

export const getSupportRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esSupportSlug;
    case 'de':
      return deSupportSlug;
    case 'nl':
      return nlSupportSlug;
    case 'fr':
      return frSupportSlug;
    case 'en':
    default:
      return enSupportSlug;
  }
};

export const getServicesRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esServicesSlug;
    case 'de':
      return deServicesSlug;
    case 'nl':
      return nlServicesSlug;
    case 'fr':
      return frServicesSlug;
    case 'en':
    default:
      return enServicesSlug;
  }
};

export const getLegalRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esLegalSlug;
    case 'de':
      return deLegalSlug;
    case 'nl':
      return nlLegalSlug;
    case 'fr':
      return frLegalSlug;
    case 'en':
    default:
      return enLegalSlug;
  }
};

export const getAboutRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esAboutSlug;
    case 'de':
      return deAboutSlug;
    case 'nl':
      return nlAboutSlug;
    case 'fr':
      return frAboutSlug;
    case 'en':
    default:
      return enAboutSlug;
  }
};

export const getContactRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esContactSlug;
    case 'de':
      return deContactSlug;
    case 'nl':
      return nlContactSlug;
    case 'fr':
      return frContactSlug;
    case 'en':
    default:
      return enContactSlug;
  }
};

export const getDestinationsRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esDestinationsSlug;
    case 'de':
      return deDestinationsSlug;
    case 'nl':
      return nlDestinationsSlug;
    case 'fr':
      return frDestinationsSlug;
    case 'en':
    default:
      return enDestinationsSlug;
  }
};

export const getOffersRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esOffersSlug;
    case 'de':
      return deOffersSlug;
    case 'nl':
      return nlOffersSlug;
    case 'fr':
      return frOffersSlug;
    case 'en':
    default:
      return enOffersSlug;
  }
};

export const getLoginRoot = (lang) => {
  switch (lang) {
    case 'es':
      return esLoginSlug;
    case 'de':
      return deLoginSlug;
    case 'nl':
      return nlLoginSlug;
    case 'fr':
      return frLoginSlug;
    case 'en':
    default:
      return enLoginSlug;
  }
};

export const getLandingRoot = (lang) => {
  switch (lang) {
    case 'en':
      return enLandingSlug;
    case 'es':
      return esLandingSlug;
    case 'de':
      return deLandingSlug;
    case 'nl':
      return nlLandingSlug;
    case 'fr':
    default:
      return frLandingSlug;
  }
};

export const getInsuranceRoot = (lang) => {
  switch (lang) {
    case 'en':
      return enInsuranceSlug;

    case 'es':
      return esInsuranceSlug;
    case 'de':
      return deInsuranceSlug;
    case 'nl':
      return nlInsuranceSlug;
    case 'fr':
      return frInsuranceSlug;
    default:
      return enInsuranceSlug;
  }
};
