import navSanitizer from './nav-sanitizer';

export default (content, { i18n, $enhancedLinkSerializer, route }) => {
  const { data } = content;

  const privateLink =
    data.link_private && $enhancedLinkSerializer(data.link_private);
  const proLink =
    data.link_professional && $enhancedLinkSerializer(data.link_professional);

  // Switcher
  const switcher =
    proLink && privateLink
      ? [
          {
            label: i18n.t('header.switcher.private'),
            cta: privateLink,
            theme: 'individual',
            current: !data.is_pro,
          },
          {
            label: i18n.t('header.switcher.professional'),
            cta: proLink,
            theme: 'corporate',
            current: data.is_pro,
          },
        ]
      : null;

  // Action CTAs
  const actions = data.banner_actions.map((item) => {
    const isModuleLisa = item.action_link?.type === 'module_lisa';
    return {
      isDesktopIconWithLabel: item.action_desktop_icon_with_label,
      mobileVisibility: item.action_mobile_visibility,
      link: $enhancedLinkSerializer(item.action_link),
      label: item.action_label,
      icon: item.action_icon,
      variant: item.action_variant,
      actionPanel: item.action_link_panel,
      lisaUId: isModuleLisa ? item.action_link?.uid : null,
    };
  });

  const secondaryLinks = data.secondary_links.map((item) => {
    return {
      label: item.label,
      link: $enhancedLinkSerializer(item.link),
    };
  });

  // Navigation
  const nav = navSanitizer({ i18n, route, $enhancedLinkSerializer }, content);
  return {
    rootLink: data.is_pro ? proLink : privateLink,
    switcher,
    menuTitle: data.menu_title,
    nav,
    actions,
    secondaryLinks,
    variant: 'international',
    showLanguageSwitcher: data.show_language_switcher ?? false,
    languagesSwitcher: {
      languages: content.languages,
    },
  };
};
