import getHeadSeo from '@/utils/seo';
import getJSONLD from '@/utils/structured-data';
import { defaultAccount as ekomiAccount } from '~/utils/ekomi';

export default (context, scripts = [], microdataOptions = {}, meta = []) => {
  const { $store, $i18n, url, $route, $config } = context;
  const layout = $store.state.layout;
  const ekomi = context.$store.state.ekomi;
  const baseUrl = $store.state.settings.root.baseUrl;
  const locale = $i18n.localeProperties.code;
  const headData = {
    ...$i18n.t('configs').head,
    ...layout.head,
    altLanguages: layout.altLanguages,
  };
  const defaultlocale = $i18n.defaultLocale || 'en';

  const path = url || $route.path;
  const noIndexConfig = $config.noIndex;

  const head = getHeadSeo(
    headData,
    defaultlocale,
    locale,
    path,
    baseUrl,
    noIndexConfig
  );
  head?.meta?.push(...meta);

  if (microdataOptions?.hasOrganization) {
    scripts.push(
      getJSONLD('organization', {
        ...layout.footer.data.seo,
        context,
        url: baseUrl,
        reviews: ekomi.reviews[ekomiAccount],
      })
    );
  }

  return {
    ...head,
    script: [
      ...scripts,
      getJSONLD('webpage', {
        ...headData,
        ...layout.footer.data.seo,
        context,
        url: baseUrl,
      }),
      getJSONLD('website', {
        url: baseUrl,
      }),
      // Add external head scripts here
    ],
    __dangerouslyDisableSanitizersByTagID: {
      // Add script ids to disable sanitizer
      // 'your-script-id': ['innerHTML'],
    },
  };
};
