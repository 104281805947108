// Code generated by Slice Machine. DO NOT EDIT.

export const components = {
  accordion_push: () =>
    import(
      /* webpackChunkName: "prismic__slices__accordion_push" */ './AccordionPush/index.vue'
    ),
  advantages_push: () =>
    import(
      /* webpackChunkName: "prismic__slices__advantages_push" */ './AdvantagesPush/index.vue'
    ),
  all_guides: () =>
    import(
      /* webpackChunkName: "prismic__slices__all_guides" */ './AllGuides/index.vue'
    ),
  article_head: () =>
    import(
      /* webpackChunkName: "prismic__slices__article_head" */ './ArticleHead/index.vue'
    ),
  banner_push: () =>
    import(
      /* webpackChunkName: "prismic__slices__banner_push" */ './BannerPush/index.vue'
    ),
  breadcrumbs: () =>
    import(
      /* webpackChunkName: "prismic__slices__breadcrumbs" */ './Breadcrumbs/index.vue'
    ),
  cards_list: () =>
    import(
      /* webpackChunkName: "prismic__slices__cards_list" */ './CardsList/index.vue'
    ),
  contact_configurator: () =>
    import(
      /* webpackChunkName: "prismic__slices__contact_configurator" */ './ContactConfigurator/index.vue'
    ),
  contact_push: () =>
    import(
      /* webpackChunkName: "prismic__slices__contact_push" */ './ContactPush/index.vue'
    ),
  cover_types: () =>
    import(
      /* webpackChunkName: "prismic__slices__cover_types" */ './CoverTypes/index.vue'
    ),
  ctas_list: () =>
    import(
      /* webpackChunkName: "prismic__slices__ctas_list" */ './CtasList/index.vue'
    ),
  data_viz: () =>
    import(
      /* webpackChunkName: "prismic__slices__data_viz" */ './DataViz/index.vue'
    ),
  documents_list: () =>
    import(
      /* webpackChunkName: "prismic__slices__documents_list" */ './DocumentsList/index.vue'
    ),
  edit_rich_text: () =>
    import(
      /* webpackChunkName: "prismic__slices__edit_rich_text" */ './EditoRichText/index.vue'
    ),
  edito_carousel: () =>
    import(
      /* webpackChunkName: "prismic__slices__edito_carousel" */ './EditoCarousel/index.vue'
    ),
  edito_cta: () =>
    import(
      /* webpackChunkName: "prismic__slices__edito_cta" */ './EditoCta/index.vue'
    ),
  edito_image: () =>
    import(
      /* webpackChunkName: "prismic__slices__edito_image" */ './EditoImage/index.vue'
    ),
  edito_table: () =>
    import(
      /* webpackChunkName: "prismic__slices__edito_table" */ './EditoTable/index.vue'
    ),
  edito_table_row: () =>
    import(
      /* webpackChunkName: "prismic__slices__edito_table_row" */ './EditoTableRow/index.vue'
    ),
  featured_block: () =>
    import(
      /* webpackChunkName: "prismic__slices__featured_block" */ './FeaturedBlock/index.vue'
    ),
  form: () =>
    import(/* webpackChunkName: "prismic__slices__form" */ './Form/index.vue'),
  guides: () =>
    import(
      /* webpackChunkName: "prismic__slices__guides" */ './Guides/index.vue'
    ),
  hero: () =>
    import(/* webpackChunkName: "prismic__slices__hero" */ './Hero/index.vue'),
  legal_mentions: () =>
    import(
      /* webpackChunkName: "prismic__slices__legal_mentions" */ './LegalMentions/index.vue'
    ),
  lisa_content: () =>
    import(
      /* webpackChunkName: "prismic__slices__lisa_content" */ './LisaContent/index.vue'
    ),
  mosaic_push: () =>
    import(
      /* webpackChunkName: "prismic__slices__mosaic_push" */ './MosaicPush/index.vue'
    ),
  nav_item: () =>
    import(
      /* webpackChunkName: "prismic__slices__nav_item" */ './NavItem/index.vue'
    ),
  nav_tabs: () =>
    import(
      /* webpackChunkName: "prismic__slices__nav_tabs" */ './NavTabs/index.vue'
    ),
  picto_carousel: () =>
    import(
      /* webpackChunkName: "prismic__slices__picto_carousel" */ './PictoCarousel/index.vue'
    ),
  products_list: () =>
    import(
      /* webpackChunkName: "prismic__slices__products_list" */ './ProductsList/index.vue'
    ),
  quote: () =>
    import(
      /* webpackChunkName: "prismic__slices__quote" */ './Quote/index.vue'
    ),
  rating_push: () =>
    import(
      /* webpackChunkName: "prismic__slices__rating_push" */ './RatingPush/index.vue'
    ),
  related_guides: () =>
    import(
      /* webpackChunkName: "prismic__slices__related_guides" */ './RelatedGuides/index.vue'
    ),
  seo_push: () =>
    import(
      /* webpackChunkName: "prismic__slices__seo_push" */ './SeoPush/index.vue'
    ),
  taxonomy_guides: () =>
    import(
      /* webpackChunkName: "prismic__slices__taxonomy_guides" */ './TaxonomyGuides/index.vue'
    ),
  youtube_video: () =>
    import(
      /* webpackChunkName: "prismic__slices__youtube_video" */ './YoutubeVideo/index.vue'
    ),
};
