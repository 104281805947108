
import {
  buildFluidSrcSet,
  buildFixedSrcSet,
  buildSingleSrcSet,
  commonProps,
} from './utils';

export default {
  props: {
    ...commonProps,
    lazy: {
      type: Boolean,
      required: false,
      default: true,
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    height: {
      type: Number,
      required: true,
      default: null,
    },
    width: {
      type: Number,
      required: true,
      default: null,
    },
  },
  computed: {
    isFixed() {
      return this.$parent.fixed || this.fixed;
    },
    isLazy() {
      return (
        (typeof this.$parent.lazy !== 'undefined' && this.$parent.lazy) ||
        this.lazy
      );
    },
    // whether we use the src (single file) attribute or srcset (set with multiple sizes)
    hasSingleSrc() {
      return (
        this.$parent.singleSrc || this.singleSrc || this.src.includes('.svg')
      );
    },
    src() {
      if (!this.filename) {
        return null;
      }

      return buildSingleSrcSet(this.filename);
    },
    srcset() {
      if (!this.filename) {
        return null;
      }

      if (this.hasSingleSrc) {
        return buildSingleSrcSet(
          this.filename,
          this.$parent.width || this.width
        );
      }

      return this.isFixed
        ? buildFixedSrcSet(this.filename, this.$parent.width || this.width)
        : buildFluidSrcSet(this.filename, this.srcSetWidths);
    },
  },
};
