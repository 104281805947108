import { SETTINGS_SET_LOCALES, SETTINGS_SET_BASE_URL } from './mutation-types';

export const state = () => ({
  languages: {},
  root: {},
});

export const mutations = {
  // languages
  [SETTINGS_SET_LOCALES](mutationState, data) {
    mutationState.languages = data;
  },
  // domain
  [SETTINGS_SET_BASE_URL](mutationState, data) {
    mutationState.root = data;
  },
};
