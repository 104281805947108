import axios from 'axios';

export default async (websiteLocale = 'US') => {
  if (!localStorage) {
    return null;
  }
  const countryCode = localStorage.getItem('country-code');

  if (countryCode) {
    return countryCode;
  }

  const { data } = await axios.get('/api/geolocation', {
    headers: {
      Accept: 'application/json',
      'Accept-Encoding': 'identity',
    },
  });

  const value = data.country || websiteLocale;
  localStorage.setItem('country-code', value);

  return value;
};
