import { onLoad } from '~/helpers/onLoad';

export default (context) => {
  onLoad(() => {
    const { axeptioClientId } = context.$config;
    const locale = context.i18n?.localeProperties.code || 'en';

    window.axeptioSettings = {
      clientId: axeptioClientId,
      userCookiesDomain: 'april-international.com',
      userCookiesDuration: 180, // 6 mois
      cookiesVersion: locale?.toUpperCase(),
    };

    const script = document.createElement('script');
    script.src = 'https://static.axept.io/sdk-slim.js';
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);
  }, 1000);
};
