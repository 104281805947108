import { stripRichTextWrapperTag } from '@/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';
import {
  lisaLinks,
  coverTypeLinks,
  routerLinks,
  productLinks,
  offerLinks,
} from '@/utils/fetch-links';
import geolocation from '~/mixins/geolocation';

export const LISA_TYPE = { panel: 'panel', hero: 'hero', content: 'content' };
export const PRODUCT_QUERY_ERROR = {
  MORE_THAN_TWO_PRODUCTS: 'more-than-two-products',
  TWO_LOCAL_OFFER_PRODUCTS: 'two-local-offer-products',
  TWO_GLOBAL_OFFER_PRODUCTS: 'two-global-offer-products',
};
export const LISA_CTA = {
  PLAN: 'FIND_A_PLAN',
  QUOTE: 'GET_A_QUOTE',
};

async function fetchGroupOfCountriesByValue(prismic, lang, value) {
  const groups = await prismic.api.query(
    [
      prismic.predicates.at('document.type', 'group_of_countries'),
      prismic.predicates.at('my.group_of_countries.countries.country', value),
    ],
    { lang }
  );

  const groupsIds = groups.results.map((group) => group.id);

  return groupsIds;
}

export async function generateLocationGroupsFilter(prismic, lang, value) {
  const ids = await fetchGroupOfCountriesByValue(prismic, lang, value);
  const filter =
    ids.length > 0
      ? prismic.predicates.any(
          `my.product_page.location_groups.location_group`,
          ids
        )
      : null;
  return filter;
}

export async function generateCountryGroupsFilter(prismic, lang, value) {
  const ids = await fetchGroupOfCountriesByValue(prismic, lang, value);

  const countryGroupsFilter =
    ids.length > 0
      ? prismic.predicates.any(
          `my.product_page.destination_groups.group_of_destination`,
          ids
        )
      : null;
  return countryGroupsFilter;
}

export async function queryProduct({
  selectedCoverType,
  selectedLanguage,
  selectedDestination,
  selectedLocation,
  lang,
  isPro,
  prismic,
}) {
  const documentFilter = prismic.predicates.at('document.type', 'product_page');

  const isProFilter = isPro
    ? prismic.predicates.at('document.tags', ['pro'])
    : prismic.predicates.not('document.tags', ['pro']);

  const coverTypeFilter = prismic.predicates.any(
    `my.product_page.cover_types.cover_type`,
    [selectedCoverType.value]
  );

  const languageFilter = prismic.predicates.any(
    `my.product_page.languages.language`,
    [selectedLanguage.value]
  );

  const destinationGroupsFilter = await generateCountryGroupsFilter(
    prismic,
    lang,
    selectedDestination.value
  );
  const destinationsFilter = prismic.predicates.at(
    `my.product_page.destination_countries.destination_country`,
    selectedDestination.value
  );

  const locationGroupsFilter = await generateLocationGroupsFilter(
    prismic,
    lang,
    selectedLocation.value
  );

  const locationsFilter = prismic.predicates.at(
    `my.product_page.locations.location`,
    selectedLocation.value
  );

  const excludedDestinationsFilter = prismic.predicates.not(
    `my.product_page.excluded_destinations.excluded_destination`,
    selectedDestination.value
  );

  const excludedLocationsFilter = prismic.predicates.not(
    `my.product_page.excluded_locations.excluded_location`,
    selectedLocation.value
  );

  const query = [
    documentFilter,
    coverTypeFilter,
    isProFilter,
    languageFilter,
    excludedDestinationsFilter,
    excludedLocationsFilter,
  ];

  const queriesList = [
    locationGroupsFilter && destinationGroupsFilter
      ? [...query, destinationGroupsFilter, locationGroupsFilter]
      : null,
    destinationGroupsFilter
      ? [...query, destinationGroupsFilter, locationsFilter]
      : null,
    locationGroupsFilter
      ? [...query, destinationsFilter, locationGroupsFilter]
      : null,
    [...query, destinationsFilter, locationsFilter],
  ];

  const validQueries = queriesList.filter((query) => query);

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log({ validQueries });
  }

  const queriesResult = await Promise.all(
    validQueries.map((queryFilter) => prismicQuery(prismic, queryFilter, lang))
  );
  const productsFound = [];
  queriesResult.forEach((queryResult) => {
    queryResult.results.forEach((result) => {
      if (!productsFound.find((el) => el.id === result.id)) {
        productsFound.push(result);
      }
    });
  });
  logProductsFound(
    productsFound,
    selectedCoverType,
    selectedDestination,
    selectedLanguage,
    selectedLocation
  );
  return getPrioritizedProduct(productsFound);
}

function getPrioritizedProduct(productsFound) {
  const hasMoreThanTwoProducts = productsFound.length > 2;
  if (hasMoreThanTwoProducts) {
    return { error: PRODUCT_QUERY_ERROR.MORE_THAN_TWO_PRODUCTS };
  }
  const hasTwoProducts = productsFound.length === 2;
  if (hasTwoProducts) {
    const bothProductsAreGlobal =
      productsFound[0].data.global_offer && productsFound[1].data.global_offer;
    if (bothProductsAreGlobal) {
      return { error: PRODUCT_QUERY_ERROR.TWO_GLOBAL_OFFER_PRODUCTS };
    }

    const bothProductsAreLocal =
      !productsFound[0].data.global_offer &&
      !productsFound[1].data.global_offer;
    if (bothProductsAreLocal) {
      return { error: PRODUCT_QUERY_ERROR.TWO_LOCAL_OFFER_PRODUCTS };
    }
  }

  const hasOneProduct = productsFound.length === 1;
  if (hasOneProduct) {
    return productsFound[0];
  }

  const localProduct = productsFound.find(
    (product) => !product.data.global_offer
  );
  return localProduct;
}

async function prismicQuery(prismic, query, lang) {
  return await prismic.api.query(query, {
    fetchLinks: [productLinks, offerLinks],
    page: 1,
    lang,
  });
}
function logProductsFound(
  productsFound,
  selectedCoverType,
  selectedDestination,
  selectedLanguage,
  selectedLocation
) {
  productsFound.forEach((product) => {
    // console.log required to investigate contribution issues
    // eslint-disable-next-line no-console
    console.table({
      uid: { value: product.uid },
      'Selected Location': {
        value: selectedLocation.value,
      },
      'Selected Cover Type': {
        label: selectedCoverType.label,
        value: selectedCoverType.value,
      },
      'Selected Destination': {
        label: selectedDestination.label,
        value: selectedDestination.value,
      },
      'Selected Language': {
        label: selectedLanguage.label,
        value: selectedLanguage.value,
      },
    });
  });
}
export const getDefaultLisaLabels = (i18n, type) => {
  const labels = {
    coverType: {
      title: i18n.t('lisa.coverType.title'),
      name: `${type}-cover-type`,
      placeholder: i18n.t('lisa.coverType.placeholder'),
      description: i18n.t('lisa.coverType.tooltip'),
      options: [],
      value: null,
    },
    destination: {
      title: i18n.t('lisa.destination.title'),
      name: `${type}-destination`,
      placeholder: i18n.t('lisa.destination.placeholder'),
      description: i18n.t('lisa.destination.tooltip'),
      lastResearchTitle: i18n.t('lisa.destination.lastResearchTitle'),
      lastResearchOption: null,
      dropdownTitle: i18n.t('lisa.destination.listTitle'),
      options: [],
      value: '',
      defaultValue: null,
    },
    location: {
      title: i18n.t('lisa.location.title'),
      name: `${type}-location`,
      placeholder: i18n.t('lisa.location.placeholder'),
      description: i18n.t('lisa.location.tooltip'),
      options: [],
      value: null,
    },
    language: {
      title: i18n.t('lisa.language.title'),
      name: `${type}-language`,
      placeholder: i18n.t('lisa.language.placeholder'),
      description: i18n.t('lisa.language.tooltip'),
      options: [],
      value: null,
    },
    buttons: [
      {
        label: i18n.t('cta.lisa-module-submit'),
        cta: {
          type: 'submit',
          disabled: true,
          loading: false,
        },
      },
    ],
    submitCta: {
      label: i18n.t('cta.lisa-submit'),
      disabled: true,
      variant: 'secondary',
      loading: false,
    },
    cta: {
      label: i18n.t('cta.get-a-rate'),
      disabled: true,
      variant: 'primary',
      loading: false,
    },
  };
  return labels;
};

export const fetchCoverTypes = async (prismic, lang) => {
  try {
    const coverTypes = await prismic.api.query(
      prismic.predicates.at('document.type', 'cover_types'),
      {
        lang,
      }
    );
    return coverTypes.results;
  } catch (e) {
    return null;
  }
};

const generateLisaFields = async (
  prismic,
  i18n,
  lisaData,
  coverTypes,
  ctaLabel
) => {
  const locationISO = await geolocation();
  const location = lisaData.countries.find((item) => {
    return item?.value.includes(locationISO);
  });

  const languages = lisaData ? lisaData.languages : [];

  const destinations = lisaData
    ? lisaData.countries.map((item) => ({
        label: item.label,
        value: item.value,
      }))
    : [];
  const coverTypesList =
    coverTypes.length > 0
      ? coverTypes
          .map((item) => {
            const coverType = item.cover_type;
            return {
              label: coverType.data?.title,
              value: coverType.id,
              icon: coverType.data?.icon,
              description: coverType.data?.description,
            };
          })
          .filter((coverType) => coverType.value)
      : [];

  const buttons = [
    {
      label: ctaLabel,
      cta: {
        type: 'submit',
        disabled: true,
        loading: false,
      },
    },
  ];

  return {
    languages,
    destinations,
    coverTypes: coverTypesList,
    location,
    buttons,
  };
};

const generateLisaModuleData = (
  i18n,
  enableResetButton,
  moduleData,
  lisaFields,
  type
) => {
  const defaultLabels = getDefaultLisaLabels(i18n, type);
  const lastResearchOption = localStorage?.lastResearchOption
    ? JSON.parse(localStorage?.lastResearchOption)
    : null;

  const locationValue =
    lisaFields.destinations.find(
      (destination) => lisaFields.location?.value === destination.value
    ) ?? null;

  const labels = {
    coverType: {
      ...defaultLabels.coverType,
      description: moduleData.cover_type_tooltip,
      options: lisaFields.coverTypes,
      resetButton: enableResetButton,
      value: null,
    },
    destination: {
      ...defaultLabels.destination,
      description: moduleData.destination_tooltip,
      options: lisaFields.destinations,
      resetButton: enableResetButton,
      value: null,
      lastResearchOption,
      defaultValue: null,
    },
    language: {
      ...defaultLabels.language,
      description: moduleData.language_tooltip,
      options: lisaFields.languages,
      resetButton: enableResetButton,
      value: null,
    },
    location: {
      ...defaultLabels.location,
      description: moduleData.location_tooltip,
      options: lisaFields.destinations,
      resetButton: enableResetButton,
      defaultValue: locationValue,
      value: locationValue.value,
      visible: false,
    },
    buttons: [...lisaFields.buttons],
  };

  return labels;
};
const adapter = async (
  slice,
  { uid, type, prismic, i18n, lisaData, useLisaTitle, enableResetButton, cta }
) => {
  try {
    const lang = i18n.localeProperties.iso;
    const moduleLisaId = slice?.primary?.module_lisa?.id;
    const module = uid
      ? await prismic.api.getByUID('module_lisa', uid, {
          fetchLinks: [...routerLinks, ...lisaLinks, ...coverTypeLinks],
          lang,
        })
      : moduleLisaId
      ? await prismic.api.getByID(moduleLisaId, {
          fetchLinks: [...routerLinks, ...lisaLinks, ...coverTypeLinks],
          lang,
        })
      : null;

    if (!module?.data) {
      return null;
    }

    const lisaFields = await generateLisaFields(
      prismic,
      i18n,
      lisaData,
      module.data.cover_types,
      cta === LISA_CTA.QUOTE
        ? module.data.cta_label
        : module.data.cta_submit_label
    );

    const lisaModuleData = generateLisaModuleData(
      i18n,
      enableResetButton,
      module.data,
      lisaFields,
      type
    );

    const title =
      useLisaTitle || !slice?.primary
        ? stripRichTextWrapperTag(module.data.title)
        : stripRichTextWrapperTag(slice?.primary?.title);

    const isLisaPanelOrContent = [LISA_TYPE.content, LISA_TYPE.panel].includes(
      type
    );

    const panelOrContentData = {
      ...lisaModuleData,
      buttons: [],
      submitCta: {
        label:
          cta === LISA_CTA.QUOTE
            ? module.data.cta_label
            : i18n.t('cta.lisa-submit'),
        disabled: true,
        variant: type === LISA_TYPE.content ? 'primary' : 'secondary',
        loading: false,
      },
      cta:
        type === LISA_TYPE.panel
          ? {
              label: i18n.t('cta.get-a-rate'),
              disabled: true,
              variant: 'primary',
              loading: false,
            }
          : null,
    };

    const lisa = {
      title,
      image: imageAdapter(module.data.image),
      noResultPage: module.data.no_result_page,
      errorPage: module.data.error_page,
      ...(isLisaPanelOrContent
        ? { ...panelOrContentData }
        : { ...lisaModuleData }),
    };

    return lisa;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default adapter;
