
import translations from '@/mixins/componentTranslations.js';
import lisaModuleMixin from '~/mixins/lisaModule';

export default {
  name: 'LisaModulePanel',
  mixins: [translations, lisaModuleMixin],
  props: {
    lisaUid: {
      type: String,
      default: null,
    },
  },
  data() {
    return { isNewLisa: false };
  },
  async fetch() {
    await this.fetchLisaData();
  },
  computed: {
    locale() {
      return this.$i18n.localeProperties.iso;
    },
    computedLisaUID() {
      return this.lisaUid || 'part-lisa-common';
    },
  },
  watch: {
    async locale(value) {
      this.lang = value;
      await this.fetchLisaData();
      this.initLisa();
    },
    async computedLisaUID() {
      await this.fetchLisaData();
      this.initLisa();
    },
  },
  mounted() {
    this.initLisa();
  },
  methods: {
    async initLisa() {
      if (this.isNewLisa) {
        await this.initLisaModule({
          useLisaTitle: false,
          resetButton: false,
          type: 'panel',
          uid: this.computedLisaUID,
        });
      }
    },
    async fetchLisaData() {
      const lisa = await this.$prismic.api.getByUID(
        'module_lisa',
        this.computedLisaUID,
        {
          lang: this.$i18n.localeProperties.iso,
        }
      );
      this.isNewLisa = lisa.data.new_lisa;
    },
    pingAutoFill() {
      const fields = this.$store.state.layout.lisaAutofilled;
      const reset = true;
      this.lisaModule = this.autoFillLisaOptions(
        this.lisaModule,
        fields,
        reset
      );
    },
  },
};
