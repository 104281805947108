import { richText, stripRichTextWrapperTag } from '../../utils/html';
import { adapter as imageAdapter } from '../../components/cloud-image/utils';
import getPrice from '../../utils/get-price';

const productBase = (product) => {
  if (!product.data) {
    return {};
  }

  let card = product.data;

  if (product.type !== 'product') {
    card = (product.data?.card && product.data?.card[0]) || {};
  }

  return {
    name: product.data.tag,
    title: card.card_title ? stripRichTextWrapperTag(card.card_title) : null,
    image: card.card_image
      ? imageAdapter(card.card_image, card.card_image_alt)
      : null,
  };
};

export const productThumb = (product, linkSerializer) => {
  const { data } = product;
  if (!data) {
    return null;
  }

  return {
    ...productBase(product),
    link: linkSerializer(product),
  };
};

export const cardThumb = (product, linkSerializer) => {
  const { data } = product || {};
  if (!data) {
    return null;
  }
  return {
    name: data.tag,
    title: data.card_title ? stripRichTextWrapperTag(data.card_title) : null,
    image: data.card_image
      ? imageAdapter(data.card_image, data.card_image_alt)
      : null,
    link: linkSerializer(data.card_link),
  };
};

const getSecondaryLinkData = (
  ctaSecondaryLink,
  ctaSecondaryLabel,
  ctaSecondaryDocument,
  ctaSecondaryRelAttributes,
  linkSerializer,
  i18n
) => {
  let secondaryLink = ctaSecondaryLink;

  if (ctaSecondaryDocument) {
    secondaryLink = ctaSecondaryDocument;
  }

  if (linkSerializer(secondaryLink)) {
    secondaryLink.rel = ctaSecondaryRelAttributes || 'nofollow';
    return {
      label: ctaSecondaryLabel || i18n.t('cta.see-all'),
      link: linkSerializer(secondaryLink),
    };
  }

  return null;
};

export const productFull = (product, i18n, linkSerializer) => {
  const { data } = product;
  const isStandalone = product.type === 'product';
  if (!data) {
    return null;
  }

  let card = product.data;

  if (!isStandalone) {
    card = (product.data?.card && product.data?.card[0]) || {};
  }

  const primaryLink =
    card.cta_primary_link && linkSerializer(card.cta_primary_link);

  const secondaryLinkData =
    product?.id === card?.cta_secondary_link?.id
      ? { ...product, link_type: 'Document' }
      : card.cta_secondary_link;

  const secondaryLink = secondaryLinkData
    ? getSecondaryLinkData(
        secondaryLinkData,
        card.cta_secondary_label,
        card.cta_secondary_document,
        card.cta_secondary_link_rel,
        linkSerializer,
        i18n
      )
    : null;

  const itemDescription = data.card_description
    ? richText(data.card_description)
    : null;
  const cardDescription = card.card_description
    ? richText(card.card_description)
    : null;

  const defaultCurrency = i18n.t('global.currency');
  const currency = data.currency || defaultCurrency || 'EUR';
  const locale = i18n.localeProperties.code;
  const formater = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || 'EUR',
    currencyDisplay: ['EUR', 'GBP'].includes(currency)
      ? 'narrowSymbol'
      : 'code',
  });
  const coverType = data.cover_types?.[0]?.cover_type?.data?.title || null;

  return {
    ...productBase(product),
    content: itemDescription || cardDescription,
    price:
      data.starting_price &&
      getPrice(
        formater.format(data.starting_price),
        data.price_frequency,
        data.legal_reference,
        i18n,
        {
          bold: true,
          twoLines: true,
          withoutFormat: true,
        }
      ),
    primaryLink:
      primaryLink && card.cta_primary_label
        ? {
            label: card.cta_primary_label,
            link: primaryLink,
          }
        : null,
    secondaryLink,
    coverType,
  };
};
