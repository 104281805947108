export const getNeedsSelectOptions = (lang) => {
  return Object.keys(needs).map((need) => {
    return {
      label: needs[need].locales[lang].title,
      description: needs[need].locales[lang].description,
      value: need,
      icon: needs[need].icon,
    };
  });
};

export const needs = {
  expat: {
    locales: {
      en: { title: 'Long-term', description: '12+ months​' },
      fr: { title: 'Expatriation', description: '+ de 12 mois​' },
      es: { title: 'Expatriación', description: 'Mas de 12 meses' },
      de: { title: 'Auswanderung', description: 'Mehr als 12 Monate' },
    },
    icon: 'homme-pro',
  },
  'study-abroad': {
    locales: {
      en: { title: 'International studies​', description: 'From 3 months​' },
      fr: { title: 'Etudes', description: 'A partir de 3 mois' },
      es: {
        title: 'Estudios en el extranjero​',
        description: 'A partir de 3 meses ',
      },
      de: { title: 'Auslandsstudium', description: 'Ab  3 Monate' },
    },
    icon: 'diplome',
  },
  'short-stay-abroad': {
    locales: {
      en: { title: 'Short-term', description: '3-12 months' },
      fr: { title: 'Court séjour', description: '3-12 mois​' },
      es: { title: 'Estancia en el extranjero​', description: '3-12 meses​' },
      de: { title: 'Langzeitreise', description: 'Zwischen 3 und 12 Monate' },
    },
    icon: 'hourglass',
  },
  travel: {
    locales: {
      en: { title: 'Holiday/Travel​', description: '-3 months​' },
      fr: { title: 'Voyage/Vacances​', description: '-de 3 mois' },
      es: { title: 'Viaje/Vacaciones​', description: 'Menos de 3 meses' },
      de: { title: 'Reise', description: 'weniger als 3 Monate' },
    },
    icon: 'valises',
  },
};
