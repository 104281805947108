export default {
  data() {
    return {
      translations: {},
    };
  },
  beforeMount() {
    // prevent reverting back to not found after hard-loading page.
    this.$i18n.mergeLocaleMessage(this.$i18n.locale, this.translations);
  },
  methods: {
    async fetchTranslations(module) {
      const locale = this.$i18n.locale;
      try {
        const moduleJsonTranslations = await import(
          `../locales/${locale}/${locale}.${module}.json`
        );
        this.translations = moduleJsonTranslations
          ? moduleJsonTranslations.default
          : {};
        this.$i18n.mergeLocaleMessage(locale, this.translations);
      } catch (err) {
        throw new Error(
          `No ${locale} language translation found for ${module}`
        );
      }
    },
  },
};
